var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-box"},[_vm._m(0),_c('a-divider',{staticStyle:{"margin-left":"-10px"}}),_c('a-form-model',{staticClass:"form-search",attrs:{"colon":false,"layout":"inline","model":_vm.query,"labelAlign":"left"},on:{"submit":_vm.handleSearch},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('a-row',[_c('a-form-model-item',{staticStyle:{"float":"right","margin-right":"0"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 查询 ")]),_c('a-button',{on:{"click":_vm.Reset}},[_vm._v("重置")])],1),_c('a-form-model-item',{attrs:{"label":"地区"}},[_c('a-cascader',{staticStyle:{"min-width":"300px"},attrs:{"field-names":{
            label: 'name',
            value: 'name',
            children: 'districts',
          },"load-data":_vm.loadSsq,"change-on-select":"","options":_vm.ssqData,"placeholder":"请选择省市区"},on:{"change":_vm.ssqChange},model:{value:(_vm.addrValue),callback:function ($$v) {_vm.addrValue=$$v},expression:"addrValue"}})],1),_c('a-form-model-item',{attrs:{"label":"类型"}},[_c('a-select',{staticClass:"a-select",attrs:{"placeholder":"请选择类型"},on:{"change":_vm.searchList},model:{value:(_vm.query.type),callback:function ($$v) {_vm.$set(_vm.query, "type", $$v)},expression:"query.type"}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" "+_vm._s('租赁客户')+" ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" "+_vm._s('产权客户')+" ")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v(" "+_vm._s('工地客户')+" ")])],1)],1),_c('a-form-model-item',{attrs:{"label":"企业名称"}},[_c('a-input',{attrs:{"placeholder":"请输入企业名称"},model:{value:(_vm.query.company_name),callback:function ($$v) {_vm.$set(_vm.query, "company_name", $$v)},expression:"query.company_name"}})],1),_c('a-form-model-item',{attrs:{"label":"经办人"}},[_c('a-input',{attrs:{"placeholder":"请输入经办人"},model:{value:(_vm.query.manager),callback:function ($$v) {_vm.$set(_vm.query, "manager", $$v)},expression:"query.manager"}})],1)],1)],1),_c('a-table',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"main-table",staticStyle:{"margin-top":"14px"},attrs:{"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"pagination":{
      current: _vm.query.page,
      total: _vm.query.total,
    },"columns":_vm.columns,"data-source":_vm.data,"rowKey":"id"},on:{"change":_vm.listChange},scopedSlots:_vm._u([{key:"type",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(text === 1 ? '租赁客户' : text === 2 ? '产权客户' : '工地客户')+" ")])}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a-space',[_c('a',{on:{"click":function($event){return _vm.divide(record.customer_id)}}},[_vm._v("分配")])])],1)}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("分配客户")])])}]

export { render, staticRenderFns }