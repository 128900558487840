export const toolbar = [
	'heading',
	'fontSize',
	'fontColor',
	'fontbackgroundcolor',
	'highlight',
	'highlight:yellowMarker',
	'highlight:greenMarker',
	'highlight:pinkMarker',
	'highlight:blueMarker',
	'fontFamily',
	'alignment',
	'imageUpload',
	'bold',
	'italic',
	'underline',
	'imageStyle:full',
	'imageStyle:alignLeft',
	'imageStyle:alignRight',
	'link',
	'undo',
	'redo',
	'lineheight',
	'ident',
	'mediaEmbed',
];

export const fontSize = {
	options: [8, 9, 10, 11, 12, 'default', 14, 16, 18, 20, 22, 24, 26, 28, 36, 44, 48, 72],
};
