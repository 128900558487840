var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-box"},[_vm._m(0),_c('a-divider',{staticStyle:{"margin-left":"-10px"}}),_c('a-form-model',{staticClass:"form-search",attrs:{"colon":false,"layout":"inline","model":_vm.query,"labelAlign":"left"},on:{"submit":_vm.handleSearch},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('a-row',[_c('a-form-model-item',{staticStyle:{"float":"right","margin-right":"0"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 查询 ")]),_c('a-button',{on:{"click":_vm.Reset}},[_vm._v("重置")])],1),_c('a-form-model-item',{attrs:{"label":"地区"}},[_c('a-cascader',{staticStyle:{"min-width":"300px"},attrs:{"field-names":{
            label: 'name',
            value: 'name',
            children: 'districts',
          },"load-data":_vm.loadSsq,"change-on-select":"","options":_vm.ssqData,"placeholder":"请选择省市区"},on:{"change":_vm.ssqChange},model:{value:(_vm.addrValue),callback:function ($$v) {_vm.addrValue=$$v},expression:"addrValue"}})],1),_c('a-form-model-item',{attrs:{"label":"单位名称"}},[_c('a-input',{attrs:{"placeholder":"请输入单位名称"},model:{value:(_vm.query.company_name),callback:function ($$v) {_vm.$set(_vm.query, "company_name", $$v)},expression:"query.company_name"}})],1),_c('a-form-model-item',{attrs:{"label":"区域名称"}},[_c('a-input',{attrs:{"placeholder":"请输入区域名称"},model:{value:(_vm.query.manager),callback:function ($$v) {_vm.$set(_vm.query, "manager", $$v)},expression:"query.manager"}})],1),_c('a-form-model-item',{attrs:{"label":"设备ID"}},[_c('a-input',{attrs:{"placeholder":"请输入设备ID"},model:{value:(_vm.query.main_id),callback:function ($$v) {_vm.$set(_vm.query, "main_id", $$v)},expression:"query.main_id"}})],1),_c('a-form-model-item',{attrs:{"label":"出厂编号"}},[_c('a-input',{attrs:{"placeholder":"请输入出厂编号"},model:{value:(_vm.query.factory_no),callback:function ($$v) {_vm.$set(_vm.query, "factory_no", $$v)},expression:"query.factory_no"}})],1)],1)],1),_c('a-row',{staticClass:"table-action-list",staticStyle:{"margin-top":"20px"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.toEdit(-1)}}},[_vm._v("批量归还")])],1),_c('a-table',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"main-table",staticStyle:{"margin-top":"14px"},attrs:{"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"pagination":{
      current: _vm.query.page,
      total: _vm.query.total,
      showLessItems: true,
      showTotal: function (total) {
        return ("共" + total + "条数据")
      },
    },"columns":_vm.columns,"data-source":_vm.data,"rowKey":"id","scroll":{ x: true }},on:{"change":_vm.listChange},scopedSlots:_vm._u([{key:"admin_name",fn:function(text, record){return _c('span',{},[_c('span',{staticStyle:{"color":"#1890ff","cursor":"pointer"},on:{"click":function($event){return _vm.showVisible(record.customer_id)}}},[_vm._v(_vm._s(text || '操作员'))])])}},{key:"equipment_count",fn:function(text, record){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.toEquipmentList(record.customer_id)}}},[_vm._v(_vm._s(text))])])}},{key:"one_id",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(text)+" "),_c('br'),(record.one_status === 1 || record.one_status === 2)?_c('a-tag',{attrs:{"color":record.one_status === 1 || record.one_status === 2
            ? '#74CE78'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.one_status === 1 || record.one_status === 2 ? '在线' : '离线')+" ")]):_vm._e(),_c('a-tag',{attrs:{"color":record.one_status === 1
            ? '#74CE78'
            : record.one_status === 2
            ? '#757776'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.one_status === 1 ? '开机' : record.one_status === 2 ? '关机' : '离线')+" ")])],1)}},{key:"two_id",fn:function(text, record){return (record.two_sim)?_c('span',{},[_vm._v(" "+_vm._s(text)+" "),_c('br'),(record.two_status === 1 || record.two_status === 2)?_c('a-tag',{attrs:{"color":record.two_status === 1 || record.two_status === 2
            ? '#74CE78'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.two_status === 1 || record.two_status === 2 ? '在线' : '离线')+" ")]):_vm._e(),_c('a-tag',{attrs:{"color":record.two_status === 1
            ? '#74CE78'
            : record.two_status === 2
            ? '#757776'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.two_status === 1 ? '开机' : record.two_status === 2 ? '关机' : '离线')+" ")])],1):_vm._e()}},{key:"is_on",fn:function(text, record){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.equipmentOn(record.equipment_id, 1)}}},[_vm._v("开启")]),_vm._v("  "),_c('a',{staticStyle:{"color":"#e0352b"},on:{"click":function($event){return _vm.equipmentOn(record.equipment_id, -1)}}},[_vm._v("关机")])])}},{key:"warn_type",fn:function(text, record){return _c('span',{},[_c('a-tag',{attrs:{"color":text === 1 ? '#74CE78' : '#757776'}},[_vm._v(" "+_vm._s(text === 1 ? '正常' : '屏蔽')+" ")]),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.equipmentWarn(record.equipment_id)}}},[_vm._v(" "+_vm._s(text === 1 ? '屏蔽' : '正常')+" ")])],1)}},{key:"qr_code",fn:function(text){return _c('span',{},[(text)?_c('img',{staticClass:"img-qr",attrs:{"src":text}}):_c('div',{staticClass:"img-qr_code",attrs:{"src":"","alt":""}})])}},{key:"is_dot",fn:function(text){return _c('span',{},[_c('span',{class:text == 1 ? 'doted' : 'doting'},[_vm._v(_vm._s(text == 1 ? '已定位' : '未定位'))])])}},{key:"main_id",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(text)+" "),_c('br'),(record.main_status === 1 || record.main_status === 2)?_c('a-tag',{attrs:{"color":record.main_status === 1 || record.main_status === 2
            ? '#74CE78'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.main_status === 1 || record.main_status === 2 ? '在线' : '离线')+" ")]):_vm._e(),_c('a-tag',{attrs:{"color":record.main_status === 1
            ? '#74CE78'
            : record.main_status === 2
            ? '#757776'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.main_status === 1 ? '开机' : record.main_status === 2 ? '关机' : '离线')+" ")])],1)}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a-space',[_c('a',{on:{"click":function($event){return _vm.toEdit1(record.equipment_id)}}},[_vm._v("编辑")]),_c('a',{staticStyle:{"color":"#e0352b"},on:{"click":function($event){return _vm.del(record.equipment_id)}}},[_vm._v("归还")])])],1)}}],null,true)}),_c('a-drawer',{attrs:{"title":"操作员","placement":"right","closable":true,"width":600,"visible":_vm.visible,"after-visible-change":_vm.afterVisibleChange},on:{"close":_vm.onClose}},[_c('a-table',{key:"123",attrs:{"data-source":_vm.dataInfo,"columns":_vm.columns1,"pagination":{
        current: _vm.query1.page,
        total: _vm.query1.total,
      }},on:{"change":_vm.listChange1}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("工地管理")])])}]

export { render, staticRenderFns }