<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">分配客户</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model
      class="form-search"
      :colon="false"
      layout="inline"
      :model="query"
      labelAlign="left"
      @submit="handleSearch"
      @submit.native.prevent
    >
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item label="地区">
          <a-cascader
            :field-names="{
              label: 'name',
              value: 'name',
              children: 'districts',
            }"
            v-model="addrValue"
            :load-data="loadSsq"
            style="min-width: 300px"
            change-on-select
            :options="ssqData"
            placeholder="请选择省市区"
            @change="ssqChange"
          />
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select
            v-model="query.type"
            class="a-select"
            placeholder="请选择类型"
            @change="searchList"
          >
            <a-select-option :value="1">
              {{ '租赁客户' }}
            </a-select-option>
            <a-select-option :value="2">
              {{ '产权客户' }}
            </a-select-option>
            <a-select-option :value="3">
              {{ '工地客户' }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="企业名称">
          <a-input v-model="query.company_name" placeholder="请输入企业名称" />
        </a-form-model-item>
        <a-form-model-item label="经办人">
          <a-input v-model="query.manager" placeholder="请输入经办人" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-table
      class="main-table"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :pagination="{
        current: query.page,
        total: query.total,
      }"
      @change="listChange"
      :columns="columns"
      :data-source="data"
      rowKey="id"
      style="margin-top: 14px"
      v-viewer
    >
      <span slot="type" slot-scope="text">
        {{ text === 1 ? '租赁客户' : text === 2 ? '产权客户' : '工地客户' }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="divide(record.customer_id)">分配</a>
        </a-space>
      </span>
    </a-table>
  </div>
</template>

<script>
import { domain } from '@/siteInfo'
import axios from 'axios'
import { ssqdata } from '@/utils/ssqdata'

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
        total: 0,
      },
      euqipments: [],
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: {
            customRender: 'type',
          },
        },
        {
          title: '企业名称',
          dataIndex: 'company_name',
        },
        {
          title: '经办人',
          dataIndex: 'manager',
        },
        {
          title: '地区',
          customRender: (text, record, index) =>
            `${record.pro}-${record.city}-${record.area}`,
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      data: [],
      selectedRowKeys: [],
      ssqData: ssqdata,
      addrValue: [],
    }
  },
  created() {
    this.euqipments = this.$route.query.ids
    this.getList()
  },
  methods: {
    divide(id) {
      this.$confirm({
        title: '确认',
        content: '确认分配给该客户吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/equipment/equipment/divide', {
            customer_id: id,
            equipment_ids: this.euqipments.split(','),
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.back()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {},
      })
    },
    listChange(e) {
      this.query.page = e.current
      this.getList()
    },
    ssqChange(e, opt) {
      this.query.pro_code = opt && opt[0] ? opt[0].adcode : ''
      this.query.city_code = opt && opt[1] ? opt[1].adcode : ''
      this.query.area_code = opt && opt[2] ? opt[2].adcode : ''
    },
    loadSsq(selectedOptions) {
      console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // load options lazily
      let subdistrict =
        targetOption.level == 'province'
          ? 1
          : targetOption.level == 'city'
          ? 2
          : targetOption.level == 'district'
          ? 3
          : 4
      axios
        .get('https://restapi.amap.com/v3/config/district', {
          params: {
            keywords: targetOption.adcode,
            subdistrict: subdistrict,
            key: 'ce7404ac71c28a7893cae3ccc5517da0',
          },
        })
        .then((res) => {
          let districts = res.data.districts[0].districts
          for (let i = 0; i < districts.length; i++) {
            let item = districts[i]
            delete item.districts
            item.isLeaf =
              item.level == 'district' ||
              res.data.districts[0].adcode == 810000 ||
              res.data.districts[0].adcode == 820000
          }
          targetOption.districts = districts
          targetOption.loading = false
          this.ssqData = [...this.ssqData]
        })
        .catch((err) => {
          console.log(err)
        })
    },
    searchList() {
      this.getList()
    },
    back() {
      this.$router.back()
    },
    getList() {
      console.log('搜索对象', this.euqipments)
      this.$post(domain + '/admin/v1/equipment/customer/list', this.query).then(
        (res) => {
          console.log(res)
          if (res.code === 0) {
            this.data = res.data.list
            this.query.total = res.data.total
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    Reset() {
      this.addrValue = []
      this.query = {
        page: this.query.page,
        limit: this.query.limit,
      }
      this.getList()
    },
    handleSearch(query) {
      this.query.page = 1
      this.getList()
      console.log(query)
    },
    changeCheck(value) {
      console.log(value)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
