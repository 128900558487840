import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import { domain } from '@/siteInfo.js'
Vue.use(Vuex)
let oldUserInfo = Cookies.getJSON('userInfo')
let oldToken = Cookies.get('token')
let oldRules = Cookies.get('rules')
let oldSidebarList = localStorage.getItem('sidebarList')
let oldHeaderNav = Cookies.getJSON('headerNav')
import { headerNav, sidebarList } from '../router/navData.js'

// console.log('zheshi chushi?', oldSidebarList)

import axios from 'axios'
const store = new Vuex.Store({
	state: {
		userInfo: oldUserInfo ? oldUserInfo : false,
		token: oldToken ? oldToken : false,
		rules: oldRules ? oldRules : [],
		sidebarList: oldSidebarList ? JSON.parse(oldSidebarList) : [],
		headerNav: oldHeaderNav ? oldHeaderNav : []
	},
	mutations: {
		setUserInfo(state, userInfo) {
			state.userInfo = userInfo
			Cookies.set('userInfo', userInfo)
		},
		setToken(state, data) {
			state.token = data
			Cookies.set('token', data)
		},
		logout(state) {
			state.token = false
			state.userInfo = false
			state.sidebarList = false
			state.headerNav = false
			state.rules = []
			Cookies.remove('token')
			Cookies.remove('userInfo')
			Cookies.remove('headerNav')
			Cookies.remove('rules')
			localStorage.removeItem('sidebarList')
		},
		setRules(state, data) {
			state.rules = data
			Cookies.set('rules', data)
		},
		setSidebarList(state, data) {
			state.sidebarList = data
			localStorage.setItem('sidebarList', JSON.stringify(data))
		},
		setHeaderNav(state, data) {
			state.headerNav = data
			Cookies.set('headerNav', data)
		}
	},
	actions: {
		login({ commit }, params) {
			let t = this
			return new Promise((resolve, reject) => {
				axios({
					url: 'login',
					data: params,
					baseURL: domain + '/admin/v1/index',
					method: 'post'
				})
					.then(res => {
						let { code, msg, data } = res.data
						if (code == 0) {
							if (data) {
								commit('setUserInfo', data)
								commit('setToken', data.token)
								let rules = []
								for (let i = 0; i < data.role_list.length; i++) {
									rules = rules.concat(data.role_list[i].rules)
								}
								rules = [...new Set(rules)]
								commit('setRules', rules)
								console.log(rules)
								let oldSidebarList = JSON.parse(JSON.stringify(sidebarList))
								let oldHeaderNav = JSON.parse(JSON.stringify(headerNav))
								if (!data.is_admin) {
									console.log('zheli ???')
									for (let i = 0; i < oldSidebarList.length; i++) {
										let item = oldSidebarList[i]
										for (let k = 0; k < item.length; k++) {
											if (rules.indexOf(item[k].path) < 0) {
												item.splice(k, 1)
												k--
											}
										}
									}

									for (let i = 0; i < oldSidebarList.length; i++) {
										if (oldSidebarList[i].length == 0) {
											oldSidebarList.splice(i, 1)
											oldHeaderNav.splice(i, 1)
											i--
										}
									}
								}
								console.log('登陆后的:setSidebarList', oldSidebarList)

								commit('setSidebarList', oldSidebarList)
								commit('setHeaderNav', oldHeaderNav)
								resolve(res.data)
							} else {
								resolve({ msg: '请完成初始化', code: 999, data: false })
							}
						} else {
							resolve(res.data)
						}
					})
					.catch(err => {
						console.log(err)
						reject(err)
					})
			})
		},
		register({ commit }, params) {}
	}
})

export default store
