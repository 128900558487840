<!--  -->
<template>
  <div class="h5">
    <div v-if="!flag" class="hide">请使用移动端查看</div>
    <div v-else>
      <!-- <header>操作人员</header> -->
      <main>
        <div class="content">
          <span style="font-size: 24px; font-weight: bold">{{
            data.name
          }}</span>
          <!-- {{
            data.name
          }} -->
          <span style="font-size: 22px">{{ data.phone }}</span>
          <div class="image_box">
            <img v-if="url" :src="url" alt="" />
            <!-- v-if="out" -->
            <span class="time_out" v-if="out"> </span>
            <span class="out" v-if="out">
              <img src="@/assets/out_img.png" alt="" />
              <span>证件已过期</span>
            </span>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'
export default {
  data() {
    return {
      flag: false,
      data: {},
      url: '',
      out: false,
    }
  },
  methods: {
    getDetails() {
      const id = this.$route.query.id
      this.$post(domain + '/admin/v1/operator/info', {
        op_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.url = res.data.certificate
          if (new Date().getTime() / 1000 > this.data.end_time) {
            this.out = true
          } else {
            this.out = false
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
  created() {
    // 判断是否是移动端
    const userAgent = navigator.userAgent
    // 匹配设备型号信息
    const modelRegex =
      /(Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini)/
    const match = userAgent.match(modelRegex)
    if (match) {
      console.log('%c移动端', 'color:red')
      this.flag = true
    } else {
      console.log('%cPC端', 'color:red')
      this.flag = false
    }
    this.getDetails()
  },
  mounted() {},
}
</script>
<style scoped lang="less">
// @media screen and (min-width: 300px) and (max-width: 820px) {
//   .h5 {
//     font-size: 62.5%;
//   }
// }
.h5 {
  background-color: #fff;
  main {
    margin-top: 60px;
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      > :first-child {
        margin-bottom: 13.5px;
      }
      > :last-child {
        margin-top: 56px;
      }
      .image_box {
        width: 286.5px;
        height: 400px;
        // background: #e3e3e3;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          // max-height: 100%;
        }
        .out {
          position: absolute;
          top: 0;
          left: 0;
          width: 286.5px;
          height: 400px;

          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          color: #f4e011;
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 2px;
          // backdrop-filter: blur(5px);
          img {
            width: 87.47px;
            height: 87.47px;
            margin-bottom: 32px;
          }
        }
        .time_out {
          font-size: 16px;
          text-align: center;
          // height: 100%;
          // width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 286.5px;
          height: 400px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background: rgba(87, 96, 117, 0.59);
          // opacity: 0.87;
          backdrop-filter: blur(5px);
        }
      }
    }
  }
}
.hide {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
header {
  height: 45px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 45px;
  text-align: center;
}
</style>