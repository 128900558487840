import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
export const routes = [
	{
		path: '/companyInfo',
		component: () => import('@/view/company/companyInfo'),
		name: 'CompanyInfo',
		meta: {
			title: '公司介绍',
			noCache: true,
			permission: '',
			parent: '公司管理'
		}
	},
	{
		path: '/companyBrief',
		component: () => import('@/view/company/companyBrief'),
		name: 'CompanyBrief',
		meta: {
			title: '公司简介',
			noCache: true,
			permission: '',
			parent: '公司管理'
		}
	},
	{
		path: '/qualificationList',
		component: () => import('@/view/company/qualificationList'),
		name: 'QualificationList',
		meta: {
			title: '资质列表',
			noCache: true,
			permission: '',
			parent: '公司管理'
		}
	},
	{
		path: '/qualificationEdit',
		component: () => import('@/view/company/qualificationEdit'),
		name: 'QualificationEdit',
		meta: {
			title: '资质修改',
			noCache: true,
			permission: '',
			parent: '公司管理'
		}
	},
	{
		path: '/videoList',
		component: () => import('@/view/company/videoList'),
		name: 'VideoList',
		meta: {
			title: '视频列表',
			noCache: true,
			permission: '',
			parent: '公司管理'
		}
	},
	{
		path: '/videoEdit',
		component: () => import('@/view/company/videoEdit'),
		name: 'VideoEdit',
		meta: {
			title: '视频修改',
			noCache: true,
			permission: '',
			parent: '公司管理'
		}
	},




	{
		path: '/deviceList',
		component: () => import('@/view/device/deviceList'),
		name: 'DeviceList',
		meta: {
			title: '设备列表',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/eventList',
		component: () => import('@/view/device/eventList'),
		name: 'EventList',
		meta: {
			title: '告警事件',
			noCache: true,
			permission: '',
			parent: '设备告警'
		}
	},
	{
		path: '/deviceEdit',
		component: () => import('@/view/device/deviceEdit'),
		name: 'DeviceList',
		meta: {
			title: '设备编辑',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},


	{
		path: '/customerList',
		component: () => import('@/view/device/customerList'),
		name: 'CustomerList',
		meta: {
			title: '客户管理',
			noCache: true,
			permission: '',
			parent: '用户管理'
		}
	},
	{
		path: '/siteList',
		component: () => import('@/view/device/siteList'),
		name: 'SiteList',
		meta: {
			title: '工地设备',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/operatingPersonnelList',
		component: () => import('@/view/device/operatingPersonnelList'),
		name: 'OperatingPersonnelList',
		meta: {
			title: '操作人员',
			noCache: true,
			permission: '',
			parent: '用户管理'
		}
	},
	{
		path: '/personEdit',
		component: () => import('@/view/device/personEdit'),
		name: 'personEdit',
		meta: {
			title: '人员编辑',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/customerEdit',
		component: () => import('@/view/device/customerEdit'),
		name: 'CustomerEdit',
		meta: {
			title: '客户编辑',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},


	{
		path: '/leaseCustomerList',
		component: () => import('@/view/device/leaseCustomerList'),
		name: 'LeaseCustomerList',
		meta: {
			title: '租赁设备',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/eqCusEdit',
		component: () => import('@/view/device/equipmentCustomerEdit'),
		name: 'eqCusEdit',
		meta: {
			title: '客户设备编辑',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},

	{
		path: '/propertyCustomerList',
		component: () => import('@/view/device/propertyCustomerList'),
		name: 'PropertyCustomerList',
		meta: {
			title: '产权设备',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/changeList',
		component: () => import('@/view/device/changeList'),
		name: 'ChangeList',
		meta: {
			title: '变更记录',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/changeEdit',
		component: () => import('@/view/device/changeEdit'),
		name: 'ChangeEdit',
		meta: {
			title: '设备变更',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/contrastList',
		component: () => import('@/view/device/contrastList'),
		name: 'ContrastList',
		meta: {
			title: '对比记录',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/customerListDivide',
		component: () => import('@/view/device/customerListDivide'),
		name: 'CustomerListDivide',
		meta: {
			title: '客户分配',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/mapList',
		component: () => import('@/view/map/mapList.vue'),
		name: 'MapList',
		meta: {
			title: '项目列表',
			noCache: true,
			permission: '',
			parent: '地图管理'
		}
	},
	{
		path: '/projectEdit',
		component: () => import('@/view/map/projectEdit.vue'),
		name: 'ProjectEdit',
		meta: {
			title: '项目编辑',
			noCache: true,
			permission: '',
			parent: '地图管理'
		}
	},
	{
		path: '/mapConfig',
		component: () => import('@/view/map/mapLookList.vue'),
		name: 'MapConfig',
		meta: {
			title: '地图配置',
			noCache: true,
			permission: '',
			parent: '地图管理'
		}
	},
	{
		path: '/actionMap',
		component: () => import('@/view/map/actionMap.vue'),
		name: 'ActionMap',
		meta: {
			title: '地图配置',
			noCache: true,
			permission: '',
			parent: '地图管理'
		}
	},
	{
		path: '/editMap',
		component: () => import('@/view/map/editMap.vue'),
		name: 'ActionMap',
		meta: {
			title: '地图编辑',
			noCache: true,
			permission: '',
			parent: '地图管理'
		}
	},
	{
		path: '/previewMap',
		component: () => import('@/view/map/previewMap.vue'),
		name: 'PreviewMap',
		meta: {
			title: '地图预览',
			noCache: true,
			permission: '',
			parent: '地图管理'
		}
	}
	// {
	// 	path: '/propertyCustomerList',
	// 	component: () => import('@/view/device/propertyCustomerList'),
	// 	name: 'PropertyCustomerList',
	// 	meta: {
	// 		title: '产权编辑',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },
	// {
	// 	path: '/gardenSwiper',
	// 	component: () => import('@/view/garden/gardenSwiper'),
	// 	name: 'GardenSwiper',
	// 	meta: {
	// 		title: '园区轮播',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '园区招商'
	// 	}
	// },
	// {
	// 	path: '/gardenList',
	// 	component: () => import('@/view/garden/gardenList'),
	// 	name: 'GardenList',
	// 	meta: {
	// 		title: '园区列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '园区招商'
	// 	}
	// },
	// {
	// 	path: '/hotGarden',
	// 	component: () => import('@/view/garden/hotGarden'),
	// 	name: 'HotGarden',
	// 	meta: {
	// 		title: '推荐园区',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '园区招商'
	// 	}
	// },
	// {
	// 	path: '/gardenDetail',
	// 	component: () => import('@/view/garden/gardenDetail'),
	// 	name: 'GardenDetail',
	// 	meta: {
	// 		title: '园区详情',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '园区招商'
	// 	}
	// },
	// {
	// 	path: '/policySwiper',
	// 	component: () => import('@/view/policy/policySwiper'),
	// 	name: 'PolicySwiper',
	// 	meta: {
	// 		title: '政策轮播',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '政策服务'
	// 	}
	// },
	// {
	// 	path: '/policyList',
	// 	component: () => import('@/view/policy/policyList'),
	// 	name: 'PolicyList',
	// 	meta: {
	// 		title: '政策列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '政策服务'
	// 	}
	// },
	// {
	// 	path: '/policyDetail',
	// 	component: () => import('@/view/policy/policyDetail'),
	// 	name: 'PolicyDetail',
	// 	meta: {
	// 		title: '政策详情',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '政策服务'
	// 	}
	// },
	// {
	// 	path: '/serveBanner',
	// 	component: () => import('@/view/serve/serveBanner'),
	// 	name: 'ServeBanner',
	// 	meta: {
	// 		title: '产业服务',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '产业服务'
	// 	}
	// },
	// {
	// 	path: '/serveSwiper',
	// 	component: () => import('@/view/serve/serveSwiper'),
	// 	name: 'ServeSwiper',
	// 	meta: {
	// 		title: '产业服务轮播',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '产业服务'
	// 	}
	// },
	// {
	// 	path: '/serveList',
	// 	component: () => import('@/view/serve/serveList'),
	// 	name: 'ServeList',
	// 	meta: {
	// 		title: '产业服务列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '产业服务'
	// 	}
	// },
	// {
	// 	path: '/serveDetail',
	// 	component: () => import('@/view/serve/serveDetail'),
	// 	name: 'ServeDetail',
	// 	meta: {
	// 		title: '产业服务详情',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '产业服务'
	// 	}
	// },
	// {
	// 	path: '/userInfo',
	// 	component: () => import('@/view/userInfo/userInfo'),
	// 	name: 'UserInfo',
	// 	meta: {
	// 		title: '用户中心',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '用户中心'
	// 	}
	// },
	// {
	// 	path: '/userList',
	// 	component: () => import('@/view/userInfo/userList'),
	// 	name: 'UserList',
	// 	meta: {
	// 		title: '用户列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '用户中心'
	// 	}
	// },
	// {
	// 	path: '/userAppointment',
	// 	component: () => import('@/view/userInfo/userAppointment'),
	// 	name: 'UserAppointment',
	// 	meta: {
	// 		title: '预约看房',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '用户中心'
	// 	}
	// },
	// {
	// 	path: '/userEntrust',
	// 	component: () => import('@/view/userInfo/userEntrust'),
	// 	name: 'UserEntrust',
	// 	meta: {
	// 		title: '需求委托',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '用户中心'
	// 	}
	// },
	// {
	// 	path: '/homeSwiper',
	// 	component: () => import('@/view/home/homeSwiper'),
	// 	name: 'HomeSwiper',
	// 	meta: {
	// 		title: '首页轮播',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '首页设置'
	// 	}
	// },
	// {
	// 	path: '/homeGarden',
	// 	component: () => import('@/view/home/homeGarden'),
	// 	name: 'HomeGarden',
	// 	meta: {
	// 		title: '首页推荐',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '首页设置'
	// 	}
	// },
	// {
	// 	path: '/homeBanner',
	// 	component: () => import('@/view/home/homeBanner'),
	// 	name: 'HomeBanner',
	// 	meta: {
	// 		title: '产品服务',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '首页设置'
	// 	}
	// },
	// {
	// 	path: '/setting',
	// 	component: () => import('@/view/setting/setting'),
	// 	name: 'Setting',
	// 	meta: {
	// 		title: '产业服务类型',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '系统设置'
	// 	}
	// },
	// {
	// 	path: '/tagSetting',
	// 	component: () => import('@/view/setting/tagSetting'),
	// 	name: 'Setting',
	// 	meta: {
	// 		title: '标签',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '系统设置'
	// 	}
	// },
	// {
	// 	path: '/video',
	// 	component: () => import('@/view/video/video'),
	// 	name: 'Video',
	// 	meta: {
	// 		title: '视频',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '视频中心'
	// 	}
	// },
	// {
	// 	path: '/xieyi',
	// 	component: () => import('@/view/setting/xieyi'),
	// 	name: 'Xieyi',
	// 	meta: {
	// 		title: '用户协议',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '系统设置'
	// 	}
	// },
	// {
	// 	path: '/about',
	// 	component: () => import('@/view/setting/about'),
	// 	name: 'About',
	// 	meta: {
	// 		title: '关于我们',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '系统设置'
	// 	}
	// },
	// {
	// 	path: '/serveApp',
	// 	component: () => import('@/view/serveApp/serveApp'),
	// 	name: 'ServeApp',
	// 	meta: {
	// 		title: '服务工具',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '服务工具'
	// 	}
	// },
	// {
	// 	path: '/serveAppClass',
	// 	component: () => import('@/view/serveApp/serveAppClass'),
	// 	name: 'ServeAppClass',
	// 	meta: {
	// 		title: '服务工具分类',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '服务工具'
	// 	}
	// },
	// {
	// 	path: '/serveAppList',
	// 	component: () => import('@/view/serveApp/serveAppList'),
	// 	name: 'ServeAppList',
	// 	meta: {
	// 		title: '服务工具列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '服务工具'
	// 	}
	// },
	// {
	// 	path: '/serveAppDetail',
	// 	component: () => import('@/view/serveApp/serveAppDetail'),
	// 	name: 'ServeAppDetail',
	// 	meta: {
	// 		title: '工具详情',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '服务工具'
	// 	}
	// }
];

export const adminRoutes = [
	{
		path: '/jueseList',
		component: () => import('@/view/permision/jueseList'),
		name: 'jueseList',
		meta: {
			title: '角色列表',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	},
	{
		path: '/yuangongList',
		component: () => import('@/view/permision/yuangongList'),
		name: 'YuangongList',
		meta: {
			title: '员工列表',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	},
	{
		path: '/governmentList',
		component: () => import('@/view/permision/governmentList'),
		name: 'GovernmentList',
		meta: {
			title: '区域账号',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	},
	{
		path: '/governmentEdit',
		component: () => import('@/view/permision/governmentEdit'),
		name: 'GovernmentEdit',
		meta: {
			title: '区域账号',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	},
];
const index = {
	path: '/index',
	component: () => import('@/view/index/index'),
	name: 'Index',
	meta: {
		title: '防坠落吊篮智能平台',
		noCache: true,
		permission: '',
		parent: '首页'
	}
};
const threeRoutes = [];
export const initRoutes = [
	{
		path: '/',
		component: Layout,
		redirect: '/index',
		name: 'Index',
		meta: {
			title: '防坠落吊篮智能平台-后台',
			icon: 'location'
		},
		children: [index, ...routes, ...adminRoutes]
	},
	{
		path: '/login',
		component: () => import('@/view/login'),
		name: 'Login',
		meta: {
			title: '登录'
		}
	},
	{
		path: '/h5',
		component: () => import('@/view/h5/index.vue'),
		name: 'h5',
		meta: {
			title: '操作人员'
		}
	},
	{
		path: '/connectList',
		component: () => import('@/view/hide/connectDevice'),
		name: 'ConnectDevice',
		meta: {
			title: '联网设备'
		}
	},
	{
		path: '/404',
		component: () => import('@/view/404'),
		name: '404',
		meta: {
			title: '页面未找到'
		}
	},
	{
		path: '/401',
		component: () => import('@/view/401'),
		name: '401',
		meta: {
			title: '暂无权限'
		}
	},
	// {
	// 	path: '/aboutInfo',
	// 	component: () => import('@/view/aboutInfo'),
	// 	name: 'AboutInfo',
	// 	meta: {
	// 		title: '关于我们'
	// 	}
	// },
	// {
	// 	path: '/webview',
	// 	component: () => import('@/view/webview'),
	// 	name: 'Webview',
	// 	meta: {
	// 		title: '隐私服务'
	// 	}
	// },
	// {
	// 	path: '/policyInfo',
	// 	component: () => import('@/view/policyInfo'),
	// 	name: 'PolicyInfo',
	// 	meta: {
	// 		title: '政策详情'
	// 	}
	// }
];
const createRouter = () =>
	new Router({
		mode: 'hash', // require service support
		base: '/',
		scrollBehavior (to, from, savedPosition) {
			console.log('路由滚动', savedPosition);
			// return new Promise((resolve, reject) => {
			//     setTimeout(() => {
			// 		let a = {}
			// 		if(savedPosition) {
			// 			a = savedPosition
			// 		}else{
			// 			a =  {
			// 				x: 0,
			// 				y: 0
			// 			}
			// 		}
			//       resolve(a)
			//     }, 200)
			//   })
			// let a = {}
			// if(savedPosition) {
			// 	a = savedPosition
			// }else{
			// 	a =  {
			// 		x: 0,
			// 		y: 0
			// 	}
			// }
			// return a
		},
		routes: initRoutes
	});

const router = createRouter();

export function resetRouter () {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}
export default router;
