<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">告警事件</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model
      class="form-search"
      :colon="false"
      layout="inline"
      :model="query"
      labelAlign="left"
      @submit="handleSearch"
      @submit.native.prevent
    >
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-item label="时间" name="day">
          <a-date-picker
            :value="query.day"
            type="day"
            placeholder="请选择时间"
            @change="changeDate"
          />
        </a-form-item>
        <a-form-item label="类型" name="type">
          <a-select
            v-model="query.type"
            placeholder="请选择告警类型"
            @change="changeSelect"
          >
            <a-select-option
              :value="item.value"
              v-for="item in checkOptions"
              :key="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-model-item label="工地名称">
          <a-input v-model="query.company_name" placeholder="请输入工地名称" />
        </a-form-model-item>
        <a-form-model-item label="设备ID">
          <a-input
            v-model="query.equipment_number"
            placeholder="请输入设备ID"
          />
        </a-form-model-item>
        <!-- <a-form-model-item label="物联网号">
          <a-input v-model="query.user" placeholder="物联网号" />
        </a-form-model-item> -->
      </a-row>
      <!-- <a-row style="margin-top: 20px">
        <a-form-model-item label="类型">
          <a-checkbox-group
            v-model="checkValue"
            name="checkboxgroup"
            placeholder="请选择告警类型"
            :options="checkOptions"
            @change="changeCheck"
          />
        </a-form-model-item>
      </a-row> -->
    </a-form-model>
    <!-- <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="toDivide()">分配客户</a-button>
      <a-button type="primary" style="margin-left: 10px" @click="toEdit(-1)"
        >新增设备</a-button
      >
    </a-row> -->
    <!-- :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }" -->
    <a-table
      class="main-table"
      :pagination="{
        current: query.page,
        total: pagetotal,
      }"
      @change="listChange"
      :columns="columns"
      :data-source="data"
      rowKey="equipment_id"
      style="margin-top: 14px"
      v-viewer
    >
      <span slot="warn_type" slot-scope="text">
        <span v-show="text == 1 || text == 5 || text == 6">超载</span>
        <span v-show="text == 2 || text == 7 || text == 8">欠载</span>
        <span v-show="text == 3 || text == 4">超欠载</span>
        <!-- <span v-show="text == 4">离线</span> -->
      </span>
      <!-- <span slot="qr_code" slot-scope="text">
        <img :src="text" class="img-qr" />
      </span> -->
    </a-table>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
  data() {
    return {
      query: {
        day: null,
        page: 1,
        limit: 10,
      },
      customer_id: null,
      pagetotal: 0,
      checkValue: [],
      checkOptions: [
        {
          label: '超载',
          value: 1,
        },
        {
          label: '欠载',
          value: 2,
        },
        {
          label: '超欠载',
          value: 3,
        },
        // {
        //   label: '离线',
        //   value: 4,
        // },
      ],
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '工地名称',
          dataIndex: 'warn_company_name',
          scopedSlots: {
            customRender: 'warn_company_name',
          },
        },
        {
          title: '设备ID',
          dataIndex: 'warn_equipment_number',
        },
        {
          title: '报警值',
          dataIndex: 'warn_value',
        },
        {
          title: '报警类型',
          dataIndex: 'warn_type',
          scopedSlots: {
            customRender: 'warn_type',
          },
        },
        {
          title: '事件内容',
          dataIndex: 'detail',
        },
        {
          title: '报警时间',
          dataIndex: 'create_at_str',
          scopedSlots: {
            customRender: 'create_at_str',
          },
        },
      ],
      data: [],
      selectedRowKeys: [],
    }
  },
  created() {
    this.customer_id = this.$route.query.cid
    this.getList()
  },
  methods: {
    del(id) {
      console.log(id)
      this.$confirm({
        title: '确认',
        content: '确认删除该设备吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/equipment/equipment/delete', {
            equipment_id: id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {},
      })
    },
    changeDate(date, dateStringa) {
      this.query.day = dateStringa
      console.log(date, dateStringa)
    },
    changeSelect(value) {
      console.log(value)
      this.query.type = value
    },
    Reset() {
      this.customer_id = null
      this.query = {
        page: 1,
        limit: this.query.limit,
      }
      this.getList()
    },
    equipmentOn(id, type) {
      this.$post(domain + '/admin/v1/equipment/equipment/on', {
        equipment_id: id,
        type: type,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    equipmentWarn(id) {
      this.$post(domain + '/admin/v1/equipment/equipment/warn', {
        equipment_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toChangeList(id) {
      this.$router.push({
        path: '/changeList',
        query: {
          id: id,
        },
      })
    },
    toContrastList(id) {
      this.$router.push({
        path: '/contrastList',
        query: {
          id: id,
        },
      })
    },
    getList() {
      console.log('这是啥玩意看一下', this.selectedRowKeys)
      let cusSerch = {}
      if (this.customer_id == null) {
        cusSerch = this.query
      } else {
        cusSerch = {
          customer_id: this.customer_id,
          page: this.query.page,
          limit: this.query.limit,
        }
      }
      this.$post(domain + '/admin/v1/warn/list', cusSerch).then((res) => {
        if (res.code === 0) {
          this.data = res.data.list
          this.pagetotal = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    listChange(e) {
      this.query.page = e.current
      this.getList()
    },
    handleSearch(query) {
      this.customer_id = null
      this.getList()
      console.log(query)
    },
    changeCheck(value) {
      if (value.length === 1) {
        this.query.is_on = value[0]
      } else {
        this.query.is_on = null
      }
      this.getList()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
