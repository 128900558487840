<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">工地管理</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model
      class="form-search"
      :colon="false"
      layout="inline"
      :model="query"
      labelAlign="left"
      @submit="handleSearch"
      @submit.native.prevent
    >
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item label="地区">
          <a-cascader
            :field-names="{
              label: 'name',
              value: 'name',
              children: 'districts',
            }"
            v-model="addrValue"
            :load-data="loadSsq"
            change-on-select
            style="min-width: 300px"
            :options="ssqData"
            placeholder="请选择省市区"
            @change="ssqChange"
          />
        </a-form-model-item>
        <!-- <a-form-model-item label="类型">
          <a-select
            v-model="query.type"
            class="a-select"
            placeholder="请选择类型"
            @change="searchList"
          >
            <a-select-option :value="1">
              {{ '租赁客户' }}
            </a-select-option>
            <a-select-option :value="2">
              {{ '产权客户' }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->
        <a-form-model-item label="单位名称">
          <a-input v-model="query.company_name" placeholder="请输入单位名称" />
        </a-form-model-item>
        <a-form-model-item label="区域名称">
          <a-input v-model="query.manager" placeholder="请输入区域名称" />
        </a-form-model-item>
        <a-form-model-item label="设备ID">
          <a-input v-model="query.main_id" placeholder="请输入设备ID" />
        </a-form-model-item>
        <a-form-model-item label="出厂编号">
          <a-input v-model="query.factory_no" placeholder="请输入出厂编号" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="toEdit(-1)">批量归还</a-button>
    </a-row>
    <a-table
      class="main-table"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :pagination="{
        current: query.page,
        total: query.total,
        showLessItems: true,
        showTotal: (total) => {
          return `共${total}条数据`
        },
      }"
      @change="listChange"
      :columns="columns"
      :data-source="data"
      rowKey="id"
      style="margin-top: 14px"
      v-viewer
      :scroll="{ x: true }"
    >
      <span slot="admin_name" slot-scope="text, record">
        <span
          @click="showVisible(record.customer_id)"
          style="color: #1890ff; cursor: pointer"
          >{{ text || '操作员' }}</span
        >
      </span>
      <span slot="equipment_count" slot-scope="text, record">
        <a @click="toEquipmentList(record.customer_id)">{{ text }}</a>
      </span>
      <span slot="one_id" slot-scope="text, record">
        {{ text }}
        <br />
        <a-tag
          :color="
            record.one_status === 1 || record.one_status === 2
              ? '#74CE78'
              : '#FF0000'
          "
          v-if="record.one_status === 1 || record.one_status === 2"
        >
          {{
            record.one_status === 1 || record.one_status === 2 ? '在线' : '离线'
          }}
        </a-tag>
        <a-tag
          :color="
            record.one_status === 1
              ? '#74CE78'
              : record.one_status === 2
              ? '#757776'
              : '#FF0000'
          "
        >
          {{
            record.one_status === 1
              ? '开机'
              : record.one_status === 2
              ? '关机'
              : '离线'
          }}
        </a-tag>
      </span>
      <span slot="two_id" slot-scope="text, record" v-if="record.two_sim">
        {{ text }}
        <br />
        <a-tag
          :color="
            record.two_status === 1 || record.two_status === 2
              ? '#74CE78'
              : '#FF0000'
          "
          v-if="record.two_status === 1 || record.two_status === 2"
        >
          {{
            record.two_status === 1 || record.two_status === 2 ? '在线' : '离线'
          }}
        </a-tag>
        <a-tag
          :color="
            record.two_status === 1
              ? '#74CE78'
              : record.two_status === 2
              ? '#757776'
              : '#FF0000'
          "
        >
          {{
            record.two_status === 1
              ? '开机'
              : record.two_status === 2
              ? '关机'
              : '离线'
          }}
        </a-tag>
      </span>
      <span slot="is_on" slot-scope="text, record">
        <a @click="equipmentOn(record.equipment_id, 1)">开启</a>&nbsp;
        <a style="color: #e0352b" @click="equipmentOn(record.equipment_id, -1)"
          >关机</a
        >
      </span>
      <span slot="warn_type" slot-scope="text, record">
        <a-tag :color="text === 1 ? '#74CE78' : '#757776'">
          {{ text === 1 ? '正常' : '屏蔽' }}
        </a-tag>
        <a-button type="link" @click="equipmentWarn(record.equipment_id)">
          {{ text === 1 ? '屏蔽' : '正常' }}
        </a-button>
      </span>
      <span slot="qr_code" slot-scope="text">
        <img :src="text" class="img-qr" v-if="text" />
        <div src="" v-else alt="" class="img-qr_code" />
      </span>
      <span slot="is_dot" slot-scope="text">
        <span :class="text == 1 ? 'doted' : 'doting'">{{
          text == 1 ? '已定位' : '未定位'
        }}</span>
      </span>
      <span slot="main_id" slot-scope="text, record">
        {{ text }}
        <br />
        <a-tag
          :color="
            record.main_status === 1 || record.main_status === 2
              ? '#74CE78'
              : '#FF0000'
          "
          v-if="record.main_status === 1 || record.main_status === 2"
        >
          {{
            record.main_status === 1 || record.main_status === 2
              ? '在线'
              : '离线'
          }}
        </a-tag>
        <a-tag
          :color="
            record.main_status === 1
              ? '#74CE78'
              : record.main_status === 2
              ? '#757776'
              : '#FF0000'
          "
        >
          {{
            record.main_status === 1
              ? '开机'
              : record.main_status === 2
              ? '关机'
              : '离线'
          }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a style="" @click="toEdit1(record.equipment_id)">编辑</a>
          <a style="color: #e0352b" @click="del(record.equipment_id)">归还</a>
        </a-space>
      </span>
    </a-table>
    <a-drawer
      title="操作员"
      placement="right"
      :closable="true"
      :width="600"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
    >
      <!-- <a-button type="primary" style="margin-bottom: 24px">
        添加操作员
      </a-button> -->

      <a-table
        :data-source="dataInfo"
        :columns="columns1"
        :pagination="{
          current: query1.page,
          total: query1.total,
        }"
        key="123"
        @change="listChange1"
      >
        <!-- <span slot="action1" style="color: #e0352b; cursor: pointer"
          >移除</span
        > -->
      </a-table>
    </a-drawer>
  </div>
</template>

<script>
import { domain } from '@/siteInfo'
import axios from 'axios'
import { ssqdata } from '@/utils/ssqdata'

export default {
  data() {
    return {
      visible: false,
      query: {
        page: 1,
        limit: 10,
        total: 0,
        type: 3,
      },
      query1: {
        page: 1,
        limit: 10,
        total: 0,
      },
      selectedRowKeys: [],
      columns1: [
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '手机号',
          dataIndex: 'phone',
        },
        // {
        //   title: '操作',
        //   dataIndex: 'action1',
        //   scopedSlots: {
        //     customRender: 'action1',
        //   },
        // },
      ],
      columns: [
        // {
        //   title: '序号',
        //   customRender: (text, record, index) => index + 1,
        // },
        {
          title: '工地名称',
          dataIndex: 'company_name',
        },
        {
          title: '区域名称',
          dataIndex: 'gov_name',
        },
        {
          title: '地区',
          customRender: (text, record, index) =>
            `${record.pro}-${record.city}-${record.area}`,
        },
        {
          title: '操作员',
          dataIndex: 'admin_name',
          scopedSlots: {
            customRender: 'admin_name',
          },
        },
        {
          title: '主ID',
          dataIndex: 'main_id',
          scopedSlots: {
            customRender: 'main_id',
          },
        },
        {
          title: '出厂编号',
          dataIndex: 'factory_no',
        },
        {
          title: '从ID-1',
          dataIndex: 'one_id',
          scopedSlots: {
            customRender: 'one_id',
          },
        },
        {
          title: '从ID-2',
          dataIndex: 'two_id',
          scopedSlots: {
            customRender: 'two_id',
          },
        },
        {
          title: '屏蔽状态',
          dataIndex: 'warn_type',
          scopedSlots: {
            customRender: 'warn_type',
          },
        },
        {
          title: '状态',
          dataIndex: 'is_on',
          scopedSlots: {
            customRender: 'is_on',
          },
        },
        {
          title: '定位状态',
          dataIndex: 'is_dot',
          scopedSlots: {
            customRender: 'is_dot',
          },
        },

        {
          title: '二维码',
          dataIndex: 'qr_code',
          scopedSlots: {
            customRender: 'qr_code',
          },
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      data: [],
      dataInfo: [],
      ssqData: ssqdata,
      addrValue: [],
      equipmentIdArr: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    onClose() {
      this.visible = false
    },
    showVisible(id) {
      this.getBindInfo(id)
      this.visible = true
    },
    toEdit1(id) {
      this.$router.push({
        path: '/deviceEdit',
        query: {
          id: id,
          type: 'site',
        },
      })
    },
    equipmentWarn(id) {
      this.$post(domain + '/admin/v1/equipment/equipment/warn', {
        equipment_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    afterVisibleChange() {
      this.data1 = []
    },
    getBindInfo(id) {
      this.$post(domain + '/admin/v1/equipment/customer/bindInfo', {
        customer_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.dataInfo = res.data.op_list
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    del(id) {
      console.log(id)
      this.$confirm({
        title: '确认',
        content: '确认归还吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/equipment/lease/return', {
            equipment_id: id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {},
      })
    },
    listChange(e) {
      this.query.page = e.current
      this.getList()
    },
    listChange1(e) {
      this.query1.page = e.current
      this.getBindInfo()
    },
    ssqChange(e, opt) {
      // this.query.pro = e[0] || "";
      // this.query.city = e[1] || "";
      // this.query.area = e[2] || "";
      this.query.pro_code = opt && opt[0] ? opt[0].adcode : ''
      this.query.city_code = opt && opt[1] ? opt[1].adcode : ''
      this.query.area_code = opt && opt[2] ? opt[2].adcode : ''
    },
    loadSsq(selectedOptions) {
      console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // load options lazily
      let subdistrict =
        targetOption.level == 'province'
          ? 1
          : targetOption.level == 'city'
          ? 2
          : targetOption.level == 'district'
          ? 3
          : 4
      axios
        .get('https://restapi.amap.com/v3/config/district', {
          params: {
            keywords: targetOption.adcode,
            subdistrict: subdistrict,
            key: 'ce7404ac71c28a7893cae3ccc5517da0',
          },
        })
        .then((res) => {
          let districts = res.data.districts[0].districts
          for (let i = 0; i < districts.length; i++) {
            let item = districts[i]
            delete item.districts
            item.isLeaf =
              item.level == 'district' ||
              res.data.districts[0].adcode == 810000 ||
              res.data.districts[0].adcode == 820000
          }
          targetOption.districts = districts
          targetOption.loading = false
          this.ssqData = [...this.ssqData]
        })
        .catch((err) => {
          console.log(err)
        })
    },
    searchList() {
      this.getList()
    },
    onSelectChange(selectedRowKeys) {
      this.equipmentIdArr = []
      this.selectedRowKeys = selectedRowKeys
      selectedRowKeys.forEach((it) => {
        this.equipmentIdArr.push(this.data[it].equipment_id)
      })
    },
    equipmentOn(id, type) {
      this.$post(domain + '/admin/v1/equipment/equipment/on', {
        equipment_id: id,
        type,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
          console.log(res)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toEdit() {
      if (this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: '确认',
          content: '确认归还吗?',
          onOk: () => {
            this.$post(domain + '/admin/v1/equipment/lease/returnMany', {
              equipment_ids: this.equipmentIdArr,
            }).then((res) => {
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
          },
          onCancel: () => {},
        })
      } else {
        this.$message.error('请选择要归还的设备')
      }
    },
    toEquipmentList(cid) {
      this.$router.push({
        path: '/deviceList',
        query: {
          cid: cid,
        },
      })
    },
    getList() {
      console.log('搜索对象', this.query)
      this.$post(
        domain + '/admin/v1/equipment/construction/list',
        this.query
      ).then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.data = res.data.list
          this.query.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    Reset() {
      this.addrValue = []
      this.query = {
        page: this.query.page,
        limit: this.query.limit,
      }
      this.getList()
    },
    handleSearch(query) {
      this.query.page = 1
      this.getList()
      console.log(query)
    },
    changeCheck(value) {
      console.log(value)
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
.img-qr_code {
  width: 30px;
  height: 30px;
  background-image: url('../../assets/qr_code.png');
  background-size: 100%;
}
.doted {
  background: #edac5f;
  border-radius: 2px;
  padding: 2px 10px;
  color: #fff;
}
.doting {
  background: #757776;
  border-radius: 2px;
  padding: 2px 10px;
  color: #fff;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
.ant-table td,
.ant-table th {
  white-space: nowrap;
}
</style>
