<template>
  <div class="app-box">
    <div class="title-box">
      <div class="back" @click="back">
        <a-icon type="left" />
      </div>
      <div class="line"></div>
      <p class="title">项目{{ item_id == -1 ? '新增' : '编辑' }}</p>
    </div>
    <a-divider style="margin-left: -10px" />

    <a-form-model
      class="form-edit"
      :model="form"
      ref="form"
      :rules="rules"
      :labelCol="{ span: 2, offset: 2 }"
      :wrapperCol="{ span: 15 }"
      style="width: 1000px"
    >
      <a-form-model-item label="项目名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入项目名称" />
      </a-form-model-item>
      <a-form-model-item label="区域名称" prop="gov_id">
        <!-- <a-input v-model="form.contact" placeholder="请输入区域名称" /> -->
        <a-auto-complete
          allowClear
          v-model="form.gov_id"
          :data-source="dataSource"
          placeholder="请输入区域名称"
          :filter-option="filterOption"
          @select="selectGovName(form.gov_id)"
        >
          <template slot="dataSource">
            <a-select-option
              v-for="item in dataSource"
              :key="item.gov_id"
              :value="item.gov_id"
            >
              {{ item.name }}
            </a-select-option>
          </template>
        </a-auto-complete>
      </a-form-model-item>
      <a-form-model-item label="工地名称" prop="customer_id">
        <!-- <a-input v-model="form.contact" placeholder="请输入区域名称" /> -->
        <a-auto-complete
          allowClear
          v-model="form.customer_id"
          :data-source="dataSource1"
          placeholder="请输入工地名称"
          :filter-option="filterOption"
        >
          <template slot="dataSource">
            <a-select-option
              v-for="item in dataSource1"
              :key="item.customer_id"
              :value="item.customer_id"
            >
              {{ item.company_name }}
            </a-select-option>
          </template>
        </a-auto-complete>
      </a-form-model-item>
      <a-form-model-item label="其他说明" prop="content">
        <a-textarea
          v-model="form.content"
          placeholder="请输入其他说明"
          :autosize="{ minRows: 6, maxRows: 20 }"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" style="margin-left: 12px" @click="submitForm">
          提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import axios from 'axios'
import { domain } from '@/siteInfo.js'
import { message } from 'ant-design-vue'

export default {
  data() {
    return {
      item_id: '',
      form: {
        name: '',
      },
      addrCode: [],
      curParent: [],
      dataSource: [],
      dataSource1: [],
      rules: {
        name: [
          {
            required: true,
            message: '请输入项目名称',
            trigger: 'change',
          },
        ],
        gov_id: [
          {
            required: true,
            message: '请输入区域名称',
            trigger: 'change',
          },
        ],
        customer_id: [
          {
            required: true,
            message: '请输入单位名称',
            trigger: 'change',
          },
        ],
        // content: [
        //   {
        //     required: true,
        //     message: '请输入联系人',
        //     trigger: 'change',
        //   },
        // ],
      },
    }
  },
  async created() {
    console.log('创建被执行了')
    this.item_id = this.$route.query.id
    this.name = this.$route.query.name

    this.godList()
    if (this.$route.query.id != -1) {
      this.$post(domain + '/admin/v1/item/info', {
        item_id: this.item_id,
      }).then((res) => {
        if (res.code === 0) {
          this.form = {
            item_id: res.data.item_id,
            name: res.data.name,
            gov_id: res.data.gov_id,
            customer_id: res.data.customer_id,
            content: res.data.content,
          }
          this.customerData(res.data.gov_id)
        } else {
          this.$message.error(res.msg)
        }
      })
    } else {
      console.log('id', this.$route.query.id)
    }
  },
  methods: {
    selectGovName(id) {
      // 请求单位数据
      this.customerData(id)
      console.log('zhixing', id)
    },
    // 单位选择器数据
    customerData(gov_id) {
      // 得到单位列表
      this.$post(domain + '/admin/v1//equipment/customer/select', {
        page: 1,
        limit: 9999,
        gov_id,
      }).then((res) => {
        if (res.code === 0) {
          this.dataSource1 = res.data
          console.log(this.dataSource1)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    godList() {
      // 得到政府列表
      this.$post(domain + '/admin/v1/gov/select', {
        page: 1,
        limit: 9999,
        name: this.name,
      }).then((res) => {
        if (res.code === 0) {
          this.dataSource = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toUpperCase()
          .indexOf(input.toUpperCase()) >= 0
      )
    },
    submitForm() {
      let action = ''
      if (this.item_id != -1) {
        action = 'update'
        this.form.item_id = this.item_id
      } else {
        action = 'add'
      }
      console.log(this.form)
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$post(domain + '/admin/v1/item/' + action, this.form).then(
            (res) => {
              if (res.code === 0) {
                this.$message.success('操作成功', 1.5)
                this.$router.back()
              } else {
                this.$message.error(res.msg, 1.5)
              }
            }
          )
        }
        console.log('substatus', valid)
      })
    },
    cancel() {
      console.log('点击取消')
      this.back()
    },
    back() {
      this.$router.back()
    },
  },
}
</script>

<style lang="less" scoped>
.row-two-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item {
    width: 277px;
  }

  .line-two {
    height: 1px;
    background-color: #979797;
    width: 38px;
  }
}

.upload-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-upload {
  width: 86px;
  height: 86px;
}
</style>
<style lang="less">
.form-edit {
  .upload-card {
    .ant-upload.ant-upload-select-picture-card {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
</style>