<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">地图配置</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <div class="content_box">
      <a-row type="flex">
        <a-col :flex="4" class="col_left">
          <div class="title" style="margin-bottom: 20px">
            <span
              ><a-button type="primary" @click="handleMap(-1)"
                >新建地图</a-button
              ></span
            >
            <span
              ><a-button type="primary" @click="toPreviewMap()"
                >地图预览</a-button
              ></span
            >
          </div>
          <div class="title">
            <span><strong>项目名称：</strong>{{ itemData.name }}</span>
            <span><strong>工地名称：</strong>{{ itemData.company_name }}</span>
            <span><strong>区域名称：</strong>{{ itemData.gov_name }}</span>
          </div>
        </a-col>
        <a-col :flex="1">
          <!-- <a-button type="primary" style="margin-right: 18px">报警音</a-button> -->
          <!-- <a-button type="primary" @click="toPreviewMap()">地图预览</a-button> -->
        </a-col>
      </a-row>
      <div class="card_table">
        <div
          style="margin: 20px"
          hoverable
          v-for="item in mapList"
          :key="item.map_id"
        >
          <div class="card_content">
            <p>ID： {{ item.map_id }}</p>
            <div>
              <p>地图名称:{{ item.name }}</p>
              <p>创建时间:{{ item.create_at_str }}</p>
              <p>最近更新:{{ item.updated_at_str }}</p>
            </div>
            <p>
              <a-button type="primary" @click="handleMap(item.map_id)"
                >编辑</a-button
              >
              <a-button
                type="primary"
                style="margin: 0px 33px"
                @click="toEditMap(item.map_id)"
                >制图</a-button
              >
              <a-button type="primary" @click="del(item.map_id)">删除</a-button>
            </p>
          </div>
        </div>
      </div>
      <a-pagination
        v-if="mapList.length > 0"
        v-model="pagin.page"
        :total="total"
        show-less-items
        @change="handlePage"
        style="text-align: center"
      />
    </div>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
  data() {
    return {
      item_id: '',
      mapList: [],
      total: 0,
      itemData: {},
      pagin: {
        page: 1,
        limit: 8,
      },
    }
  },
  created() {
    this.item_id = this.$route.query.id
    this.itemData = JSON.parse(localStorage.getItem('info') || {})

    this.getMapList()
    // this.itemInfo()
  },
  methods: {
    // 新增编辑地图
    handleMap(id = -1) {
      this.$router.push({
        path: '/actionMap',
        query: {
          id: id,
          itemId: this.item_id,
        },
      })
    },
    toPreviewMap() {
      this.$router.push({
        path: '/previewMap',
        query: {
          id: this.item_id,
        },
      })
    },
    // 删除地图
    del(id) {
      console.log(id)
      this.$confirm({
        title: '确认',
        content: '确认删除该地图吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/map/delete', {
            map_id: id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getMapList()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {},
      })
    },
    // 地图列表
    getMapList() {
      this.$post(domain + '/admin/v1/map/list', {
        item_id: this.item_id,
        ...this.pagin,
      }).then((res) => {
        if (res.code === 0) {
          this.mapList = res.data.list
          this.total = res.data.total
          console.log(this.total)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePage(page, pageSize) {
      this.pagin.page = page
      this.getMapList()
    },
    // 制图
    toEditMap(id) {
      this.$router.push({
        path: '/editMap',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}
.content_box {
  .title {
    font-size: 16px;
    color: #383f50;
    font-weight: 500;
  }
  .col_left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 20px;
    span {
      margin-right: 29px;
    }
  }
  .card_table {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    > div {
      border-radius: 6.5px;
      width: 362px;
      height: 232px;
      box-shadow: 0px 0px 3px 0px rgba(201, 216, 244, 0.84);
      border-radius: 13px;
      border: 1px solid #ffffff;
      .card_content {
        padding: 30px 45px 9px;
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        color: #212834;
        font-size: 14px;
        font-weight: 400;
        p {
          margin-bottom: 4px;
        }
      }
    }
    // > :first-child {
    //   margin-left: 0 !important;
    // }
    // > :nth-child(4n + 1) {
    //   margin-left: 0 !important;
    // }
    // > :nth-child(4n) {
    //   margin-right: 0 !important;
    // }
  }
}
.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
