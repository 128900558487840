<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">资质列表</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item label="名称">
					<a-input v-model="query.name" placeholder="请输入名称" />
				</a-form-model-item>
				<a-form-model-item label="操作人">
					<a-input v-model="query.admin_name" placeholder="请输入操作人" />
				</a-form-model-item>

				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="toEdit(-1)">新增资质</a-button>
		</a-row>
		<a-table class="main-table" :pagination="{
		current: query.page, 
		total: query.total}" @change="listChange" :columns="columns" :data-source="data" rowKey="id" style="margin-top: 14px"
			v-viewer>
			<span slot="is_on" slot-scope="text, record">
				<a-tag :color="text === 1 ? '#74CE78' : '#757776'">
					{{ text === 1 ? '开启' : '关闭' }}
				</a-tag>
				<a-button type="link" @click="equipmentOn(record.material_id)">
					{{ text === 1 ? '关闭' : '开启' }}
				</a-button>
			</span>
			<span slot="type" slot-scope="text">
				{{ text === 1 ? '视频' : '图片' }}
			</span>
			<span slot="material" slot-scope="text, record">
				<div v-if="record.type == 1">
					<img :src="text+'?x-oss-process=video/snapshot,t_5000,f_png,w_0,h_0,m_fast'" class="img-qr">
				</div>
				<div v-else>
					<img :src="text" class="img-qr">
				</div>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record.material_id)">编辑</a>
					<a style="color: #E0352B" @click="del(record.material_id,record.type)">删除</a>
				</a-space>
			</span>
		</a-table>
	</div>
</template>

<script>
	import {
		domain
	} from '@/siteInfo.js'

	export default {
		data() {
			return {
				query: {
					page: 1,
					limit: 10,
					total: 0
				},
				checkValue: [],
				checkOptions: [{
						label: '开启',
						value: 1
					},
					{
						label: '关闭',
						value: -1
					}
				],
				columns: [{
						title: '序号',
						customRender: (text, record, index) => index + 1
					},
					{
						title: '名称',
						dataIndex: 'name'
					},
					{
						title: '类型',
						dataIndex: 'type',
						scopedSlots: {
							customRender: 'type'
						}
					},
					{
						title: '视频',
						dataIndex: 'material',
						scopedSlots: {
							customRender: 'material'
						}
					},
					{
						title: '上传时间',
						dataIndex: 'create_at_str'
					},
					{
						title: '操作人',
						dataIndex: 'admin_name'
					},
					{
						title: '状态',
						dataIndex: 'is_on',
						scopedSlots: {
							customRender: 'is_on'
						}
					},
					{
						title: '操作',
						scopedSlots: {
							customRender: 'action'
						}
					}
				],
				data: [],
			}
		},
		created() {
			this.getList()
		},
		methods: {
			del(id, type) {
				let typeMsg = '';
				if (type == 1) {
					typeMsg = '视频';
				} else {
					typeMsg = '图片';
				}
				console.log(id, type)
				this.$confirm({
					title: '确认',
					content: '确认删除该' + typeMsg + '吗',
					onOk: () => {
						this.$post(domain + '/admin/v1/company/qualification/delete', {
							material_id: id
						}).then(res => {
							if (res.code === 0) {
								this.$message.success(res.msg)
								this.getList()
							} else {
								this.$message.error(res.msg)
							}
						})
					},
					onCancel: () => {},
				})
			},
			listChange(e) {
				this.query.page = e.current
				this.getList();
			},
			equipmentOn(id) {
				this.$post(domain + '/admin/v1/company/qualification/on', {
					material_id: id
				}).then(res => {
					if (res.code === 0) {
						this.$message.success(res.msg)
						this.getList()
						console.log(res)
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			toEdit(id) {
				this.$router.push({
					path: '/qualificationEdit',
					query: {
						id: id
					}
				})
			},
			getList() {
				this.$post(domain + '/admin/v1/company/qualification/list', this.query).then(res => {
					console.log(res)
					if (res.code === 0) {
						this.data = res.data.list
						this.query.total = res.data.total
					} else {
						this.$message.error(res.msg)
					}
				})
			},
			Reset() {
				this.query.name = undefined
				this.query.admin_name = undefined
				this.getList()
			},
			handleSearch(query) {
				this.getList()
				console.log(query)
			},
			changeCheck(value) {
				console.log(value)
			},
		}
	}
</script>

<style lang="less" scoped>
	.title-box {
		display: flex;
		align-items: center;
		margin-left: 8px;

		.line {
			width: 5px;
			height: 14px;
			background-color: #4E80F8;
		}

		.title {
			color: #383F50;
			font-size: 16px;
			margin-left: 5px;
		}
	}

	.img-qr {
		height: 30px;
		cursor: pointer;
	}
</style>

<style lang="less">
	.form-search {
		.ant-form-item-label {
			width: 70px;
		}
	}
</style>
