// 时间戳转化成时间
export const disTimeToDate = (time) => {
    // if(!time){
    //   return 
    // }

    const date = new Date(time * 1000 || '');  // 参数需要毫秒数，所以这里将秒数乘于 1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = date.getDate() + ' ';
    let h = date.getHours() + ':';
    let m = date.getMinutes() + ':';
    let s = date.getSeconds();
    const formatterDate = Y + M + D + h + m + s
    return formatterDate
}