<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">地图预览</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <div class="content_box">
      <a-row type="flex">
        <a-col :flex="4" class="col_left">
          <div class="title">
            <span>项目：{{ itemData.company_name }}</span>
            <span>区域：{{ itemData.gov_name }} </span>
            <span>工地：{{ itemData.name }}</span>
          </div>
        </a-col>
      </a-row>
      <div class="card_table">
        <div class="left_list">
          <ul>
            <li
              v-for="item in mapList"
              :key="item.map_id"
              :class="activeId == item.map_id ? 'active' : ''"
              @click="handleMap(item.map_id)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="right_content">
          <canvasImage
            :url="map_url"
            :dotData="dotData"
            :isPreview="isPreview"
            v-if="map_url"
          />
          <div class="mask"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import canvasImage from '@/components/Fabric/Fabric.vue'

export default {
  components: {
    canvasImage,
  },
  data() {
    return {
      item_id: '',
      mapList: [],
      itemData: {},
      data: {},
      map_url: '',
      dotData: [],
      activeId: '',
      isPreview: true,
    }
  },
  created() {
    this.item_id = this.$route.query.id
    this.itemData = JSON.parse(localStorage.getItem('info') || {})
    this.getList()
  },
  methods: {
    getList() {
      this.$post(domain + '/admin/v1/map/list', {
        item_id: this.item_id,
        page: 1,
        limit: 999,
      }).then((res) => {
        if (res.code === 0) {
          this.mapList = res.data.list
          this.handleMap(this.mapList[0].map_id)
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleMap(id) {
      this.map_url = ''
      this.activeId = id
      this.getMapInfo(id)
    },
    getMapInfo(id) {
      this.$post(domain + '/admin/v1/map/info', {
        map_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.map_url = this.data.map_url
          console.log(this.map_url)
          this.getDotData(res.data.map_id)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getDotData(id) {
      this.$post(domain + '/admin/v1/map/dotEquipmentList', {
        map_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.dotData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.app-box {
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
}
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    font-size: 16px;
    margin-left: 5px;
    font-weight: 500;
    color: #161617;
  }
}
.content_box {
  display: flex;
  flex: 1;
  flex-direction: column;
  .title {
    font-size: 16px;
    color: #383f50;
    font-weight: 500;
  }
  .col_left {
    display: flex;
    align-items: center;
    span {
      margin-right: 29px;
    }
  }
  .card_table {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 19px;
    flex: 1;
    overflow-x: hidden;

    padding: 0 11px;
    padding-top: 21px;
    background: rgba(239, 239, 239, 0.53);
    border: 1px solid rgba(151, 151, 151, 0.61);
    .upload_box {
      width: 50%;
      height: 450px;
      background: rgba(239, 239, 239, 0.53);
      .ant-upload-text {
        font-size: 16px;
        font-weight: 500;
        color: #383f50;
      }
    }
    .left_list {
      width: 215px;
      height: 100%;
      max-height: 630px;
      overflow-y: auto;
      position: relative;
      z-index: 9;
      ul {
        li {
          height: 52px;
          background: #fffefe;
          border-radius: 2px;
          border: 1px solid #2b6ae0;
          margin-bottom: 18px;
          font-size: 16px;
          font-weight: 400;
          color: #2b6ae0;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .active {
          background: #4e80f8;
          border-radius: 2px;
          border: 1px solid #2b6ae0;
          color: #fff;
        }
      }
    }
    .right_content {
      flex: 1;
      position: relative;
      overflow-x: hidden;
      // transform: translate(0%, 10%) scale(1.2);
      //   > :first-child {
      //   }
      .mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        z-index: 9;
      }
    }
  }
}
.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.upload_box {
  .ant-upload.ant-upload-drag {
    border: 2px dashed rgba(151, 151, 151, 0.61);
    background: rgba(239, 239, 239, 0.53);
  }
}
.drewer-box {
  .ant-form-item-label {
    width: 90px;
  }
  .right_p {
    text-align: right;
  }
  .center_p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.85);
    span {
      width: 50%;
    }
    :first-child {
      text-align: right;
    }
    :last-child {
      color: #343436;
    }
  }
}
</style>
