<template>
	<div class="app-box">
		<div class="title-box">
			<div class="back" @click="back">
				<a-icon type="left" />
			</div>
			<div class="line"></div>
			<p class="title">设备变更</p>
		</div>
		<a-divider style="margin-left: -10px" />

		<a-form-model class="form-edit" :model="form" ref="form" :rules="rules" :labelCol="{ span: 2, offset: 2 }"
			:wrapperCol="{ span: 15 }" style="width: 1000px">

			<a-form-model-item label="变更内容" prop="content">
				<a-input v-model="form.content" placeholder="请输入变更内容" />
			</a-form-model-item>
			<a-form-model-item label=" " :colon="false">
				<a-button @click="cancel">取消</a-button>
				<a-button type="primary" style="margin-left: 12px" @click="submitForm">
					提交
				</a-button>
			</a-form-model-item>
		</a-form-model>
	</div>
</template>

<script>
import axios from "axios";
import {
	domain
} from '@/siteInfo.js'
import {
	message
} from 'ant-design-vue'
export default {
	data() {
		return {
			form: {
				content: ''
			},
			rules: {
				content: [{
					required: true,
					message: '请输入变更内容',
					trigger: 'change'
				}],
			}
		}
	},
	methods: {
		validator(key, callback) {
			if (this.form[key] && this.form[key].length > 0) {
				callback()
			} else {
				callback(new Error('请输入物联网号'))
			}
		},
		submitForm() {
			this.form.equipment_id = this.$route.query.id
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$post(domain + '/admin/v1/equipment/change/do', this.form).then(res => {
						if (res.code === 0) {
							this.$message.success('操作成功', 1.5)
							this.$router.back()
						} else {
							this.$message.error(res.msg, 1.5)
						}
					})
				}
				console.log('substatus', valid)
			})
		},
		cancel() {
			console.log('点击取消')
			this.back()
		},
		back() {
			this.$router.back()
		},
	},
}
</script>

<style lang="less" scoped>
.row-two-item {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.item {
		width: 277px;
	}

	.line-two {
		height: 1px;
		background-color: #979797;
		width: 38px;
	}
}

.upload-card {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.img-upload {
	width: 86px;
	height: 86px;
}
</style>
<style lang="less">
.form-edit {
	.upload-card {
		.ant-upload.ant-upload-select-picture-card {
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}
</style>
