<template>
  <div class="maintenancePlanAdd">
    <div class="child-panel-title">
      <!-- <h2>图形标注</h2> -->
    </div>
    <div class="panel-body">
      <div class="demo">
        <canvas id="canvas" :width="width" :height="height"></canvas>
        <div class="draw-btn-group" v-if="!showAction">
          <!-- <div title="填充颜色" class="color_input">
            <input
              type="color"
              v-model="fillColor"
              @input="fillColorChange($event)"
            />
          </div>
          <div title="画笔颜色" class="color_input">
            <input type="color" v-model="color" @input="colorChange($event)" />
            <div class="color_full"></div>
          </div> -->
          <!-- <div @click="undo" title="上一步">
            <i class="shang-icon draw-icon"></i>
          </div>
          <div @click="redo" title="下一步">
            <i class="next-icon draw-icon"></i>
          </div>
          <div
            :class="{ active: drawType == '' }"
            title="自由选择"
            @click="drawTypeChange('')"
          >
            <i class="draw-icon icon-mouse"></i>
          </div>
          <div
            :class="{ active: drawType == 'fullEllipse' }"
            title="打点"
            @click="drawTypeChange('fullEllipse')"
          >
            <i class="draw-icon icon-3"></i>
          </div> -->
          <!-- <div
            :class="{ active: drawType == 'line' }"
            title="画直线"
            @click="drawTypeChange('line')"
          >
            <i class="draw-icon icon-1"></i>
          </div>
          <div
            :class="{ active: drawType == 'text' }"
            title="文本输入框"
            @click="drawTypeChange('text')"
          >
            <i class="draw-icon icon-2"></i>
          </div>
          <div
            :class="{ active: drawType == 'ellipse' }"
            title="画圆"
            @click="drawTypeChange('ellipse')"
          >
            <i class="draw-icon icon-3"></i>
          </div>
          <div
            :class="{ active: drawType == 'rectangle' }"
            title="画矩形"
            @click="drawTypeChange('rectangle')"
          >
            <i class="draw-icon icon-4"></i>
          </div>
          <div
            :class="{ active: drawType == 'polygon' }"
            title="画多边形"
            @click="drawPolygon"
          >
            <i class="draw-icon icon-6"></i>
          </div>
          <div
            :class="{ active: drawType == 'pen' }"
            title="笔画"
            @click="drawTypeChange('pen')"
          >
            <i class="draw-icon icon-7"></i>
          </div>
          <div title="填充颜色" @click="handlefillColor()">
            <i class="draw-icon icon-color"></i>
          </div> -->
          <!-- <div title="删除" @click="handleDelect()">
            <i class="draw-icon icon-del"></i>
          </div> -->
          <!-- <div
            :class="{ active: drawType == 'erase' }"
            title="橡皮擦"
            @click="drawTypeChange('erase')"
          >
            橡皮擦
          </div> -->
          <!-- <div
            :class="{ active: drawType == 'pentagram' }"
            title="五角星"
            @click="drawTypeChange('pentagram')"
          >
            <i class="draw-icon icon-pentagram"></i>
          </div> -->
          <!-- <div @click="uploadImg" title="从文件选择图片上传">
            <i class="draw-icon icon-img"></i>
          </div>
          <div @click="loadExpImg" title="加载背景图">
            <i class="draw-icon icon-back"></i>
          </div> -->
          <!-- <div @click="save" title="保存">
            <i class="draw-icon icon-save"></i>
          </div> -->
        </div>
      </div>
    </div>
    <input
      type="file"
      @change="uploadImgChange"
      id="imgInput"
      accept="image/*"
    />
    <img id="img" :src="imgSrc" />
    <img id="expImg" :src="url" />
  </div>
</template>
<script>
export default {
  name: 'Fabric',
  props: {
    url: String,
    isFullType: String,
    equipment_id: String,
    dotData: Array,
    delId: String,
    isPreview: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      width:
        (window.screen.width * 4) / 5 > 1920
          ? 1920
          : (window.screen.width * 4) / 5,
      height:
        (window.screen.height * 4) / 5 > 1080
          ? 1080
          : (window.screen.height * 4) / 5,
      rect: [],
      scaleObj: {
        width:
          ((window.screen.width * 4) / 5 > 1920 ? 1920 : window.screen.width) /
          1707,
        height:
          ((window.screen.height * 4) / 5 > 1080
            ? 1080
            : window.screen.height) / 968,
      },
      canvas: {},
      showMenu: false,
      x: '',
      y: '',
      mapUrl: '',
      showAction: this.isPreview,
      scaleX: 1,
      mouseFrom: {},
      mouseTo: {},
      drawType: 'fullEllipse', //当前绘制图像的种类
      canvasObjectIndex: 0,
      textbox: null,
      rectangleLabel: 'warning',
      drawWidth: 1, //笔触宽度
      color: '#19EE21', //画笔颜色
      fillColor: '#19EE21', //填充颜色
      drawingObject: null, //当前绘制对象
      moveCount: 1, //绘制移动计数器
      doDrawing: false, // 绘制状态
      canvasHistory: [], // 步骤状态数组
      currentStep: -1, // 当前显示的步骤索引
      //polygon 相关参数
      polygonMode: false,
      pointArray: [],
      lineArray: [],
      activeShape: false,
      activeLine: '',
      line: {},
      moveTargetPolygon: {},
      delectKlass: {},
      imgFile: {},
      imgSrc: '',
      rightKey: false,
      fullType: this.isFullType,
    }
  },
  watch: {
    drawType() {
      this.canvas.selection = !this.drawType
    },
    width() {
      this.canvas.setWidth(this.width)
    },
    height() {
      this.canvas.setHeight(this.height)
    },
    // url(newV, oldV) {
    //   console.log(newV, oldV)
    //   this.loadExpImg()
    // },
    isFullType() {
      this.fullType = this.isFullType
      console.log(this.fullType)
      if (this.fullType == 'dot') {
        this.changefullEllipse()
        this.fullType = 'close'
      }
    },
    dotData() {
      if (this.dotData.length > 0) {
        console.log(this.dotData)
        // 渲染点位
        this.dotData.forEach((item) => {
          let wran
          if (item.main_status == 1) {
            wran = '#19EE21'
          } else if (item.main_status == 2) {
            wran = '#F00202'
          } else if (item.main_status == 3) {
            wran = 'gray'
          }
          this.renderDot(
            item.coordinate_x,
            item.coordinate_y,
            item.equipment_id,
            wran
          )
        })
      }
    },
    delId() {
      if (this.delId) {
        this.canvas.getObjects().forEach((obj) => {
          if (obj.equipment_id && obj.equipment_id == this.delId) {
            this.canvas.remove(obj)
          }
        })
      }
    },
  },
  methods: {
    // 选择颜色
    colorChange(e) {
      console.log(this.color, e)
    },
    // 选择填充颜色
    fillColorChange() {},
    // 保存当前画布为png图片
    save() {
      var canvas = document.getElementById('canvas')
      var imgData = canvas.toDataURL('png')
      imgData = imgData.replace('image/png', 'image/octet-stream')

      // 下载后的问题名，可自由指定
      var filename = 'drawingboard_' + new Date().getTime() + '.' + 'png'
      this.saveFile(imgData, filename)
    },
    saveFile(data, filename) {
      var save_link = document.createElement('a')
      save_link.href = data
      save_link.download = filename

      var event = document.createEvent('MouseEvents')
      event.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      )
      save_link.dispatchEvent(event)
    },
    uploadImg() {
      document.getElementById('imgInput').click()
    },
    // 从已渲染的DOM元素加载图片至canvas
    loadExpImg() {
      var imgElement = document.getElementById('expImg') //声明我们的图片

      // const width = imgElement.width > 960 ? 960 : imgElement.width
      // const height = imgElement.height > 540 ? 540 : imgElement.height
      // if (this.showAction) {
      //   this.width = this.width - 210
      //   imgElement.width = this.width
      // }
      const imgHScale =
        imgElement.height > this.height ? this.height / imgElement.height : 1
      const imgScale =
        imgElement.width > this.width ? this.width / imgElement.width : 1
      console.log(111, imgHScale, imgScale)
      // console.log(width, imgHScale)
      // eslint-disable-next-line no-undef
      var imgInstance = new fabric.Image(imgElement, {
        selectable: false,
        // width: width,
        // height: height,
        lockMovementX: false, // 禁止水平移动
        lockMovementY: false, // 禁止垂直移动
        scaleX: imgScale,
        scaleY: imgHScale,
        // zIndex:-99,
      })
      this.canvas.add(imgInstance)
      this.added()
    },
    // 从文件加载图片至canvas
    uploadImgChange() {
      // 获取文件
      var eleImportInput = document.getElementById('imgInput')
      this.imgFile = eleImportInput.files[0]
      var imgSrc = '',
        imgTitle = ''
      // 从reader中获取选择文件的src
      if (/\.(jpe?g|png|gif)$/i.test(this.imgFile.name)) {
        var reader = new FileReader()
        var _this = this
        reader.addEventListener(
          'load',
          function () {
            imgTitle = _this.imgFile.name
            _this.imgSrc = this.result
          },
          false
        )
        reader.readAsDataURL(this.imgFile)
      }
      var imgElement = document.getElementById('img') //声明我们的图片

      imgElement.onload = () => {
        this.width = imgElement.width
        this.height = imgElement.height
        // eslint-disable-next-line no-undef
        var imgInstance = new fabric.Image(imgElement, {
          zIndex: -1,
          selectable: false,
        })
        this.canvas.add(imgInstance)
      }
    },
    // 开始绘制时，指定绘画种类
    drawTypeChange(e) {
      this.drawType = e
      this.canvas.skipTargetFind = !!e
      switch (e) {
        case 'pen':
          // isDrawingMode为true 才可以自由绘画
          this.canvas.isDrawingMode = true
          break
        case 'erase':
          this.canvas.isDrawingMode = true
          // 自由绘画模式 画笔类型设置为 橡皮擦对象
          // eslint-disable-next-line no-undef
          this.canvas.freeDrawingBrush = new fabric.EraserBrush(this.canvas)
          // 设置橡皮擦大小
          this.canvas.freeDrawingBrush.width = 10
          console.log(this.canvas.freeDrawingBrush)
          break
        default:
          this.canvas.isDrawingMode = false
          break
      }
      // if (e == 'pen') {
      // } else {
      //   if (e == 'erase') {
      //   }
      //   this.canvas.isDrawingMode = false
      // }
    },
    // 打点操作
    drawFullEllipseChange() {},
    // 鼠标按下时触发
    mousedown(e) {
      // 记录鼠标按下时的坐标
      var xy = e.pointer || this.transformMouse(e.e.offsetX, e.e.offsetY)
      this.mouseFrom.x = xy.x
      this.mouseFrom.y = xy.y
      this.doDrawing = true
      console.log(xy, e.target)
      if (e.target?.selfType && e.target?.selfType === 'fullEllipse') {
        // 说明点击的是打点设备，查看详情
        console.log(e.target)
        this.$emit('showDotDetails', e.target.equipment_id)
        return
      }
      if (this.drawType == 'text') {
        this.drawing()
      }
      if (this.drawType == 'fullEllipse') {
        this.changefullEllipse()
        return
      }
      console.log(e.button)

      if (this.textbox) {
        this.textbox.enterEditing()
        this.textbox.hiddenTextarea.focus()
      }
      // 绘制多边形
      if (this.drawType == 'polygon') {
        this.canvas.skipTargetFind = false
        try {
          // 此段为判断是否闭合多边形，点击红点时闭合多边形
          if (this.pointArray.length > 1) {
            // e.target.id == this.pointArray[0].id 表示点击了初始红点
            if (e.target && e.target.id == this.pointArray[0].id) {
              this.generatePolygon()
            }
          }
          //未点击红点则继续作画
          if (this.polygonMode) {
            this.addPoint(e)
          }
        } catch (error) {
          console.log(error)
        }
      }
      if (e.button === 3) {
        this.rightKey = true
      } else {
        this.rightKey = false
      }
      // // 检查是否点击到了多边形对象
      // if (e.button === 3) {
      //   // 右击进入编辑模式
      //   if (e.target && e.target instanceof fabric.Polygon) {
      //     console.log(e.target)
      //     // 显示编辑控制点
      //     // 启用多边形的编辑模式
      //     if (e.target.editable) {
      //       // 从多边形对象中获取小圆点对象
      //       // 判断是否存在控制点
      //       console.log(e.target.hasControls)
      //       this.canvas.getObjects().forEach((obj) => {
      //         // 判断对象是否为小圆点，这里通过控制 `cid` 属性来判断对象
      //         if (obj.isCircle) {
      //           this.canvas.remove(obj)
      //         }
      //       })
      //       // if (e.target.hasControls.length > 0) {
      //       //   // 移除所有控制点
      //       //   e.target.controls.forEach((control) => {
      //       //     this.canvas.remove(control)
      //       //   })
      //       // }
      //       e.target.editable = false
      //       // 渲染画布
      //       this.canvas.renderAll()
      //     } else {
      //       e.target.editable = true
      //       const points = e.target.points
      //       console.log(points)
      //       // 圆形列表
      //       let circleList = []
      //       // 循环所有定点并创建圆形
      //       points.forEach((item, index) => {
      //         circleList.push(
      //           new fabric.Circle({
      //             left: item.x,
      //             top: item.y,
      //             strokeWidth: 1,
      //             radius: 6,
      //             fill: '#fff',
      //             stroke: '#666',
      //             originX: 'center', // x轴方向以中心点为原点
      //             originY: 'center', // y轴方向以中心点为原点
      //             hasControls: false, // 不显示控制器
      //             hasBorders: false, // 不显示控制器的边
      //             cid: `circle-${index}`, // 自定义属性
      //             isCircle: true,
      //           })
      //         )
      //       })
      //       // // 将多边形和圆形对象添加到画布中
      //       this.canvas.add(e.target, ...circleList)
      //     }
      //     console.log(e.target)
      //     this.moveTargetPolygon = e.target
      //     // e.target.cornerStyle = 'circle' // 设置调整点的样式
      //     // // e.target.cornerColor = 'rgba(0, 0, 255, 0.5)' // 设置调整点的颜色
      //     // e.target.transparentCorners = true
      //     // e.target.cornerColor = 'rgba(255, 255, 255, 0.8)'
      //     // e.target.cornerStrokeColor = 'red'
      //     // e.target.borderColor = 'red'
      //     // e.target.cornerSize = 12
      //     // e.target.padding = 10
      //     // e.target.cornerStyle = 'circle'
      //     // e.target.borderDashArray = [10, 10]
      //     // e.target.setControlsVisibility({
      //     //   tl: true, // 左上角
      //     //   tr: true, // 右上角
      //     //   br: true, // 右下角
      //     //   bl: true, // 左下角
      //     //   mt: true, // 顶部中间
      //     //   mb: true, // 底部中间
      //     //   mr: true, // 右侧中间
      //     //   ml: true, // 左侧中间
      //     //   mtr: true, // 角度旋转控制点
      //     // })
      //     // // 设置当前选中的对象
      //     // this.canvas.setActiveObject(e.target)
      //     // 监听多边形的移动事件
      //     // e.target.on('mousemove', function (event) {
      //     //   // if ('cid' in target && target.cid.match(RegExp(/circle-/))) {
      //     //   //   let index = target.cid.split('-')[1] // 通过cid可以判断出当前操作的是哪个圆形，并且圆形的cid最后一位对应多边形指定的顶点
      //     //   //   console.log(index)
      //     //   //   let points = this.moveTargetPolygon.points // 获取多边形定点
      //     //   //   console.log(points)
      //     //   //   points[index].x = target.left // 修改指定顶点的x坐标
      //     //   //   points[index].y = target.top // 修改指定定点的y坐标
      //     //   //   console.log(points)
      //     //   //   this.moveTargetPolygon.points = points
      //     //   //   this.canvas.renderAll() // 刷新画布
      //     //   // }
      //     //   console.log('mousemove')
      //     //   // 获取多边形的顶点数组
      //     //   var points = e.target.points
      //     //   // 获取多边形的中心点坐标
      //     //   var centerX = e.target.left + e.target.width / 2
      //     //   var centerY = e.target.top + e.target.height / 2
      //     //   // 遍历顶点数组，更新各顶点的坐标
      //     //   points.forEach(function (point, index) {
      //     //     var deltaX = point.x - centerX
      //     //     var deltaY = point.y - centerY
      //     //     // 根据多边形的移动距离，更新顶点的坐标
      //     //     point.x = event.target.left + e.target.width / 2 + deltaX
      //     //     point.y = event.target.top + e.target.height / 2 + deltaY
      //     //   })
      //     //   // 更新多边形的顶点数组
      //     //   e.target.set('points', points)
      //     //   // 重新渲染画布
      //     //   this.canvas.renderAll()
      //     // })
      //   } else if (this.drawType == 'polygon') {
      //     this.generatePolygon()
      //   }
      // }
    },
    // 鼠标松开执行
    mouseup(e) {
      var xy = e.pointer || this.transformMouse(e.e.offsetX, e.e.offsetY)
      this.mouseTo.x = xy.x
      this.mouseTo.y = xy.y
      this.drawingObject = null
      this.moveCount = 1
      // if (this.drawType == 'fullEllipse') {
      //   this.changefullEllipse()
      // }
      if (this.drawType != 'polygon') {
        this.doDrawing = false
      }
      this.added()
      console.log(e.target)
      // 检查是否点击到了多边形对象
      if (e.button === 3) {
        // 右击进入编辑模式
        // eslint-disable-next-line no-undef
        if (e.target && e.target instanceof fabric.Polygon) {
          // 显示编辑控制点
          // 启用多边形的编辑模式
          if (e.target.editable) {
            // 从多边形对象中获取小圆点对象
            // 判断是否存在控制点
            this.canvas.getObjects().forEach((obj) => {
              // 判断对象是否为小圆点，这里通过控制 `cid` 属性来判断对象
              if (obj.isCircle) {
                this.canvas.remove(obj)
              }
            })
            // if (e.target.hasControls.length > 0) {
            //   // 移除所有控制点
            //   e.target.controls.forEach((control) => {
            //     this.canvas.remove(control)
            //   })
            // }
            e.target.editable = false
            // 渲染画布
            this.canvas.renderAll()
          } else {
            e.target.editable = true
            const points = e.target.points
            console.log(this.mouseTo.x, this.mouseTo.y, points)
            // 圆形列表
            let circleList = []
            // 循环所有定点并创建圆形
            points.forEach((item, index) => {
              circleList.push(
                // eslint-disable-next-line no-undef
                new fabric.Circle({
                  left: item.x,
                  top: item.y,
                  strokeWidth: 1,
                  radius: 6,
                  fill: '#fff',
                  stroke: '#666',
                  originX: 'center', // x轴方向以中心点为原点
                  originY: 'center', // y轴方向以中心点为原点
                  hasControls: false, // 不显示控制器
                  hasBorders: false, // 不显示控制器的边
                  cid: `circle-${index}`, // 自定义属性
                  isCircle: true,
                })
              )
            })
            // // 将多边形和圆形对象添加到画布中
            this.canvas.add(e.target, ...circleList)
          }
          console.log(e.target)
          this.moveTargetPolygon = e.target
          // e.target.cornerStyle = 'circle' // 设置调整点的样式
          // // e.target.cornerColor = 'rgba(0, 0, 255, 0.5)' // 设置调整点的颜色
          // e.target.transparentCorners = true
          // e.target.cornerColor = 'rgba(255, 255, 255, 0.8)'
          // e.target.cornerStrokeColor = 'red'
          // e.target.borderColor = 'red'
          // e.target.cornerSize = 12
          // e.target.padding = 10
          // e.target.cornerStyle = 'circle'
          // e.target.borderDashArray = [10, 10]
          // e.target.setControlsVisibility({
          //   tl: true, // 左上角
          //   tr: true, // 右上角
          //   br: true, // 右下角
          //   bl: true, // 左下角
          //   mt: true, // 顶部中间
          //   mb: true, // 底部中间
          //   mr: true, // 右侧中间
          //   ml: true, // 左侧中间
          //   mtr: true, // 角度旋转控制点
          // })
          // // 设置当前选中的对象
          // this.canvas.setActiveObject(e.target)
          // 监听多边形的移动事件
          // e.target.on('mousemove', function (event) {
          //   // if ('cid' in target && target.cid.match(RegExp(/circle-/))) {
          //   //   let index = target.cid.split('-')[1] // 通过cid可以判断出当前操作的是哪个圆形，并且圆形的cid最后一位对应多边形指定的顶点
          //   //   console.log(index)
          //   //   let points = this.moveTargetPolygon.points // 获取多边形定点
          //   //   console.log(points)
          //   //   points[index].x = target.left // 修改指定顶点的x坐标
          //   //   points[index].y = target.top // 修改指定定点的y坐标
          //   //   console.log(points)
          //   //   this.moveTargetPolygon.points = points
          //   //   this.canvas.renderAll() // 刷新画布
          //   // }
          //   console.log('mousemove')
          //   // 获取多边形的顶点数组
          //   var points = e.target.points
          //   // 获取多边形的中心点坐标
          //   var centerX = e.target.left + e.target.width / 2
          //   var centerY = e.target.top + e.target.height / 2
          //   // 遍历顶点数组，更新各顶点的坐标
          //   points.forEach(function (point, index) {
          //     var deltaX = point.x - centerX
          //     var deltaY = point.y - centerY
          //     // 根据多边形的移动距离，更新顶点的坐标
          //     point.x = event.target.left + e.target.width / 2 + deltaX
          //     point.y = event.target.top + e.target.height / 2 + deltaY
          //   })
          //   // 更新多边形的顶点数组
          //   e.target.set('points', points)
          //   // 重新渲染画布
          //   this.canvas.renderAll()
          // })
        } else if (this.drawType == 'polygon') {
          this.generatePolygon()
        }
      }
    },

    //鼠标移动过程中已经完成了绘制
    mousemove(e) {
      if (e.target?.editable) {
        return
      }
      // 当前移动的元素
      let target = e.target
      // 如果存在自定义属性和指定值就执行以下代码（本例主要筛选出刚刚创建的几个圆形）
      if (this.rightKey == 3) {
        if ('cid' in target && target?.cid.match(RegExp(/circle-/))) {
          let index = target.cid.split('-')[1] // 通过cid可以判断出当前操作的是哪个圆形，并且圆形的cid最后一位对应多边形指定的顶点
          let points = this.moveTargetPolygon.points // 获取多边形定点
          points[index].x = target.left // 修改指定顶点的x坐标
          points[index].y = target.top // 修改指定定点的y坐标
          // e.target.editable = true
          // 处于编辑模式
          this.moveTargetPolygon.editable = true
          console.log(points, target.left, this.moveTargetPolygon.editable)
          // 更新多边形的位置
          // this.moveTargetPolygon.left = target.left
          // this.moveTargetPolygon.top = target.top
          // this.moveTargetPolygon.points = points

          // 移除旧的多边形对象
          this.canvas.remove(this.moveTargetPolygon)
          this.canvas.renderAll() // 刷新画布
          if (!this.canvas.contains(this.moveTargetPolygon)) {
            // eslint-disable-next-line no-undef
            let newPolygon = new fabric.Polygon(points, {
              stroke: this.color,
              strokeWidth: this.drawWidth,
              fill: 'rgba(255, 255, 255, 0)',
              opacity: 1,
              hasBorders: true,
              hasControls: false,
            })
            newPolygon.editable = true
            // 将新的多边形对象添加到画布
            this.canvas.add(newPolygon)

            // 更新多边形对象的引用
            this.moveTargetPolygon = newPolygon
            // this.moveTargetPolygon.points = points
            this.canvas.renderAll() // 刷新画布
          }
          return
        }
      }
      if (this.moveCount % 2 && !this.doDrawing) {
        //减少绘制频率
        return
      }
      this.moveCount++
      var xy = e.pointer || this.transformMouse(e.e.offsetX, e.e.offsetY)
      this.mouseTo.x = xy.x
      this.mouseTo.y = xy.y
      // 多边形与文字框特殊处理
      if (
        this.drawType != 'text' ||
        this.drawType != 'fullEllipse' ||
        this.drawType != 'polygon'
      ) {
        this.drawing(e)
      }
      if (this.drawType == 'polygon') {
        if (this.activeLine && this.activeLine.class == 'line') {
          var pointer = this.canvas.getPointer(e.e)
          this.activeLine.set({ x2: pointer.x, y2: pointer.y })
          var points = this.activeShape.get('points')
          points[this.pointArray.length] = {
            x: pointer.x,
            y: pointer.y,
            zIndex: 1,
          }
          this.activeShape.set({
            points: points,
          })
          this.canvas.renderAll()
        }
        this.canvas.renderAll()
      }
    },
    deleteObj() {
      this.canvas.getActiveObjects().map((item) => {
        this.canvas.remove(item)
      })
    },
    transformMouse(mouseX, mouseY) {
      return { x: mouseX / 1, y: mouseY / 1 }
    },
    // 绘制多边形开始，绘制多边形和其他图形不一样，需要单独处理
    drawPolygon() {
      this.drawType = 'polygon'
      this.polygonMode = true
      //这里画的多边形，由顶点与线组成
      this.pointArray = new Array() // 顶点集合
      this.lineArray = new Array() //线集合
      this.canvas.isDrawingMode = false
    },
    addPoint(e) {
      var random = Math.floor(Math.random() * 10000)
      var id = new Date().getTime() + random
      // eslint-disable-next-line no-undef
      var circle = new fabric.Circle({
        radius: 5,
        fill: '#ffffff',
        stroke: '#333333',
        strokeWidth: 0.5,
        left: (e.pointer.x || e.e.layerX) / this.canvas.getZoom(),
        top: (e.pointer.y || e.e.layerY) / this.canvas.getZoom(),
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        id: id,
        objectCaching: false,
      })
      if (this.pointArray.length == 0) {
        circle.set({
          fill: 'red',
        })
      }
      var points = [
        (e.pointer.x || e.e.layerX) / this.canvas.getZoom(),
        (e.pointer.y || e.e.layerY) / this.canvas.getZoom(),
        (e.pointer.x || e.e.layerX) / this.canvas.getZoom(),
        (e.pointer.y || e.e.layerY) / this.canvas.getZoom(),
      ]
      // eslint-disable-next-line no-undef
      this.line = new fabric.Line(points, {
        strokeWidth: 2,
        fill: '#999999',
        stroke: '#999999',
        class: 'line',
        originX: 'center',
        originY: 'center',
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,

        objectCaching: false,
      })
      if (this.activeShape) {
        var pos = this.canvas.getPointer(e.e)
        // eslint-disable-next-line no-redeclare
        var points = this.activeShape.get('points')
        points.push({
          x: pos.x,
          y: pos.y,
        })
        // eslint-disable-next-line no-undef
        var polygon = new fabric.Polygon(points, {
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,

          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false,
        })
        this.canvas.remove(this.activeShape)
        this.canvas.add(polygon)
        this.activeShape = polygon
        this.canvas.renderAll()
      } else {
        var polyPoint = [
          {
            x: (e.pointer.x || e.e.layerX) / this.canvas.getZoom(),
            y: (e.pointer.y || e.e.layerY) / this.canvas.getZoom(),
          },
        ]
        // eslint-disable-next-line no-undef, no-redeclare
        var polygon = new fabric.Polygon(polyPoint, {
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,

          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false,
        })
        this.activeShape = polygon
        this.canvas.add(polygon)
      }
      this.activeLine = this.line

      this.pointArray.push(circle)
      this.lineArray.push(this.line)
      this.canvas.add(this.line)
      this.canvas.add(circle)
    },
    generatePolygon() {
      var points = new Array()
      this.pointArray.map((point, index) => {
        points.push({
          x: point.left,
          y: point.top,
        })
        this.canvas.remove(point)
      })
      this.lineArray.map((line, index) => {
        this.canvas.remove(line)
      })
      this.canvas.remove(this.activeShape).remove(this.activeLine)
      // eslint-disable-next-line no-undef, no-redeclare
      var polygon = new fabric.Polygon(points, {
        stroke: this.color,
        strokeWidth: this.drawWidth,
        fill: 'rgba(255, 255, 255, 0)',
        opacity: 1,
        hasBorders: true,
        hasControls: false,
      })
      this.canvas.add(polygon)

      this.activeLine = null
      this.activeShape = null
      this.polygonMode = false
      this.doDrawing = false
      this.drawType = null
    },
    drawing(e) {
      if (this.drawingObject) {
        this.canvas.remove(this.drawingObject)
      }
      var canvasObject = null
      var left = this.mouseFrom.x,
        top = this.mouseFrom.y,
        mouseFrom = this.mouseFrom,
        mouseTo = this.mouseTo
      switch (this.drawType) {
        case 'line': //直线
          var x1 = mouseFrom.x,
            x2 = mouseTo.x,
            y1 = mouseFrom.y,
            y2 = mouseTo.y
          // eslint-disable-next-line no-undef, no-redeclare
          canvasObject = new fabric.Line([x1, y1, x2, y2], {
            stroke: this.color,
            fill: this.fillColor,
            strokeWidth: this.drawWidth,
          })
          break
        case 'arrow': //箭头
          // eslint-disable-next-line no-redeclare
          var x1 = mouseFrom.x,
            // eslint-disable-next-line no-redeclare
            x2 = mouseTo.x,
            // eslint-disable-next-line no-redeclare
            y1 = mouseFrom.y,
            // eslint-disable-next-line no-redeclare
            y2 = mouseTo.y
          var w = x2 - x1,
            h = y2 - y1,
            sh = Math.cos(Math.PI / 4) * 16
          var sin = h / Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2))
          var cos = w / Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2))
          var w1 = (16 * sin) / 4,
            h1 = (16 * cos) / 4,
            centerx = sh * cos,
            centery = sh * sin
          /**
           * centerx,centery 表示起始点，终点连线与箭头尖端等边三角形交点相对x，y
           * w1 ，h1用于确定四个点
           */

          var path = ' M ' + x1 + ' ' + y1
          path += ' L ' + (x2 - centerx + w1) + ' ' + (y2 - centery - h1)
          path +=
            ' L ' + (x2 - centerx + w1 * 2) + ' ' + (y2 - centery - h1 * 2)
          path += ' L ' + x2 + ' ' + y2
          path +=
            ' L ' + (x2 - centerx - w1 * 2) + ' ' + (y2 - centery + h1 * 2)
          path += ' L ' + (x2 - centerx - w1) + ' ' + (y2 - centery + h1)
          path += ' Z'
          // eslint-disable-next-line no-undef, no-redeclare
          canvasObject = new fabric.Path(path, {
            stroke: this.color,
            fill: this.color,
            strokeWidth: this.drawWidth,
          })
          break
        case 'pentagram': //五角星
          // eslint-disable-next-line no-redeclare
          var x1 = mouseFrom.x,
            // eslint-disable-next-line no-redeclare
            x2 = mouseTo.x,
            // eslint-disable-next-line no-redeclare
            y1 = mouseFrom.y,
            // eslint-disable-next-line no-redeclare
            y2 = mouseTo.y
          /**
           * 实现思路  (x1,y1)表示鼠标起始的位置 (x2,y2)表示鼠标抬起的位置
           * r 表示五边形外圈圆的半径，这里建议自己画个图理解
           * 正五边形夹角为36度。计算出cos18°，sin18°备用
           */
          // eslint-disable-next-line no-redeclare
          var w = Math.abs(x2 - x1),
            // eslint-disable-next-line no-redeclare
            h = Math.abs(y2 - y1),
            r = Math.sqrt(w * w + h * h)
          var cos18 = Math.cos((18 * Math.PI) / 180)
          var sin18 = Math.sin((18 * Math.PI) / 180)

          /**
           * 算出对应五个点的坐标转化为路径
           */
          var point1 = [x1, y1 + r]
          var point2 = [x1 + 2 * r * sin18, y1 + r - 2 * r * cos18]
          var point3 = [x1 - r * cos18, y1 + r * sin18]
          var point4 = [x1 + r * cos18, y1 + r * sin18]
          var point5 = [x1 - 2 * r * sin18, y1 + r - 2 * r * cos18]
          // eslint-disable-next-line no-redeclare
          var path = ' M ' + point1[0] + ' ' + point1[1]
          path += ' L ' + point2[0] + ' ' + point2[1]
          path += ' L ' + point3[0] + ' ' + point3[1]
          path += ' L ' + point4[0] + ' ' + point4[1]
          path += ' L ' + point5[0] + ' ' + point5[1]
          path += ' Z'
          // eslint-disable-next-line no-undef, no-redeclare
          canvasObject = new fabric.Path(path, {
            stroke: this.color,
            fill: this.color,
            strokeWidth: this.drawWidth,
            // angle:180,  //设置旋转角度
          })
          break

        case 'ellipse': //椭圆
          // 按shift时画正圆，只有在鼠标移动时才执行这个，所以按了shift但是没有拖动鼠标将不会画圆
          if (e.e.shiftKey) {
            mouseTo.x - left > mouseTo.y - top
              ? (mouseTo.y = top + mouseTo.x - left)
              : (mouseTo.x = left + mouseTo.y - top)
          }
          var radius =
            Math.sqrt(
              (mouseTo.x - left) * (mouseTo.x - left) +
                (mouseTo.y - top) * (mouseTo.y - top)
            ) / 2
          // eslint-disable-next-line no-undef, no-redeclare
          canvasObject = new fabric.Ellipse({
            left: (mouseTo.x - left) / 2 + left,
            top: (mouseTo.y - top) / 2 + top,
            stroke: this.color,
            fill: 'rgba(255, 255, 255, 0)',
            originX: 'center',
            originY: 'center',
            rx: Math.abs(left - mouseTo.x) / 2,
            ry: Math.abs(top - mouseTo.y) / 2,
            strokeWidth: this.drawWidth,
          })
          break
        case 'rectangle': //长方形
          // 按shift时画正方型
          if (e.e.shiftKey) {
            mouseTo.x - left > mouseTo.y - top
              ? (mouseTo.y = top + mouseTo.x - left)
              : (mouseTo.x = left + mouseTo.y - top)
          }
          // eslint-disable-next-line no-redeclare
          var path =
            'M ' +
            mouseFrom.x +
            ' ' +
            mouseFrom.y +
            ' L ' +
            mouseTo.x +
            ' ' +
            mouseFrom.y +
            ' L ' +
            mouseTo.x +
            ' ' +
            mouseTo.y +
            ' L ' +
            mouseFrom.x +
            ' ' +
            mouseTo.y +
            ' L ' +
            mouseFrom.x +
            ' ' +
            mouseFrom.y +
            ' z'
          // eslint-disable-next-line no-undef, no-redeclare
          canvasObject = new fabric.Path(path, {
            left: left,
            top: top,
            stroke: this.color,
            strokeWidth: this.drawWidth,
            fill: 'rgba(255, 255, 255, 0)',
            hasControls: false,
          })
          //也可以使用fabric.Rect
          break
        case 'text': //文本框
          // eslint-disable-next-line no-undef, no-redeclare
          this.textbox = new fabric.Textbox('', {
            left: mouseFrom.x,
            top: mouseFrom.y - 10,
            // width: 150,
            fontSize: 16,
            borderColor: this.color,
            fill: this.color,
            hasControls: false,
          })
          this.canvas.add(this.textbox)
          this.textbox.enterEditing()
          this.textbox.hiddenTextarea.focus()
          break

        default:
          break
      }

      if (canvasObject) {
        // canvasObject.index = getCanvasObjectIndex();\
        this.canvas.add(canvasObject) //.setActiveObject(canvasObject)
        this.drawingObject = canvasObject
      }
    },
    // 监听双击事件
    mouseDblclick(options) {
      console.log(options)
      // 检查双击的目标对象
      if (options.target) {
        // 在控制台输出双击的对象
        console.log('双击了对象:', options.target)
        // 在这里执行双击事件的逻辑操作
        this.handlefillColor()
        // ...
      }
    },
    // 上一步方法
    undo() {
      if (this.currentStep > 0) {
        // 更新画布为上一步状态
        this.currentStep--
        this.canvas.loadFromJSON(this.canvasHistory[this.currentStep], () => {
          this.canvas.renderAll()
        })
      }
    },
    // 下一步方法
    redo() {
      if (this.currentStep < this.canvasHistory.length - 1) {
        // 更新画布为下一步状态
        this.currentStep++
        this.canvas.loadFromJSON(this.canvasHistory[this.currentStep], () => {
          this.canvas.renderAll()
        })
      }
    },
    // 删除方法
    handleDelect() {
      // 获取当前选中的多个对象的数组
      const activeObjects = this.canvas.getActiveObjects()
      // 检查是否有选中的对象
      if (activeObjects.length > 0) {
        // 循环遍历选中的对象数组
        activeObjects.forEach((object) => {
          // 从画布中移除当前对象
          this.canvas.remove(object)
        })
        // 清除选中状态
        this.canvas.discardActiveObject()
        // 清除选择框
        this.canvas.renderAll()
      } else {
        console.log('请先选中图形')
        alert('请先选中图形')
      }
      this.added()
    },
    // 填充颜色
    handlefillColor() {
      // 获取当前选中的多个对象的数组
      const activeObjects = this.canvas.getActiveObjects()
      // 检查是否有选中的对象
      if (activeObjects.length > 0) {
        // 循环遍历选中的对象数组
        activeObjects.forEach((object) => {
          // 设置填充颜色为红色
          object.set('fill', this.fillColor)
        })
        // 重新渲染画布
        this.canvas.renderAll()
      } else {
        console.log('请先选中图形')
        alert('请先选中图形')
      }
      this.added()
    },
    added() {
      // 获取当前画布状态
      const currentState = this.canvas.toObject(['id', 'selectable', 'evented'])
      // 截断当前索引之后的未完成步骤
      this.canvasHistory = this.canvasHistory.slice(0, this.currentStep + 1)
      // 将当前状态添加到步骤状态数组中
      this.canvasHistory.push(currentState)
      // 更新当前步骤索引
      this.currentStep++
    },
    // 多边形被选中时触发
    movePolygon(event) {
      // // 当前移动的元素
      // let target = event.target
      // // 如果存在自定义属性和指定值就执行以下代码（本例主要筛选出刚刚创建的几个圆形）
      // if ('cid' in target && target.cid.match(RegExp(/circle-/))) {
      //     let index = target.cid.split('-')[1] // 通过cid可以判断出当前操作的是哪个圆形，并且圆形的cid最后一位对应多边形指定的顶点
      //     let points = polygon.points // 获取多边形定点
      //     points[index].x = target.left // 修改指定顶点的x坐标
      //     points[index].y = target.top // 修改指定定点的y坐标
      //     canvas.renderAll() // 刷新画布
    },
    renderDot(x, y, id, type) {
      let canvasObject = null
      console.log(123, type)
      // eslint-disable-next-line no-undef, no-redeclare
      canvasObject = new fabric.Circle({
        left: x,
        top: y,
        radius: 15,
        fill: type,
        stroke: type,
        strokeWidth: 0.5,
        originX: 'center',
        originY: 'center',
        rx: Math.abs(x - this.mouseTo.x) / 2,
        ry: Math.abs(y - this.mouseTo.y) / 2,
        hasControls: false,
        lockMovementY: true,
        lockMovementX: true,
        selfType: 'fullEllipse', //传一个自定义属性
        equipment_id: id,
        // strokeWidth: this.drawWidth,
        // radius: 20,
        // fill: '#19EE21',
        // stroke: '#19EE21',
        // strokeWidth: 0.5,
      })
      console.log('执行了吗', canvasObject)
      if (canvasObject) {
        // canvasObject.index = getCanvasObjectIndex();\
        this.canvas.add(canvasObject) //.setActiveObject(canvasObject)
        this.drawingObject = canvasObject
        // this.$emit('emitPosition', {
        //   x: x,
        //   y: top,
        //   color: this.fillColor,
        //   r: 15,
        // })
        console.log('执行了吗')
      }
    },
    changefullEllipse() {
      var canvasObject = null
      var left = this.mouseFrom.x,
        top = this.mouseFrom.y,
        mouseFrom = this.mouseFrom,
        mouseTo = this.mouseTo
      if (this.fullType == 'dot') {
        // 打点
        // eslint-disable-next-line no-undef, no-redeclare
        canvasObject = new fabric.Circle({
          left: left,
          top: top,
          radius: 15,
          fill: this.fillColor,
          stroke: this.fillColor,
          strokeWidth: 0.5,
          originX: 'center',
          originY: 'center',
          rx: Math.abs(left - mouseTo.x) / 2,
          ry: Math.abs(top - mouseTo.y) / 2,
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          selfType: 'fullEllipse', //传一个自定义属性
          equipment_id: this.equipment_id,
          // strokeWidth: this.drawWidth,
          // radius: 20,
          // fill: '#19EE21',
          // stroke: '#19EE21',
          // strokeWidth: 0.5,
        })
        if (canvasObject) {
          // canvasObject.index = getCanvasObjectIndex();\
          this.canvas.add(canvasObject) //.setActiveObject(canvasObject)
          this.drawingObject = canvasObject
          this.$emit('emitPosition', {
            x: left / this.scaleObj.width,
            y: top / this.scaleObj.height,
            color: this.fillColor,
            r: 15,
          })
        }
      } else {
        this.$emit('handleFullDrewer', true)
      }
    },
    resizeFn() {
      const canvas = document.getElementById('canvas')
      if (window.innerWidth + 240 < this.width >= 1707 ? 2400 : 1707) {
        this.$nextTick(() => {
          console.log(333, window.innerWidth + 240, canvas.width)
          if ((1 / canvas.width) * (window.innerWidth - 300) > 1) {
            canvas.style.transform = `scale(1)`
          } else {
            canvas.style.transform = `scale(${
              (1 / canvas.width) * (window.innerWidth - 300)
            })`
          }
        })
      } else {
        this.$nextTick(() => {
          console.log(444, window.innerWidth + 240, canvas.width)
          canvas.style.transform = `scale(${1})`
        })
      }
    },
    // 打点
    // eslint-disable-next-line no-undef, no-redeclare
  },
  mounted() {
    // let innerWidth = window.innerWidth
    // if (this.showAction) {
    //   this.width = this.width - 100
    //   this.scaleX = (this.width - 100) / this.width
    // }
    window.addEventListener('resize', () => this.resizeFn())
    // eslint-disable-next-line no-undef, no-redeclare
    this.canvas = new fabric.Canvas('canvas', {
      fireRightClick: true, // <-- enable firing of right click events
      fireMiddleClick: true, // <-- enable firing of middle click events
      stopContextMenu: true, // <--  prevent context menu from showing
      // skipTargetFind: false, //当为真时，跳过目标检测。目标检测将返回始终未定义。点击选择将无效
      // selectable: false,  //为false时，不能选择对象进行修改
      // selection: false   // 是否可以多个对象为一组
    })
    this.canvas.defaultCursor = 'crosshair' //默认光标改成十字
    this.canvas.hoverCursor = 'crosshair' //悬浮光标改成手型

    this.canvas.selectionColor = 'rgba(0,0,0,0.05)'
    this.canvas.on('mouse:down', this.mousedown)
    this.canvas.on('mouse:move', this.mousemove)
    this.canvas.on('mouse:up', this.mouseup)
    // 监听双击事件
    this.canvas.on('mouse:dblclick', this.mouseDblclick)
    // 创建画布对象时保存当前画布状态
    // this.canvas.off('object:added')
    // this.canvas.on('object:added', this.added)
    // 当多边形被选中时触发
    // this.canvas.on('mouse:move', this.movePolygon)
    // this.canvas.on('object:selected', function (options) {
    //   console.log('执行了吗', options)
    //   const selectedObject = options.target
    //   // 设置填充颜色为蓝色
    //   selectedObject.fill = 'blue'
    //   // 更新画布
    //   canvas.renderAll()
    // })
    document.onkeydown = (e) => {
      // 键盘 delect删除所选元素
      if (e.keyCode == 46) {
        this.deleteObj()
      }
      // ctrl+z 删除最近添加的元素
      if (e.keyCode == 90 && e.ctrlKey) {
        this.canvas.remove(
          this.canvas.getObjects()[this.canvas.getObjects().length - 1]
        )
      }
    }
    let timer = setTimeout(() => {
      this.loadExpImg()
      clearTimeout(timer)
      if (this.dotData.length > 0) {
        console.log(this.dotData)
        // 渲染点位
        this.dotData.forEach((item) => {
          if (item.main_status == 1) {
            item.wran = '#19EE21'
          } else if (item.main_status == 2) {
            item.wran = '#F00202'
          } else if (item.main_status == 3) {
            item.wran = 'gray'
          }
          console.log(this.scaleX)
          this.renderDot(
            Number(item.coordinate_x) * this.scaleX * this.scaleObj.width,
            Number(item.coordinate_y) * this.scaleObj.height,
            item.equipment_id,
            item.wran
          )
        })
      }
    }, 500)
  },
  beforeDestory() {
    window.removeEventListener('resize', () => this.resizeFn())
  },
}
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
}
img,
input {
  display: none;
}
.demo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
canvas {
  border: 1px dashed black;
}
.draw-btn-group {
  // width: 1270px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .color_input {
    height: 30px;
    width: 30px;
    position: relative;
    input {
      display: inline-block;
      height: 30px;
      width: 30px;
      border: none;
    }
    .color_full {
      width: 14px;
      height: 12px;
      position: absolute;
      top: 9px;
      left: 8px;
      background: #fff;
    }
  }
  & > div {
    background: #fafafa;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
    i {
      display: flex;
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: 50% 50%;
      height: 30px;
      width: 30px;
    }
    .icon-1 {
      background-image: url('../../assets/icons/draw/1.png');
    }
    .icon-pentagram {
      background-image: url('../../assets/icons/draw/pentagram.png');
    }
    .icon-2 {
      background-image: url('../../assets/icons/draw/2.png');
    }
    .icon-3 {
      background-image: url('../../assets/icons/draw/3.png');
    }
    .icon-4 {
      background-image: url('../../assets/icons/draw/4.png');
      background-size: 75%;
    }
    .icon-5 {
      background-image: url('../../assets/icons/draw/5.png');
      background-size: 70%;
    }
    .icon-6 {
      background-image: url('../../assets/icons/draw/6.png');
    }
    .icon-7 {
      background-image: url('../../assets/icons/draw/7.png');
      background-size: 80%;
    }
    .icon-del {
      background-image: url('../../assets/icons/draw/del.png');
      background-size: 90%;
    }
    .icon-color {
      background-image: url('../../assets/icons/draw/yanse.png');
      background-size: 90%;
    }
    .icon-img {
      background-image: url('../../assets/icons/draw/img.png');
      background-size: 80%;
    }
    .icon-back {
      background-image: url('../../assets/icons/draw/back.png');
      background-size: 75%;
    }
    .icon-save {
      background-image: url('../../assets/icons/draw/save.png');
      background-size: 80%;
    }
    .icon-mouse {
      background-image: url('../../assets/icons/draw/mouse.png');
      background-size: 60%;
    }
    .shang-icon {
      background-image: url('../../assets/icons/draw/8.png');
      background-size: 80%;
    }
    .next-icon {
      background-image: url('../../assets/icons/draw/9.png');
      background-size: 80%;
    }
  }
  .active {
    background: #eee;
  }
}
</style>
