<template>
	<div class="app-box">
		<div class="title-box">
			<div class="back" @click="back">
				<a-icon type="left" />
			</div>
			<div class="line"></div>
			<p class="title">设备编辑</p>
		</div>
		<a-divider style="margin-left: 10px" />
		<a-form-model class="form-edit" :model="form" ref="form" :rules="rules" :labelCol="{ span: 2, offset: 2 }"
			:wrapperCol="{ span: 15 }" style="width: 1000px">
			<a-form-model-item label="企业名称:">
				<label>{{ customer.company_name }}</label>
				<label> 经办人：</label>
				<label>{{ customer.manager }}</label>
				<label> 地区:</label>
				<label>{{ customer.pro }}-{{ customer.city }}-{{ customer.area }}</label>
			</a-form-model-item>
			<a-form-model-item label="设备主ID" prop="main_id">
				<div class="row-two-item">
					<a-input-number class="item" v-model="form.main_id" placeholder="请输入ID‘仅数字’" disabled="disabled" />
					<div class="line-two"></div>
					<a-input class="item" v-model="form.main_sim" placeholder="请输入设备id" />
				</div>
			</a-form-model-item>
			<a-form-model-item label="从ID-1" prop="one_id">
				<div class="row-two-item">
					<a-input-number class="item" v-model="form.one_id" placeholder="请输入ID‘仅数字’" disabled="disabled" />
					<div class="line-two"></div>
					<a-input class="item" v-model="form.one_sim" placeholder="请输入设备id" />
				</div>
			</a-form-model-item>
			<a-form-model-item label="从ID-2" prop="two_id">
				<div class="row-two-item">
					<a-input-number class="item" v-model="form.two_id" placeholder="请输入ID‘仅数字’" disabled="disabled" />
					<div class="line-two"></div>
					<a-input class="item" v-model="form.two_sim" placeholder="请输入设备id" />
				</div>
			</a-form-model-item>
			<a-form-model-item label="生产日期">
				<a-date-picker v-model="form.birth_at_str" valueFormat="YYYY-MM-DD" placeholder="选择日期" />
			</a-form-model-item>
			<a-form-model-item label="出厂编号" prop="factory_no">
				<a-input v-model="form.factory_no" placeholder="字母加数字7位" />
			</a-form-model-item>
			<a-form-model-item label="检验员号">
				<a-input v-model="form.inspector_no" placeholder="请输入数字" />
			</a-form-model-item>
			<a-form-model-item label="对比值G" prop="contrast_g">
				<a-input-number v-model="form.contrast_g" placeholder="1-8位数字" />
			</a-form-model-item>
			<a-form-model-item label="对比值H" prop="contrast_h">
				<a-input-number v-model="form.contrast_h" placeholder="1-8位数字" />
			</a-form-model-item>
			<a-form-model-item label="产品资质" prop="certificate" :wrapperCol="{ span: 19 }">
				<div class="row-two-item">
					<div class="upload-card" v-for="item in fileList" :key="item.key">
						<a-upload list-type="picture-card" :show-upload-list="false"
							:action="$domain + '/admin/v1/index/imgUpload'" :maxCount="1" @change="item.event">
							<img class="img-upload" v-if="form[item.key]" :src="form[item.key]" />
							<div v-else>
								<a-icon type="plus" />
								<div class="ant-upload-text">
									上传
								</div>
							</div>
						</a-upload>
						<div class="upload-text">{{ item.name }}</div>
					</div>
				</div>
			</a-form-model-item>
			<a-form-model-item label="备注">
				<a-textarea v-model="form.remark" :rows="5" />
			</a-form-model-item>
			<a-form-model-item label=" " :colon="false">
				<a-button @click="back">取消</a-button>
				<a-button type="primary" style="margin-left: 12px" @click="submitForm">
					提交
				</a-button>
			</a-form-model-item>
		</a-form-model>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			form: {},
			customer: {},
			fileList: [{
				name: '产品合格证',
				key: 'certificate',
				event: this.changeCertificate
			}, {
				name: '产品检验报告',
				key: 'inspection_report',
				event: this.changeInspection
			}, {
				name: '产品标定证',
				key: 'calibration',
				event: this.changeCalibration
			}, {
				name: '产品产权归属',
				key: 'property_right',
				event: this.changeProperty
			}, {
				name: '产品历史记事',
				key: 'historical_note',
				event: this.changeHistorical
			}],
			rules: {
				main_sim: [{
					required: true,
					message: '请输入主设备id',
					trigger: 'change'
				},
				{
					validator: (rule, value, callback) => this.validator('main_sim', callback)
				}
				],
				one_sim: [{
					required: true,
					message: '请输入从1设备id',
					trigger: 'change'
				},
				{
					validator: (rule, value, callback) => this.validator('one_sim', callback)
				}
				],
				// two_sim: [{
				// 		required: true,
				// 		message: '请输入从2设备id',
				// 		trigger: 'change'
				// 	},
				// 	{
				// 		validator: (rule, value, callback) => this.validator('two_sim', callback)
				// 	}
				// ],
				factory_no: [{
					required: true,
					message: '请输入出厂编号',
					trigger: 'change'
				},
				{
					min: 7,
					max: 7,
					message: '出产编号为7位',
					trigger: 'blur'
				}
				],
				contrast_h: [{
					required: true,
					message: '请输入对比值H',
					trigger: 'change'
				}],
				contrast_g: [{
					required: true,
					message: '请输入对比值G',
					trigger: 'change'
				}],
				certificate: [{
					required: true,
					message: '请上传产品合格证'
				},
				{
					validator: (rule, value, callback) => {
						if (!this.form.inspection_report) {
							callback('请上传产品检验报告')
						}
						if (!this.form.calibration) {
							callback('请上传产品标定证')
						}
						if (!this.form.property_right) {
							callback('请上传产品产权归属')
						}
						if (!this.form.historical_note) {
							callback('请上传产品历史记事')
						}
						callback()
					}
				}
				]
			}
		}
	},
	created() {
		let eid = this.$route.query.eid
		let cid = this.$route.query.cid
		if (eid == null || cid == null) {
			this.$message.error('页面异常')
			this.$router.back()
		}
		this.$post(domain + '/admin/v1/equipment/customer/info', {
			customer_id: cid
		}).then(res => {
			if (res.code === 0) {
				this.customer = res.data
			} else {
				this.$message.error(res.msg)
			}
		})
		this.$post(domain + '/admin/v1/equipment/equipment/info', {
			equipment_id: eid
		}).then(res => {
			if (res.code === 0) {
				this.form = res.data
			} else {
				this.$message.error(res.msg)
			}
		})


	},
	methods: {
		validator(key, callback) {
			if (this.form[key] && this.form[key].length > 0) {
				callback()
			} else {
				callback(new Error('请输入物联网号'))
			}
		},
		submitForm() {
			console.log(this.form)
			let type
			this.$refs.form.validate(valid => {
				if (valid) {
					if (this.$route.query.eid > -1) {
						type = 'edit'
					} else {
						type = 'add'
					}
					this.$post(domain + '/admin/v1/equipment/equipment/' + type, this.form).then(res => {
						if (res.code === 0) {
							this.$message.success('保存成功')
							this.$router.back()
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			})
		},
		back() {
			this.$router.back()
		},
		changeCertificate(res) {
			this.changeFile(res, 'certificate')
		},
		changeInspection(res) {
			this.changeFile(res, 'inspection_report')
		},
		changeCalibration(res) {
			this.changeFile(res, 'calibration')
		},
		changeProperty(res) {
			this.changeFile(res, 'property_right')
		},
		changeHistorical(res) {
			this.changeFile(res, 'historical_note')
		},
		changeFile(res, key) {
			if (res.file.status == "done") {
				const response = res.file.response
				if (response && response.code === 0) {
					this.form[key] = response.data.really_path;
					this.form = {
						...this.form
					}
					this.$nextTick(() => {
						this.$refs.form.validateField('certificate')
					})
				} else {
					this.$message.error(response.msg)
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.row-two-item {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.item {
		width: 277px;
	}

	.line-two {
		height: 1px;
		background-color: #979797;
		width: 38px;
	}
}

.upload-card {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.img-upload {
	width: 86px;
	height: 86px;
}
</style>
<style lang="less">
.form-edit {
	.upload-card {
		.ant-upload.ant-upload-select-picture-card {
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}
</style>
