<template>
  <a-config-provider id="app" :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN
    }
  },
  components: {
  }
}
</script>

<style lang="less">
@import "@/static/css/style.less";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

#editor1,
#editor2 {
  height: 500px;
  border: 1px solid var(--ck-color-toolbar-border);
}
</style>
