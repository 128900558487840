<template>
  <div class="app-box">
    <div class="title-box">
      <div class="back" @click="back">
        <a-icon type="left" />
      </div>
      <div class="line"></div>
      <p class="title">公司介绍</p>
    </div>
    <a-divider style="margin-left: -10px" />
    
    <a-form-model
      class="form-edit"
      :model="form"
      ref="form"
      :rules="rules"
      :labelCol="{ span: 2, offset: 2 }"
      :wrapperCol="{ span: 15 }"
      style="width: 1000px"
    >
      <a-form-model-item label="展示图" prop="img">
        <div class="row-two-item">
          <div class="upload-card">
            <a-upload
              list-type="picture-card"
              :show-upload-list="false"
              :action="$domain + '/admin/v1/index/imgUpload'"
              :maxCount="1"
              @change="changeFile"
            >
              <img class="img-upload" v-if="form.img" :src="form.img" />
              <div v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
          </div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="内容" :wrapperCol="{ span: 19 }">
        <!-- <a-textarea v-model="form.remark" :rows="5" /> -->
        <div id="editor-toolbar-container"></div>
        <div id="editor"></div>
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false">
        <a-button>取消</a-button>
        <a-button type="primary" style="margin-left: 12px" @click="submitForm">
          确认保存
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import { message } from 'ant-design-vue'
import CKEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import MyUploadAdapter from '@/utils/uploader'
import { toolbar, fontSize } from '@/utils/toolbar'
export default {
  data() {
    return {
      editor: null,
      editorData: '',
      form: {},
      rules: {
        img: [
          { required: true, message: '请上传展示图' }
        ]
      }
    }
  },
  watch: {
    editorData(val) {
      this.form.content = val
    }
  },
  created() {
    this.$post(domain + '/admin/v1/company/usual/info').then(res => {
      if (res.code === 0) {
        this.form = res.data
      } else {
        this.$message.error(res.msg)
      }
    })
  },
  mounted() {
    CKEditor.create(document.querySelector('#editor'), {
      toolbar,
      fontSize,
      language: 'zh-cn'
    }).then(editor => {
      const toolbarContainer = document.querySelector('#editor-toolbar-container')
      toolbarContainer.appendChild(editor.ui.view.toolbar.element)
      this.editor = editor
      this.myUploadImagePlugin()
      setTimeout(() => {
        this.editor.setData(this.form.content)
      }, 400)
    })
  },
  methods: {
    myUploadImagePlugin() {
      this.editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return new MyUploadAdapter(loader);
      }
      this.editor.model.document.on('change:data', () => {
        this.editorData = this.editor.getData()
      })
    },
    submitForm() {
      console.log(this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$post(domain + '/admin/v1/company/usual/edit', {
            content: this.form.content,
            img: this.form.img
          }).then(res => {
            if (res.code === 0) {
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    back() {
      this.$router.back()
    },
    changeFile(res) {
      if (res.file.status == "done") {
        const response = res.file.response
        if (response && response.code === 0) {
          this.form.img = response.data.really_path;
          this.form = {...this.form}
          this.$nextTick(() => {
            this.$refs.form.validateField('img')
          })
        } else {
          message.error(response.msg)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.row-two-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    width: 277px;
  }
  .line-two {
    height: 1px;
    background-color: #979797;
    width: 38px;
  }
}
.upload-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-upload {
  width: 86px;
  height: 86px;
}
#editor {
  height: 560px;
  border: 1px solid #D9D9D9;
}
</style>
<style lang="less">
  .form-edit {
    .upload-card {
      .ant-upload.ant-upload-select-picture-card {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
</style>
