<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">地图编辑</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <div class="content_box">
      <a-row type="flex">
        <a-col :flex="4" class="col_left">
          <div class="title">
            <span><strong>地图名称：</strong>{{ data.name }}</span>
            <span><strong>项目名称：</strong>{{ itemData.name }}</span>
            <span><strong>工地名称：</strong>{{ itemData.company_name }}</span>
            <span><strong>区域名称：</strong>{{ itemData.gov_name }}</span>

            <!-- <span>{{ itemData.company_name }}</span
            ><br />
            <span>{{ itemData.gov_name }} ——{{ itemData.name }} </span> -->
          </div>
        </a-col>
        <a-col>
          <a-tooltip placement="topLeft">
            <template slot="title">
              <span>重新上传地图将清空原地图所有已打点设备，请谨慎操作！</span>
            </template>
            <a-icon
              type="info-circle"
              theme="filled"
              style="
                margin-right: 10px;
                font-size: 26px;
                color: rgba(0, 0, 0, 0.45);
                vertical-align: middle;
              "
            />
          </a-tooltip>
          <a-upload
            name="img"
            :multiple="false"
            accept=".jpg, .jpeg, .png"
            :action="$domain + '/admin/v1/map/mapUpload'"
            @change="handleChange"
            :data="{ map_id: map_id }"
            :headers="{
              authorization: authorization,
            }"
            :show-upload-list="false"
          >
            <a-button type="primary"> 上传地图 </a-button>
          </a-upload>
          <!-- <a-button type="primary" @click="handleChange"> 上传地图 </a-button> -->
        </a-col>
      </a-row>
      <div class="card_table">
        <a-upload-dragger
          name="img"
          :multiple="false"
          accept=".jpg, .jpeg, .png"
          :action="$domain + '/admin/v1/map/mapUpload'"
          @change="handleChange"
          :data="{ map_id: map_id }"
          :headers="{
            authorization: authorization,
          }"
          class="upload_box"
          :show-upload-list="false"
          v-if="!data.map_url"
        >
          <p class="ant-upload-drag-icon">
            <img src="@/assets/image.png" alt="" style="width: 192px" />
          </p>
          <p class="ant-upload-text">请点击上传工地图片</p>
          <p class="ant-upload-text">建议尺寸：1920*1080</p>
          <p class="ant-upload-text">建议大小：不超过5M</p>
        </a-upload-dragger>
        <div v-else>
          <canvasImage
            :url="data.map_url"
            :isFullType="isFullType"
            :equipment_id="equipment_id"
            @emitPosition="getPosition"
            @handleFullDrewer="getFullDrewer"
            @showDotDetails="getDetails"
            :dotData="dotData"
            :delId="delId"
          />
        </div>
      </div>
    </div>
    <a-drawer
      title="打点设置"
      width="600"
      :closable="true"
      :visible="isFullDrewer"
      @close="onDrawerClose"
      class="drewer-box"
    >
      <a-form-model :rules="rules" ref="Form" :model="query" layout="inline">
        <a-form-model-item
          label="所在位置"
          prop="build_name"
          :label-col="{ span: 7, offset: -12 }"
        >
          <a-input v-model="query.build_name" placeholder="请输入所在位置" />
        </a-form-model-item>
        <!-- <a-form-model-item label="设备ID" :label-col="{ span: 4 }">
          <a-input v-model="query.main_id" placeholder="请输入设备ID" />
          <a-button type="primary" @click="handleSearch"> 查询 </a-button>
        </a-form-model-item> -->
      </a-form-model>
      <a-form-model layout="inline">
        <a-form-model-item
          label="出厂编号"
          :label-col="{ span: 7, offset: -12 }"
        >
          <a-input v-model="query.main_id" placeholder="请输入出厂编号" />
          <!-- <a-button type="primary" @click="handleSearch"> 查询 </a-button> -->
        </a-form-model-item>
        <a-form-model-item :label-col="{ span: 4 }">
          <a-button type="primary" @click="handleSearch"> 查询 </a-button>
        </a-form-model-item>
      </a-form-model>
      <p>未打点设备</p>
      <a-table
        :data-source="listData"
        :columns="columns"
        :pagination="{
          current: query1.page,
          total: query1.total,
        }"
        @change="listChange"
      >
        <!-- :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }" -->
        <span slot="action" slot-scope="text, record">
          <a @click="handleFull(record)">选择</a>
        </span>
      </a-table>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <!-- <a-button style="margin-right: 20px" @click="onChildrenDrawerClose">
          取消
        </a-button> -->
        <a-button type="primary" @click="onChildrenDrawerSave"> 保存 </a-button>
      </div>
    </a-drawer>
    <a-drawer
      title="点位信息"
      width="600"
      :closable="true"
      :visible="isDotInfoDrewer"
      @close="onDotInfoClose"
      class="drewer-box"
    >
      <div>
        <p class="right_p">
          <a-button type="primary" @click="delDot"> 删除点位 </a-button>
        </p>
        <p class="center_p">
          <span>所在位置：</span><span>{{ dotInfoDetails.build_name }}</span>
        </p>
        <p class="center_p">
          <span>主设备 ：</span><span>{{ dotInfoDetails.equipment_id }}</span>
        </p>
        <p class="center_p">
          <span>从机1 ：</span><span>{{ dotInfoDetails.one_sim }}</span>
        </p>
        <p class="center_p">
          <span>从机2 ：</span><span>{{ dotInfoDetails.two_sim }}</span>
        </p>
        <p class="center_p">
          <span>经度：</span><span>{{ dotInfoDetails.coordinate_y }}</span>
        </p>
        <p class="center_p">
          <span>纬度：</span><span>{{ dotInfoDetails.coordinate_x }}</span>
        </p>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import canvasImage from '@/components/Fabric/Fabric.vue'
import store from '@/store/store.js'

export default {
  components: {
    canvasImage,
  },
  data() {
    return {
      map_id: '',
      authorization: 'Bearer ' + store.state.token,
      mapList: [],
      itemData: {},
      data: {},
      positionArr: [],
      isFullDrewer: false,
      isFullType: 'close',
      query: {
        build_name: '',
        equipment_id: '',
      },
      query1: {
        page: 1,
        limit: 10,
        total: 0,
      },
      rules: {
        build_name: [
          {
            required: true,
            message: '填写所在位置',
            trigger: 'blur',
          },
        ],
      },
      columns: [
        {
          title: '主设备',
          dataIndex: 'main_sim',
        },
        {
          title: '出厂编号',
          dataIndex: 'factory_no',
        },
        // {
        //   title: '从机2',
        //   dataIndex: 'two_sim',
        // },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      listData: [],
      equipment_id: '',
      build_name: '',
      isDotInfoDrewer: false,
      dotInfoDetails: {},
      dotData: [],
      delId: '',
    }
  },
  created() {
    this.map_id = this.$route.query.id
    this.itemData = JSON.parse(localStorage.getItem('info') || {})
    this.getMapInfo()
  },
  methods: {
    // 得到点位信息
    getDotInfo() {
      this.$post(domain + '/admin/v1/map/dotEquipmentList', {
        map_id: this.map_id,
      }).then((res) => {
        if (res.code === 0) {
          // console.log('dotEquipmentList', res.data)
          this.dotData = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 得到图纸
    getMapInfo() {
      this.$post(domain + '/admin/v1/map/info', {
        map_id: this.map_id,
      }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          if (this.data.map_url) {
            this.getDotInfo()
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        const { code, msg, data } = info.fileList[0].response
        this.data.map_url = data
        if (code == 0) {
          this.$message.success(`${info.file.name} 上传成功`)
        } else {
          this.$message.error(msg)
        }
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
    },
    handleFull(record) {
      if (!this.query.build_name) {
        this.$message.error('请输入所在位置')
        return
      } else {
        this.equipment_id = record.equipment_id
        this.build_name = this.query.build_name
        this.isFullType = 'dot'
        this.isFullDrewer = false
      }
    },
    onChildrenDrawerSave() {
      this.isFullType = 'dot'
      this.isFullDrewer = false
    },
    getPosition(data) {
      // this.positionArr.push(data)
      this.$post(domain + '/admin/v1/map/dot', {
        map_id: this.map_id,
        equipment_id: this.equipment_id,
        coordinate_x: data.x,
        coordinate_y: data.y,
        build_name: this.build_name,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('打点成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 打点详情
    getDetails(id) {
      this.isDotInfoDrewer = true
      this.$post(domain + '/admin/v1/map/dotInfo', {
        equipment_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.dotInfoDetails = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 删除点位
    delDot() {
      this.$confirm({
        title: '确认',
        content: '确认删除该点位吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/map/deleteDot', {
            equipment_id: this.dotInfoDetails.equipment_id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success('删除成功')
              this.delId = this.dotInfoDetails.equipment_id
              // this.getDotInfo()
              this.isDotInfoDrewer = false
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {},
      })
    },
    // 关闭详情
    onDotInfoClose() {
      this.isDotInfoDrewer = false
    },
    listChange(e) {
      this.query1.page = e.current
      // this.getList()
    },
    // 查询
    handleSearch() {
      this.getList()
    },
    // 关闭打点设置
    onDrawerClose() {
      this.isFullDrewer = false
      this.isFullType = 'close'
    },
    getFullDrewer(flag) {
      this.isFullDrewer = flag
      this.isFullType = 'close'

      this.getList()
    },
    getList() {
      this.$post(domain + '/admin/v1/map/unDotEquipmentList', {
        page: this.query1.page,
        limit: this.query1.limit,
        main_id: this.query.main_id,
        map_id: this.map_id,
      }).then((res) => {
        if (res.code === 0) {
          this.listData = res.data.list
          this.query1.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.app-box {
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
}
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}
.content_box {
  display: flex;
  flex: 1;
  flex-direction: column;
  .title {
    font-size: 18px;
    color: #383f50;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .col_left {
    display: flex;
    align-items: center;
    span {
      margin-right: 32px;
    }
  }
  .card_table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 19px;
    flex: 1;
    background: rgba(239, 239, 239, 0.53);
    border: 1px solid rgba(151, 151, 151, 0.61);
    .upload_box {
      width: 50%;
      height: 450px;
      background: rgba(239, 239, 239, 0.53);
      .ant-upload-text {
        font-size: 18px;
        font-weight: 500;
        color: #383f50;
      }
    }
  }
}
.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.upload_box {
  .ant-upload.ant-upload-drag {
    border: 2px dashed rgba(151, 151, 151, 0.61);
    background: rgba(239, 239, 239, 0.53);
  }
}
.drewer-box {
  .ant-form-item-label {
    width: 90px;
  }
  .right_p {
    text-align: right;
  }
  .center_p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.85);
    span {
      width: 50%;
    }
    :first-child {
      text-align: right;
    }
    :last-child {
      color: #343436;
    }
  }
}
</style>
