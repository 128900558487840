<template>
  <div class="app-box">
    <div class="title-box">
      <div class="back" @click="back">
        <a-icon type="left" />
      </div>
      <div class="line"></div>
      <p class="title">账号{{ gov_id == -1 ? '新增' : '编辑' }}</p>
    </div>
    <a-divider style="margin-left: -10px" />

    <a-form-model
      class="form-edit"
      :model="form"
      ref="form"
      :rules="rules"
      :labelCol="{ span: 2, offset: 2 }"
      :wrapperCol="{ span: 15 }"
      style="width: 1000px"
    >
      <a-form-model-item label="区域名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入区域名称" />
      </a-form-model-item>
      <a-form-model-item label="联系人" prop="contact">
        <a-input v-model="form.contact" placeholder="请输入联系人" />
      </a-form-model-item>
      <a-form-model-item label="登录账号" prop="phone">
        <a-input v-model="form.phone" placeholder="请输入登录手机号" />
      </a-form-model-item>
      <a-form-model-item label="密码" prop="password">
        <a-input-password
          v-model="form.password"
          :disabled="gov_id == -1 ? false : true"
          placeholder="请输入密码"
        />
      </a-form-model-item>
      <a-form-model-item label="地区">
        <a-cascader
          :field-names="{
            label: 'name',
            value: 'name',
            children: 'districts',
          }"
          v-model="form.addrValue"
          style="min-width: 300px"
          :load-data="loadSsq"
          change-on-select
          :options="ssqData"
          placeholder="请选择省市区"
          @change="ssqChange"
        />
        <a-input v-model="form.address" placeholder="请输入详细地址" />
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" style="margin-left: 12px" @click="submitForm">
          提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import axios from 'axios'
import { domain } from '@/siteInfo.js'
import { message } from 'ant-design-vue'
import { ssqdata } from '@/utils/ssqdata'

export default {
  data() {
    return {
      gov_id: '',
      form: {
        addrValue: [],
        name: '',
        phone: '',
        password: '',
        contact: '',
        pro: '',
        pro_code: '',
        city: '',
        city_code: '',
        area: '',
        area_code: '',
      },
      ssqData: ssqdata,
      addrCode: [],
      curParent: [],
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入登录手机号',
            trigger: 'change',
          },
        ],
        contact: [
          {
            required: true,
            message: '请输入联系人',
            trigger: 'change',
          },
        ],
      },
    }
  },
  async created() {
    console.log('创建被执行了')
    this.gov_id = this.$route.query.id
    if (this.$route.query.id != -1) {
      this.$post(domain + '/admin/v1/gov/info', {
        gov_id: this.gov_id,
      }).then((res) => {
        if (res.code === 0) {
          this.form = res.data
          this.form.password = '******'
          this.form.addrValue = [this.form.pro, this.form.city, this.form.area]
          this.addrCode = [
            this.form.pro_code,
            this.form.city_code,
            this.form.area_code,
          ]
          this.curParent = ssqdata
          this.initLoad(0)
        } else {
          this.$message.error(res.msg)
        }
      })
    } else {
      console.log('id', this.$route.query.id)
    }
  },
  methods: {
    validator(key, callback) {
      if (this.form[key] && this.form[key].length > 0) {
        callback()
      } else {
        callback(new Error('请输入物联网号'))
      }
    },
    showChange(type) {
      //编辑
      if (this.gov_id > 0 && type != this.form.type) {
        this.$message.error('用户无法编辑类型')
      } else {
        this.form.type = type
      }
    },
    submitForm() {
      let action = ''
      console.log('业务id', this.material_id)
      if (this.gov_id != -1) {
        action = 'update'
        this.form.gov_id = this.gov_id
      } else {
        action = 'add'
      }
      console.log(this.form)
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$post(domain + '/admin/v1/gov/' + action, this.form).then(
            (res) => {
              if (res.code === 0) {
                this.$message.success('操作成功', 1.5)
                this.$router.back()
              } else {
                this.$message.error(res.msg, 1.5)
              }
            }
          )
        }
        console.log('substatus', valid)
      })
    },
    cancel() {
      console.log('点击取消')
      this.back()
    },
    back() {
      this.$router.back()
    },
    ssqChange(e, opt) {
      this.form.pro = e[0] || ''
      this.form.city = e[1] || ''
      this.form.area = e[2] || ''
      this.form.pro_code = opt && opt[0] ? opt[0].adcode : ''
      this.form.city_code = opt && opt[1] ? opt[1].adcode : ''
      this.form.area_code = opt && opt[2] ? opt[2].adcode : ''
      this.form = {
        ...this.form,
      }
    },
    initLoad(ind) {
      console.log('执行次数', ind)
      let code = this.addrCode[ind]
      for (let i = 0; i < this.curParent.length; i++) {
        let item = this.curParent[i]
        if (code == item.adcode) {
          const targetOption = item
          targetOption.loading = true
          let subdistrict =
            targetOption.level == 'province'
              ? 1
              : targetOption.level == 'city'
              ? 2
              : targetOption.level == 'district'
              ? 3
              : 4
          axios
            .get('https://restapi.amap.com/v3/config/district', {
              params: {
                keywords: targetOption.adcode,
                subdistrict: subdistrict,
                key: 'ce7404ac71c28a7893cae3ccc5517da0',
              },
            })
            .then((res) => {
              for (let i = 0; i < res.data.districts[0].districts.length; i++) {
                let item = res.data.districts[0].districts[i]
                delete item.districts
                item.isLeaf =
                  item.level == 'district' ||
                  res.data.districts[0].adcode == 810000 ||
                  res.data.districts[0].adcode == 820000
              }
              targetOption.districts = res.data.districts[0].districts
              this.ssqData = [...this.ssqData]
              targetOption.loading = false
              this.curParent = res.data.districts[0].districts
              console.log(ind + 2)
              if (ind + 2 != this.addrCode.length) {
                this.initLoad(ind + 1)
              }
            })
            .catch((err) => {
              console.log(err)
            })
          break
        }
      }
    },
    loadSsq(selectedOptions) {
      console.log('selectedOptions', selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // load options lazily
      let subdistrict =
        targetOption.level == 'province'
          ? 1
          : targetOption.level == 'city'
          ? 2
          : targetOption.level == 'district'
          ? 3
          : 4
      axios
        .get('https://restapi.amap.com/v3/config/district', {
          params: {
            keywords: targetOption.adcode,
            subdistrict: subdistrict,
            key: 'ce7404ac71c28a7893cae3ccc5517da0',
          },
        })
        .then((res) => {
          let districts = res.data.districts[0].districts
          for (let i = 0; i < districts.length; i++) {
            let item = districts[i]
            delete item.districts
            item.isLeaf =
              item.level == 'district' ||
              res.data.districts[0].adcode == 810000 ||
              res.data.districts[0].adcode == 820000
          }
          targetOption.districts = districts
          targetOption.loading = false
          this.ssqData = [...this.ssqData]
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  computed: {
    ssqvalue() {
      return [this.pro, this.city, this.area]
    },
  },
}
</script>

<style lang="less" scoped>
.row-two-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item {
    width: 277px;
  }

  .line-two {
    height: 1px;
    background-color: #979797;
    width: 38px;
  }
}

.upload-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-upload {
  width: 86px;
  height: 86px;
}
</style>
<style lang="less">
.form-edit {
  .upload-card {
    .ant-upload.ant-upload-select-picture-card {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
</style>
