<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">操作人员</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model
      class="form-search"
      :colon="false"
      layout="inline"
      :model="query"
      labelAlign="left"
      @submit="handleSearch"
      @submit.native.prevent
    >
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item label="用户名称">
          <a-input v-model="query.name" placeholder="请输入用户名称" />
        </a-form-model-item>
        <a-form-model-item label="联系电话">
          <a-input v-model="query.phone" placeholder="请输入联系电话" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="toEdit(-1)">新增人员</a-button>
    </a-row>
    <a-table
      class="main-table"
      :pagination="{
        current: query.page,
        total: query.total,
      }"
      @change="listChange"
      :columns="columns"
      :data-source="data"
      rowKey="id"
      style="margin-top: 14px"
      v-viewer
    >
      <span slot="admin_name" slot-scope="text, record">
        <a @click="toSiteList(record.op_id)">关联工地</a>
      </span>
      <span slot="qr_code" slot-scope="text, record">
        <!-- <img :src="text" class="img-qr" v-if="text" /> -->
        <div alt="" class="img-qr_code" @click="showImg(record)" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="toEdit(record.op_id)">编辑</a>
          <a style="color: #e0352b" @click="del(record.op_id)">删除</a>
        </a-space>
      </span>
    </a-table>
    <a-drawer
      title="关联工地"
      placement="right"
      :closable="true"
      :width="600"
      :visible="visible"
      @close="onClose"
    >
      <!-- :after-visible-change="afterVisibleChange" -->
      <a-table
        :data-source="data1"
        :columns="columns1"
        :pagination="{
          current: query1.page,
          total: query1.total,
        }"
        @change="listChange1"
      ></a-table>
    </a-drawer>
  </div>
</template>

<script>
import { domain } from '@/siteInfo'
import axios from 'axios'
// import { ssqdata } from '@/utils/ssqdata'

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
        total: 0,
      },
      query1: {
        page: 1,
        limit: 10,
        total: 0,
      },
      visible: false,
      columns1: [
        {
          title: '工地名称',
          dataIndex: 'company_name',
        },
      ],
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '用户名称',
          dataIndex: 'name',
        },
        {
          title: '企业名称',
          dataIndex: 'company_name',
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
        },
        {
          title: '证件有效期',
          customRender: (text, record, index) =>
            `${record.begin_time_str}-${record.end_time_str}`,
        },
        {
          title: '关联工地',
          dataIndex: 'admin_name',
          scopedSlots: {
            customRender: 'admin_name',
          },
        },
        {
          title: '二维码',
          dataIndex: 'qr_code',
          scopedSlots: {
            customRender: 'qr_code',
          },
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      data: [],
      data1: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    del(id) {
      console.log(id)
      this.$confirm({
        title: '确认',
        content: '确认删除该人员吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/operator/delete', {
            op_id: id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {},
      })
    },
    getSiteData(id) {
      this.$post(domain + '/admin/v1/operator/info', {
        op_id: id,
      }).then((res) => {
        console.log(res)
        const { code, data } = res
        if (code == 0) {
          this.data1 = data.customer_list
        }
      })
    },
    toSiteList(id) {
      this.getSiteData(id)
      this.visible = true
    },
    showImg(record) {
      console.log(record)
      if (record.qr_code) {
        const $viewer = this.$viewerApi({
          images: [record.qr_code],
        })
      } else {
        this.$post(domain + '/admin/v1/operator/refreshQrCode', {
          op_id: record.op_id,
        }).then((res) => {
          const $viewer = this.$viewerApi({
            images: [res.data],
          })
        })
      }
    },
    onClose() {
      this.visible = false
    },
    listChange(e) {
      this.query.page = e.current
      this.getList()
    },
    listChange1(e) {
      this.query1.page = e.current
      this.getSiteData()
    },
    searchList() {
      this.getList()
    },
    equipmentOn(id) {
      this.$post(domain + '/admin/v1/equipment/qualification/on', {
        material_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
          console.log(res)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toEdit(id) {
      this.$router.push({
        path: '/personEdit',
        query: {
          id: id,
        },
      })
    },
    toEquipmentList(cid) {
      this.$router.push({
        path: '/deviceList',
        query: {
          cid: cid,
        },
      })
    },
    getList() {
      console.log('搜索对象', this.query)
      this.$post(domain + '/admin/v1/operator/list', this.query).then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.data = res.data.list
          this.query.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    Reset() {
      this.query = {
        page: this.query.page,
        limit: this.query.limit,
      }
      this.getList()
    },
    handleSearch(query) {
      this.query.page = 1
      this.getList()
      console.log(query)
    },
    changeCheck(value) {
      console.log(value)
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
.img-qr_code {
  width: 30px;
  height: 30px;
  background-image: url('../../assets/qr_code.png');
  background-size: 100%;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
