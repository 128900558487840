<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">联网设备</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 20px">
					<a-button type="primary" html-type="submit" size="big">
						查询
					</a-button>
					<a-button @click="relationReset" size="big" style="margin-left: 20px; margin-right: 20px;">重置
					</a-button>
				</a-form-model-item>
				<a-form-model-item label="物联网号" style="margin-left: 35px">
					<a-input v-model="relationquery.device_id" placeholder="物联网号" size="small" />
				</a-form-model-item>
				<a-form-model-item label="设备类型">
					<a-select v-model="relationquery.type" class="a-select" placeholder="请选择类型" @change="handleChange"
						style="margin-bottom: ;">
						<a-select-option :value="0">
							{{ '全部' }}
						</a-select-option>
						<a-select-option :value="1">
							{{ '主设备' }}
						</a-select-option>
						<a-select-option :value="2">
							{{ '从1设备' }}
						</a-select-option>
						<a-select-option :value="3">
							{{ '从2设备' }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="绑定类型">
					<a-select v-model="relationquery.is_bind" class="a-select" placeholder="请选择类型" @change="handleChange">
						<a-select-option :value="0">
							{{ '全部' }}
						</a-select-option>
						<a-select-option :value="1">
							{{ '已绑定' }}
						</a-select-option>
						<a-select-option :value="-1">
							{{ '未绑定' }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: relationquery.page,
			total: relationpagetotal
		}" @change="relationListChange" :columns="relationColumns" :data-source="relationData" rowKey="id"
			style="margin-top: 14px;margin-left: 25px;margin-right:25px;" v-viewer>
			<span slot="type" slot-scope="text, record">
				{{ text == 1 ? '主设备' : (text == 2 ? '从1设备' : '从2设备') }}
			</span>
			<span slot="is_bind" slot-scope="text, record">
				{{ text == 1 ? '已绑定' : '未绑定' }}
			</span>
		</a-table>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			relationvisible: false,
			relationquery: {
				page: 1,
				limit: 10
			},
			relationpagetotal: 0,
			relationData: [],
			relationColumns: [{
				title: '设备类型',
				dataIndex: 'type',
				scopedSlots: {
					customRender: 'type'
				}
			},
			{
				title: '物联网号',
				dataIndex: 'device_id'
			},
			{
				title: '最近上线时间',
				dataIndex: 'updated_at_str'
			},
			{
				title: '是否绑定',
				dataIndex: 'is_bind',
				scopedSlots: {
					customRender: 'is_bind'
				}
			}
			],
		}
	},
	created() {
		this.relationList()
	},
	methods: {
		validator(key, callback) {
			if (this.form[key] && this.form[key].length > 0) {
				callback()
			} else {
				callback(new Error('请输入物联网号'))
			}
		},
		relationListOn() {
			let routeUrl = this.$router.resolve({
				path: '/deviceList' // 这里的路径就可以正常的写，不需要添加_blank: true
			})
			window.open(routeUrl.href, '_blank')
			this.relationvisible = true
			this.relationList()
		},
		relationClose() {
			this.relationvisible = false;
		},
		handleChange() {
			this.relationquery.page = 1
			this.relationList()
		},
		relationHandleSearch() {
			this.relationList()
		},
		relationList() {
			this.$post(domain + '/admin/v1/equipment/equipment/connectList', this.relationquery).then(res => {
				if (res.code === 0) {
					this.relationData = res.data.list
					console.log('我是不是空数组', this.relationData)
					this.relationpagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		relationReset() {
			this.relationquery = {
				page: 1,
				limit: this.relationquery.limit,
			}
			this.relationList()
		},
		relationListChange(e) {
			this.relationquery.page = e.current
			this.relationList();
		},

	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
