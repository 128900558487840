var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-box"},[_vm._m(0),_c('a-divider',{staticStyle:{"margin-left":"-10px"}}),_c('div',{staticClass:"content_box"},[_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{staticClass:"col_left",attrs:{"flex":4}},[_c('div',{staticClass:"title"},[_c('span',[_c('strong',[_vm._v("地图名称：")]),_vm._v(_vm._s(_vm.data.name))]),_c('span',[_c('strong',[_vm._v("项目名称：")]),_vm._v(_vm._s(_vm.itemData.name))]),_c('span',[_c('strong',[_vm._v("工地名称：")]),_vm._v(_vm._s(_vm.itemData.company_name))]),_c('span',[_c('strong',[_vm._v("区域名称：")]),_vm._v(_vm._s(_vm.itemData.gov_name))])])]),_c('a-col',[_c('a-tooltip',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("重新上传地图将清空原地图所有已打点设备，请谨慎操作！")])]),_c('a-icon',{staticStyle:{"margin-right":"10px","font-size":"26px","color":"rgba(0, 0, 0, 0.45)","vertical-align":"middle"},attrs:{"type":"info-circle","theme":"filled"}})],2),_c('a-upload',{attrs:{"name":"img","multiple":false,"accept":".jpg, .jpeg, .png","action":_vm.$domain + '/admin/v1/map/mapUpload',"data":{ map_id: _vm.map_id },"headers":{
            authorization: _vm.authorization,
          },"show-upload-list":false},on:{"change":_vm.handleChange}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" 上传地图 ")])],1)],1)],1),_c('div',{staticClass:"card_table"},[(!_vm.data.map_url)?_c('a-upload-dragger',{staticClass:"upload_box",attrs:{"name":"img","multiple":false,"accept":".jpg, .jpeg, .png","action":_vm.$domain + '/admin/v1/map/mapUpload',"data":{ map_id: _vm.map_id },"headers":{
          authorization: _vm.authorization,
        },"show-upload-list":false},on:{"change":_vm.handleChange}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('img',{staticStyle:{"width":"192px"},attrs:{"src":require("@/assets/image.png"),"alt":""}})]),_c('p',{staticClass:"ant-upload-text"},[_vm._v("请点击上传工地图片")]),_c('p',{staticClass:"ant-upload-text"},[_vm._v("建议尺寸：1920*1080")]),_c('p',{staticClass:"ant-upload-text"},[_vm._v("建议大小：不超过5M")])]):_c('div',[_c('canvasImage',{attrs:{"url":_vm.data.map_url,"isFullType":_vm.isFullType,"equipment_id":_vm.equipment_id,"dotData":_vm.dotData,"delId":_vm.delId},on:{"emitPosition":_vm.getPosition,"handleFullDrewer":_vm.getFullDrewer,"showDotDetails":_vm.getDetails}})],1)],1)],1),_c('a-drawer',{staticClass:"drewer-box",attrs:{"title":"打点设置","width":"600","closable":true,"visible":_vm.isFullDrewer},on:{"close":_vm.onDrawerClose}},[_c('a-form-model',{ref:"Form",attrs:{"rules":_vm.rules,"model":_vm.query,"layout":"inline"}},[_c('a-form-model-item',{attrs:{"label":"所在位置","prop":"build_name","label-col":{ span: 7, offset: -12 }}},[_c('a-input',{attrs:{"placeholder":"请输入所在位置"},model:{value:(_vm.query.build_name),callback:function ($$v) {_vm.$set(_vm.query, "build_name", $$v)},expression:"query.build_name"}})],1)],1),_c('a-form-model',{attrs:{"layout":"inline"}},[_c('a-form-model-item',{attrs:{"label":"出厂编号","label-col":{ span: 7, offset: -12 }}},[_c('a-input',{attrs:{"placeholder":"请输入出厂编号"},model:{value:(_vm.query.main_id),callback:function ($$v) {_vm.$set(_vm.query, "main_id", $$v)},expression:"query.main_id"}})],1),_c('a-form-model-item',{attrs:{"label-col":{ span: 4 }}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v(" 查询 ")])],1)],1),_c('p',[_vm._v("未打点设备")]),_c('a-table',{attrs:{"data-source":_vm.listData,"columns":_vm.columns,"pagination":{
        current: _vm.query1.page,
        total: _vm.query1.total,
      }},on:{"change":_vm.listChange},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.handleFull(record)}}},[_vm._v("选择")])])}}])}),_c('div',{style:({
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'right',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      })},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onChildrenDrawerSave}},[_vm._v(" 保存 ")])],1)],1),_c('a-drawer',{staticClass:"drewer-box",attrs:{"title":"点位信息","width":"600","closable":true,"visible":_vm.isDotInfoDrewer},on:{"close":_vm.onDotInfoClose}},[_c('div',[_c('p',{staticClass:"right_p"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.delDot}},[_vm._v(" 删除点位 ")])],1),_c('p',{staticClass:"center_p"},[_c('span',[_vm._v("所在位置：")]),_c('span',[_vm._v(_vm._s(_vm.dotInfoDetails.build_name))])]),_c('p',{staticClass:"center_p"},[_c('span',[_vm._v("主设备 ：")]),_c('span',[_vm._v(_vm._s(_vm.dotInfoDetails.equipment_id))])]),_c('p',{staticClass:"center_p"},[_c('span',[_vm._v("从机1 ：")]),_c('span',[_vm._v(_vm._s(_vm.dotInfoDetails.one_sim))])]),_c('p',{staticClass:"center_p"},[_c('span',[_vm._v("从机2 ：")]),_c('span',[_vm._v(_vm._s(_vm.dotInfoDetails.two_sim))])]),_c('p',{staticClass:"center_p"},[_c('span',[_vm._v("经度：")]),_c('span',[_vm._v(_vm._s(_vm.dotInfoDetails.coordinate_y))])]),_c('p',{staticClass:"center_p"},[_c('span',[_vm._v("纬度：")]),_c('span',[_vm._v(_vm._s(_vm.dotInfoDetails.coordinate_x))])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("地图编辑")])])}]

export { render, staticRenderFns }