<template>
    <div class="home-banner">
        <h1 class="index_title">
            欢迎来到防坠落吊篮智能平台
        </h1>
    </div>
</template>

<script>
export default {
    data() {
        return {
            text: '',
            t2: ''
        }
    },
    mounted() {

    },
    methods: {
    },
}
</script>
<style lang="less">
.home-banner {
    position: fixed;
    left: 0;
    top: 64px;
    width: 100%;
    bottom: 0;
    background: #fff;

    .index_title {
        position: absolute;
        text-align: center;
        width: 100%;
        top: 40%;
        transform: translateY(-50%);
    }
}
</style>