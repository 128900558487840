let headerNav = [{
	name: "公司管理",
	path: "/company",
},
{
	name: "用户管理",
	path: "/user",
},
{
	name: "设备管理",
	path: "/device",
},
{
	name: '地图管理',
	path: '/map'
},
{
	name: '设备告警',
	path: '/warn'
},
{
	name: '账号管理',
	path: '/permision'
},
	// {
	//   name: "园区招商",
	//   path: "/gardenSwiper",
	// },
	// {
	//   name: "政策服务",
	//   path: "/policySwiper",
	// },
	// {
	//   name: "产业服务",
	//   path: "/serveSwiper",
	// },
	// {
	//   name: "服务工具",
	//   path: "/serveAppClass",
	// },
	// {
	//   name: "视频中心",
	//   path: "/video",
	// },
	// {
	//   name: "员工管理",
	//   path: "/jueseList",
	// },
	// {
	//   name: "系统设置",
	//   path: "/setting?type=0",
	// },
];

let sidebarList = [
	[{
		name: "公司介绍",
		path: "/companyInfo",
		parent: '公司管理'
	},
	{
		name: "公司简介",
		path: "/companyBrief",
		parent: '公司管理'
	},
	{
		name: "相关资质",
		path: "/qualificationList",
		parent: '公司管理'
	},
	{
		name: "操作视频",
		path: "/videoList",
		parent: '公司管理'
	},
	],
	[
		{
			name: "客户管理",
			path: "/customerList",
			parent: '用户管理'
		},
		{
			name: "操作人员",
			path: "/operatingPersonnelList",
			parent: '用户管理'
		},
	],

	[{
		name: "设备列表",
		path: "/deviceList",
		parent: '设备管理'
	},


	{
		name: "工地设备",
		path: "/siteList",
		parent: '设备管理'
	},

	{
		name: "租赁设备",
		path: "/leaseCustomerList",
		parent: '设备管理'
	},
	{
		name: "产权设备",
		path: "/propertyCustomerList",
		parent: '设备管理'
	},
	],
	[
		{
			name: "项目列表",
			path: "/mapList",
			parent: '地图管理'
		}
	],
	[{
		name: "告警事件",
		path: "/eventList",
		parent: '设备告警'
	}],
	[{
		name: "员工列表",
		path: "/yuangongList",
		parent: '员工管理'
	}, {
		name: "角色列表",
		path: "/jueseList",
		parent: '员工管理'
	}, {
		name: "区域账号",
		path: "/governmentList",
		parent: '员工管理'
	}],

	// [
	//   {
	//     name: "企业列表",
	//     path: "/userList",
	//  parent: '用户中心'
	//   },
	//   {
	//     name: "预约看房",
	//     path: "/userAppointment",
	//  parent: '用户中心'
	//   },
	//   {
	//     name: "需求委托",
	//     path: "/userEntrust",
	//  parent: '用户中心'
	//   },
	// ],
	// [
	//   {
	//     name: "设备列表",
	//     path: "/deviceList",
	//  	parent: '设备管理'
	//   },
	//   {
	//     name: "园区列表",
	//     path: "/gardenList",
	//  parent: '园区招商'
	//   },
	//   {
	//     name: "热门园区",
	//     path: "/hotGarden",
	//  parent: '园区招商'
	//   },
	//   {
	// name: "招商轮播",
	// path: "/gardenSwiper",
	// parent: '园区招商'
	//  }
	// ],
	// [
	//   {
	//     name: "政策列表",
	//     path: "/policyList",
	//  parent: '政策'
	//   },
	//   {
	//     name: "轮播",
	//     path: "/policySwiper",
	//  parent: '政策'
	//   }
	// ],
	// [
	//   {
	// name: "产业服务列表",
	// path: "/serveList",
	// parent: '政策'
	//  },
	//  {
	//     name: "轮播",
	//     path: "/serveSwiper",
	//  parent: '政策'
	//   },
	//   {
	//     name: "服务模块",
	//     path: "/serveBanner",
	//  parent: '政策'
	//   }
	// ],
	// [
	//   {
	//     name: "类型设置",
	//     path: "/serveAppClass",
	//  parent: '产业服务'
	//   },
	//   {
	//     name: "服务工具列表",
	//     path: "/serveAppList",
	//  parent: '产业服务'
	//   },
	//   {
	//     name: "服务工具模块",
	//     path: "/serveApp",
	//  parent: '产业服务'
	//   },
	// ],
	// [
	//   {
	//     name: "视频列表",
	//     path: "/video",
	//  parent: '视频中心'
	//   },
	// ],
	// [
	//   {
	// name: "员工列表",
	// path: "/yuangongList",
	// parent: '员工管理'
	//  },{
	//     name: "角色列表",
	//     path: "/jueseList",
	//  parent: '员工管理'
	//   },
	// ],
	// [
	//   {
	//     name: "产业服务类型设置",
	//     path: "/setting?type=0",
	//  parent: '系统设置'
	//   },
	//   {
	//     name: "园区类型设置",
	//     path: "/setting?type=1",
	//  parent: '系统设置'
	//   },
	//   {
	//     name: "政策类型设置",
	//     path: "/setting?type=2",
	//  parent: '系统设置'
	//   },
	//   {
	//     name: "配套设施",
	//     path: "/setting?type=3",
	//  parent: '系统设置'
	//   },
	//   {
	//     name: "政策标签",
	//     path: "/setting?type=4",
	//  parent: '系统设置'
	//   },
	//   {
	//     name: "标签配置",
	//     path: "/tagSetting?type=0",
	//  parent: '系统设置'
	//   },
	//   {
	//     name: "用户协议",
	//     path: "/xieyi",
	//  parent: '系统设置'
	//   },
	//   {
	//     name: "关于我们",
	//     path: "/about",
	//  parent: '系统设置'
	//   },
	// ],
];
let sidebarList2 = [
	[{
		name: "公司介绍",
		path: "/companyInfo",
		parent: '公司管理'
	},
	{
		name: "公司简介",
		path: "/companyBrief",
		parent: '公司管理'
	},
	{
		name: "相关资质",
		path: "/qualificationList",
		parent: '公司管理'
	},
	{
		name: "操作视频",
		path: "/videoList",
		parent: '公司管理'
	},
	],
	[{
		name: "设备列表",
		path: "/deviceList",
		parent: '设备管理'
	},
	{
		name: "事件列表",
		path: "/eventList",
		parent: '设备管理'
	},
	{
		name: "客户管理",
		path: "/customerList",
		parent: '设备管理'
	},
	{
		name: "租赁客户",
		path: "/leaseCustomerList",
		parent: '设备管理'
	},
	{
		name: "产权客户",
		path: "/propertyCustomerList",
		parent: '设备管理'
	},
	],
	// [{
	// 	name: "员工列表",
	// 	path: "/yuangongList",		
	// 	parent: '员工管理'
	// }, {
	// 	name: "角色列表",
	// 	path: "/jueseList",
	// 	parent: '员工管理'
	// }, ],
	// [{
	// 		name: "首页轮播",
	// 		path: "/homeSwiper",
	// 		parent: '首页配置'
	// 	},
	// 	{
	// 		name: "首页推荐",
	// 		path: "/homeGarden",
	// 		parent: '首页配置'
	// 	},
	// 	{
	// 		name: "底部模块",
	// 		path: "/homeBanner",
	// 		parent: '首页配置'
	// 	},
	// ],
	// [{
	// 		name: "企业列表",
	// 		path: "/userList",
	// 		parent: '用户中心'
	// 	},
	// 	{
	// 		name: "预约看房",
	// 		path: "/userAppointment",
	// 		parent: '用户中心'
	// 	},
	// 	{
	// 		name: "需求委托",
	// 		path: "/userEntrust",
	// 		parent: '用户中心'
	// 	},
	// ],
	// [{
	// 		name: "招商轮播",
	// 		path: "/gardenSwiper",
	// 		parent: '园区招商'
	// 	},
	// 	{
	// 		name: "园区列表",
	// 		path: "/gardenList",
	// 		parent: '园区招商'
	// 	},
	// 	{
	// 		name: "热门园区",
	// 		path: "/hotGarden",
	// 		parent: '园区招商'
	// 	},
	// ],
	// [{
	// 		name: "轮播",
	// 		path: "/policySwiper",
	// 		parent: '政策'
	// 	},
	// 	{
	// 		name: "政策列表",
	// 		path: "/policyList",
	// 		parent: '政策'
	// 	},
	// ],
	// [{
	// 		name: "轮播",
	// 		path: "/serveSwiper",
	// 		parent: '政策'
	// 	},
	// 	{
	// 		name: "产业服务列表",
	// 		path: "/serveList",
	// 		parent: '政策'
	// 	},
	// 	{
	// 		name: "服务模块",
	// 		path: "/serveBanner",
	// 		parent: '政策'
	// 	},
	// ],
	// [{
	// 		name: "类型设置",
	// 		path: "/serveAppClass",
	// 		parent: '产业服务'
	// 	},
	// 	{
	// 		name: "服务工具列表",
	// 		path: "/serveAppList",
	// 		parent: '产业服务'
	// 	},
	// 	{
	// 		name: "服务工具模块",
	// 		path: "/serveApp",
	// 		parent: '产业服务'
	// 	},
	// ],
	// [{
	// 	name: "视频列表",
	// 	path: "/video",
	// 	parent: '视频中心'
	// }, ],
	// [{
	// 		name: "产业服务类型设置",
	// 		path: "/setting?type=0",
	// 		parent: '系统设置'
	// 	},
	// 	{
	// 		name: "园区类型设置",
	// 		path: "/setting?type=1",
	// 		parent: '系统设置'
	// 	},
	// 	{
	// 		name: "政策类型设置",
	// 		path: "/setting?type=2",
	// 		parent: '系统设置'
	// 	},
	// 	{
	// 		name: "配套设施",
	// 		path: "/setting?type=3",
	// 		parent: '系统设置'
	// 	},
	// 	{
	// 		name: "政策标签",
	// 		path: "/setting?type=4",
	// 		parent: '系统设置'
	// 	},
	// 	{
	// 		name: "标签配置",
	// 		path: "/tagSetting?type=0",
	// 		parent: '系统设置'
	// 	},
	// 	{
	// 		name: "用户协议",
	// 		path: "/xieyi",
	// 		parent: '系统设置'
	// 	},
	// 	{
	// 		name: "关于我们",
	// 		path: "/about",
	// 		parent: '系统设置'
	// 	},
	// ],
];
export {
	headerNav,
	sidebarList,
	sidebarList2
};
