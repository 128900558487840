<template>
	<div class="app-box">
		<div class="title-box">
			<div class="back" @click="back">
				<a-icon type="left" />
			</div>
			<div class="line"></div>
			<p class="title">变更记录</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="toAdd(query.equipment_id)">添加</a-button>
			<label>设备主id:</label>
			<label>{{ eq_main_id }}</label>
		</a-row>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="id" style="margin-top: 14px"
			v-viewer>
		</a-table>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10,
				equipment_id: ''
			},
			pagetotal: 0,
			eq_main_id: '',
			checkValue: [],
			checkOptions: [{
				label: '开启',
				value: 1
			},
			{
				label: '关闭',
				value: -1
			}
			],
			columns: [{
				title: '排序',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '内容',
				dataIndex: 'content'
			},
			{
				title: '上传时间',
				dataIndex: 'create_at_str'
			},
			{
				title: '操作人',
				dataIndex: 'admin_name'
			},
			],
			data: [],
		}
	},
	created() {
		this.query.equipment_id = this.$route.query.id;
		this.getList()
	},
	methods: {
		back() {
			this.$router.back()
		},
		toAdd(id) {
			this.$router.push({
				path: '/changeEdit',
				query: {
					id: id
				}
			})
		},
		getList() {
			this.$post(domain + '/admin/v1/equipment/change/list', this.query).then(res => {
				if (res.code === 0) {
					this.eq_main_id = res.data.eq_main_id
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		handleSearch(query) {
			console.log(query)
		},
		changeCheck(value) {
			console.log('value', value)
		},
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
