<template>
	<div class="app-box">
		<div class="title-box">
			<div class="back" @click="back">
				<a-icon type="left" />
			</div>
			<div class="line"></div>
			<p class="title">对比数据</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit">
						查询
					</a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="设备ID">
					<a-input v-model="query.main_id" placeholder="请输入设备主id" />
				</a-form-model-item>
				<a-form-item label="开始时间">
					<a-date-picker placeholder="请选择时间" v-model="beginMonent" @change="beginChange" />
				</a-form-item>
				<a-form-model-item label="至">
					<a-date-picker placeholder="请选择时间" v-model="endMonent" @change="endChange" />
				</a-form-model-item>
			</a-row>
			<a-row style="margin-top: 20px">
				<a-form-model-item label="设备主id:">
					<label>{{ eq_main_id }}</label>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: query.total
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="id" style="margin-top: 14px" v-viewer>
			<span slot="is_on" slot-scope="text, record">
				<a-tag :color="text === 1 ? '#74CE78' : '#757776'">
					{{ text === 1 ? '开启' : '关闭' }}
				</a-tag>
				<a-button type="link" @click="equipmentOn(record.material_id)">
					{{ text === 1 ? '关闭' : '开启' }}
				</a-button>
			</span>
		</a-table>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			query: {
				page: 1,
				limit: 10,
				total: 0,
				equipment_id: ''
			},
			beginMonent: '',
			endMonent: '',
			eq_main_id: '',
			birthMoment: '',
			columns: [{
				title: '排序',
				customRender: (text, record, index) => index + 1
			},
			{
				title: '从ID-1',
				dataIndex: 'one_id'
			},
			{
				title: '从ID-2',
				dataIndex: 'two_id',
			},
			{
				title: '称重时间',
				dataIndex: 'weigh_at_str'
			},
			{
				title: '称重值',
				customRender: (text, record, index) => 'A+B:' + `${record.weigh_value[0]['A+B']}` + ' D+E:' +
					`${record.weigh_value[1]['D+E']}` + ' C:' + `${record.weigh_value[0].C}` + ' F:' +
					`${record.weigh_value[1].F}`
			},
			{
				title: '对比值',
				customRender: (text, record, index) => 'G:' + `${record.contrast_g}` + ' H:' +
					`${record.contrast_h}` + ' Z:' +
					`${record.contrast_z}`
			},
			],
			data: [],
		}
	},
	created() {
		this.query.equipment_id = this.$route.query.id
		this.getList()
	},
	methods: {
		back() {
			this.$router.back()
		},
		listChange(e) {
			this.query.page = e.current
			this.getList();
		},
		getList() {
			console.log('query提交', this.query)
			this.$post(domain + '/admin/v1/equipment/weigh/list', this.query).then(res => {
				console.log(res)
				if (res.code === 0) {
					this.eq_main_id = res.data.eq_main_id
					this.data = res.data.list
					this.query.total = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.query = {
				page: this.query.page,
				limit: this.query.limit,
				equipment_id: this.query.equipment_id,
			}
			this.getList()
		},
		beginChange(e, d) {
			this.query.begin_time = d
		},
		endChange(e, d) {
			this.query.end_time = d
		},
		handleSearch(query) {
			this.getList()
			console.log(query)
		},
		changeCheck(value) {
			console.log(value)
		},
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4E80F8;
	}

	.title {
		color: #383F50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
