<template>
  <div class="app-box">
    <div class="title-box">
      <div class="back" @click="back">
        <a-icon type="left" />
      </div>
      <div class="line"></div>
      <p class="title">人员{{ customer_id == -1 ? '新增' : '编辑' }}</p>
    </div>
    <a-divider style="margin-left: -10px" />

    <a-form-model
      class="form-edit"
      :model="form"
      ref="form"
      :form="form1"
      :rules="rules"
      :labelCol="{ span: 3, offset: 2 }"
      :wrapperCol="{ span: 15 }"
      style="width: 1000px"
    >
      <a-form-model-item label="企业名称" prop="company_name">
        <a-input v-model="form.company_name" placeholder="请输入企业名称" />
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="name">
        <a-input v-model="form.name" placeholder="请输入客户姓名" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="phone">
        <a-input v-model="form.phone" placeholder="请输入联系电话" />
      </a-form-model-item>
      <a-form-model-item label="证件有效期" prop="date">
        <a-range-picker
          @change="onChange"
          v-model="date"
          valueFormat="YYYY-MM-DD"
        />
      </a-form-model-item>
      <a-form-model-item label="相关证件" prop="certificate">
        <a-upload
          name="file"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :action="$domain + '/admin/v1/index/imgUpload'"
          @change="handleChange"
        >
          <img
            v-if="form.certificate"
            :src="form.certificate"
            alt="avatar"
            style="max-width: 200px"
          />
          <div v-else>
            <a-icon :type="'plus'" />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

      <a-form-model-item label=" " :colon="false">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" style="margin-left: 12px" @click="submitForm">
          提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import axios from 'axios'
import { domain } from '@/siteInfo.js'
import { message } from 'ant-design-vue'
import { ssqdata } from '@/utils/ssqdata'
import { disTimeToDate } from '@/utils/formatterDate.js'
import moment from 'moment'
export default {
  data() {
    return {
      customer_id: '',
      form: {
        name: '',
        phone: '',
        date: ['', ''],
      },
      date: ['', ''],
      form1: this.$form.createForm(this, { name: 'coordinated' }),
      ssqData: ssqdata,
      addrCode: [],
      curParent: [],
      rules: {
        company_name: [
          {
            required: true,
            message: '请输入企业名称',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: 'change',
          },
        ],
        date: [
          {
            required: true,
            message: '请选择证件有效期',
            trigger: 'change',
          },
        ],
        certificate: [
          {
            required: true,
            message: '请上传相关证件',
            trigger: 'change',
          },
        ],
        // addrValue: [{
        // 	required: true,
        // 	message: '请选择地区',
        // 	trigger: 'change'
        // }],
      },
    }
  },
  async created() {
    console.log('创建被执行了')
    this.customer_id = this.$route.query.id
    if (this.$route.query.id != -1) {
      this.$post(domain + '/admin/v1/operator/info', {
        op_id: this.customer_id,
      }).then((res) => {
        if (res.code === 0) {
          this.form = res.data
          console.log(disTimeToDate(this.form.begin_time))
          this.form.date = [
            disTimeToDate(this.form.begin_time),
            disTimeToDate(this.form.end_time),
          ]
          this.date = [
            disTimeToDate(this.form.begin_time),
            disTimeToDate(this.form.end_time),
          ]
          this.initLoad(0)
        } else {
          this.$message.error(res.msg)
        }
      })
    } else {
      console.log('id', this.$route.query.id)
    }
  },
  methods: {
    onChange(date, dateString) {
      this.form.date = dateString
      this.date = dateString
      this.form1.setFieldsValue({ date: date })
      this.$nextTick(() => {
        this.$refs.form.validateField('date')
      })
    },
    validator(key, callback) {
      if (this.form[key] && this.form[key].length > 0) {
        callback()
      } else {
        callback(new Error('请输入物联网号'))
      }
    },
    handleChange(res) {
      console.log(res)
      if (res.file.status == 'done' || res.file.status == 'removed') {
        // this.form.certificate = imgArr[0]
        const response = res.file.response
        if (response && response.code === 0) {
          this.form['certificate'] = response.data.really_path
          this.form = {
            ...this.form,
          }
          this.$nextTick(() => {
            this.$refs.form.validateField('certificate')
          })
        } else {
          this.$message.error(response.msg)
        }
      }
    },
    showChange(type) {
      //编辑
      if (this.customer_id > 0 && type != this.form.type) {
        this.$message.error('用户无法编辑类型')
      } else {
        this.form.type = type
      }
    },
    submitForm() {
      let action = ''
      console.log('业务id', this.material_id)
      if (this.customer_id != -1) {
        action = 'edit'
        this.form.customer_id = this.customer_id
      } else {
        action = 'add'
      }
      const data = { ...this.form }
      data.begin_time_str = this.form.date[0]
      data.end_time_str = this.form.date[1]
      delete data.date
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (action === 'add') {
            this.$post(domain + '/admin/v1/operator/add', data).then((res) => {
              if (res.code === 0) {
                this.$message.success('操作成功', 1.5)
                this.$router.back()
              } else {
                this.$message.error(res.msg, 1.5)
              }
            })
          } else {
            this.$post(domain + '/admin/v1/operator/update', data).then(
              (res) => {
                if (res.code === 0) {
                  this.$message.success('操作成功', 1.5)
                  this.$router.back()
                } else {
                  this.$message.error(res.msg, 1.5)
                }
              }
            )
          }
        }
        console.log('substatus', valid)
      })
    },
    cancel() {
      console.log('点击取消')
      this.back()
    },
    back() {
      this.$router.back()
    },
    ssqChange(e, opt) {
      this.form.pro = e[0] || ''
      this.form.city = e[1] || ''
      this.form.area = e[2] || ''
      this.form.pro_code = opt && opt[0] ? opt[0].adcode : ''
      this.form.city_code = opt && opt[1] ? opt[1].adcode : ''
      this.form.area_code = opt && opt[2] ? opt[2].adcode : ''
      this.form = {
        ...this.form,
      }
    },
    initLoad(ind) {
      console.log('执行次数', ind)
      let code = this.addrCode[ind]
      for (let i = 0; i < this.curParent.length; i++) {
        let item = this.curParent[i]
        if (code == item.adcode) {
          const targetOption = item
          targetOption.loading = true
          let subdistrict =
            targetOption.level == 'province'
              ? 1
              : targetOption.level == 'city'
              ? 2
              : targetOption.level == 'district'
              ? 3
              : 4
          axios
            .get('https://restapi.amap.com/v3/config/district', {
              params: {
                keywords: targetOption.adcode,
                subdistrict: subdistrict,
                key: 'ce7404ac71c28a7893cae3ccc5517da0',
              },
            })
            .then((res) => {
              for (let i = 0; i < res.data.districts[0].districts.length; i++) {
                let item = res.data.districts[0].districts[i]
                delete item.districts
                item.isLeaf =
                  item.level == 'district' ||
                  res.data.districts[0].adcode == 810000 ||
                  res.data.districts[0].adcode == 820000
              }
              targetOption.districts = res.data.districts[0].districts
              this.ssqData = [...this.ssqData]
              targetOption.loading = false
              this.curParent = res.data.districts[0].districts
              console.log(ind + 2)
              if (ind + 2 != this.addrCode.length) {
                this.initLoad(ind + 1)
              }
            })
            .catch((err) => {
              console.log(err)
            })
          break
        }
      }
    },
    loadSsq(selectedOptions) {
      console.log('selectedOptions', selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // load options lazily
      let subdistrict =
        targetOption.level == 'province'
          ? 1
          : targetOption.level == 'city'
          ? 2
          : targetOption.level == 'district'
          ? 3
          : 4
      axios
        .get('https://restapi.amap.com/v3/config/district', {
          params: {
            keywords: targetOption.adcode,
            subdistrict: subdistrict,
            key: 'ce7404ac71c28a7893cae3ccc5517da0',
          },
        })
        .then((res) => {
          let districts = res.data.districts[0].districts
          for (let i = 0; i < districts.length; i++) {
            let item = districts[i]
            delete item.districts
            item.isLeaf =
              item.level == 'district' ||
              res.data.districts[0].adcode == 810000 ||
              res.data.districts[0].adcode == 820000
          }
          targetOption.districts = districts
          targetOption.loading = false
          this.ssqData = [...this.ssqData]
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  computed: {
    ssqvalue() {
      return [this.pro, this.city, this.area]
    },
  },
}
</script>

<style lang="less" scoped>
.row-two-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item {
    width: 277px;
  }

  .line-two {
    height: 1px;
    background-color: #979797;
    width: 38px;
  }
}

.upload-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-upload {
  width: 86px;
  height: 86px;
}
</style>
<style lang="less">
.form-edit {
  .upload-card {
    .ant-upload.ant-upload-select-picture-card {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
</style>
