<template>
	<div class="page-box">
		<div class="wrap">
			<div class="add-btn-box">
				<a-button type="primary" @click="showAddMoadl">
					<a-icon type="plus-circle" />
					<span>添加角色</span>
				</a-button>
			</div>
			<div class="table-box">
				<a-table :rowKey="(item, index) => {
						return index
					}
					" :loading="dataLoading" :columns="columns" :data-source="list" :bordered="true" :pagination="{
			hideOnSinglePage: true,
			current: page.current,
			total: page.total
		}" @change="listChange">
					<template slot="operation" slot-scope="index, item">
						<div class="control-box">
							<a-button size="small" type="primary" @click="toEdit(item)">编辑</a-button>
							<a-popconfirm :loading="deleteIng" title="确认删除该角色" ok-text="是" cancel-text="否"
								@confirm="deleteItem(item)">
								<a-button size="small" type="danger">删除</a-button>
							</a-popconfirm>
						</div>
					</template>
				</a-table>
			</div>
		</div>

		<a-modal :title="(role_id ? '修改' : '创建') + '角色'" class="jueseInfo" :visible="visibleInfo" ok-text="提交"
			cancel-text="取消" @ok="submitInfo" :confirmLoading="confirmLoading" @cancel="handleCancel">
			<a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" ref="jueseForm" :model="jueseInfo"
				:rules="jueseRules">
				<a-form-model-item prop="name" label="角色名称">
					<a-input v-model="jueseInfo.name"></a-input>
				</a-form-model-item>
				<a-form-model-item prop="rules" label="权限列表">
					<div class="quanxian-box">
						<div class="top-box">
							<a-checkbox :checked="checkAll" @change="onCheckAllChange"> 全选 </a-checkbox>
						</div>
						<div class="ul">
							<div class="li" v-for="(item, index) in rulesRoutes" :key="item.parent">
								<div class="item-all item">
									<a-checkbox @change="itemAllChange(index)" :checked="item.allChecked"> {{ item.name
									}}</a-checkbox>
								</div>
								<div class="item item-sub">
									<a-checkbox-group :options="item.routes" v-model="item.checkedList"
										@change="itemChange(index)" />
								</div>
							</div>
						</div>
					</div>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import { sidebarList2 } from '@/router/navData.js'
let sidebarList = sidebarList2
console.log(sidebarList)
export default {
	data() {
		let columns = [
			{
				title: '角色名称',
				key: 'name',
				dataIndex: 'name'
			},
			{
				title: '创建人',
				key: 'admin_name',
				dataIndex: 'admin_name'
			},
			{
				title: '创建日期',
				key: 'create_at_str',
				dataIndex: 'create_at_str'
			},
			{
				title: '操作',
				dataIndex: 'operation',
				scopedSlots: { customRender: 'operation' },
				width: '140px'
			}
		]
		let rulesValidator = (rule, value, callback) => {
			if (value.length == 0) {
				callback(new Error('请选择角色权限'))
			} else {
				callback()
			}
		}
		return {
			columns,
			dataLoading: false,
			list: [],
			visibleInfo: false,
			page: {
				current: 1,
				total: 0
			},
			jueseInfo: {
				name: '',
				rules: []
			},
			jueseRules: {
				name: [
					{
						required: true,
						message: '填写角色名称',
						trigger: 'blur'
					}
				],
				ruless: [
					{
						validator: rulesValidator,
						required: true,
						message: '填写角色名称',
						trigger: 'change'
					}
				]
			},
			params: {
				name: '',
				rules: []
			},
			role_id: '',
			rulesRoutes: [],
			initRulesRoutes: [],
			checkAll: false,
			confirmLoading: false,
			deleteIng: false
		}
	},
	computed: {},
	created() {
		// for(let i = 0; i < sidebarList.length; i++) {
		// 	let item = routes[i]
		// 	if(parArr.indexOf(item.meta.parent)>= 0) {
		// 		newRoutes[parArr.indexOf(item.meta.parent)].routes.push({
		// 			value: item.path,
		// 			label: item.meta.title
		// 		})
		// 	}else{
		// 		parArr.push(item.meta.parent)
		// 		newRoutes.push({
		// 			name: item.meta.parent,
		// 			allChecked: false,
		// 			checkedList: [],
		// 			routes: [{
		// 				value: item.path,
		// 				label: item.meta.title
		// 			}]
		// 		})
		// 		console.log(newRoutes)
		// 	}
		// }
		console.log(sidebarList)
		let newRoutes = []
		for (let i = 0; i < sidebarList.length; i++) {
			let item = sidebarList[i]
			let obj = {
				name: sidebarList[i][0].parent,
				allChecked: false,
				checkedList: [],
				routes: []
			}
			for (let k = 0; k < item.length; k++) {
				obj.routes.push({
					value: item[k].path,
					label: item[k].name
				})
			}
			newRoutes.push(obj)
		}
		this.rulesRoutes = newRoutes
		this.initRulesRoutes = newRoutes
	},
	mounted() {
		this.getList()
	},
	methods: {
		getList() {
			let t = this
			t.dataLoading = true
			t.$post('staff/roleList', { page: t.page.current })
				.then(res => {
					let { code, data, msg } = res
					t.dataLoading = false
					if (code == 0) {
						t.list = data.list
						t.page.total = data.total
					}
				})
				.catch(err => {
					t.dataLoading = false
				})
		},
		listChange(e) {
			this.page.current = e.current
			this.getList()
		},
		showAddMoadl() {
			this.visibleInfo = true
		},
		deleteItem(item) {
			this.deleteIng = true
			this.$post('staff/roleDelete', { role_id: item.role_id })
				.then(res => {
					this.deleteIng = false
					let { code, msg, data } = res
					if (code == 0) {
						this.$message.success(msg, 1.5)
						this.getList()
					} else {
						this.$message.error(msg, 1.5)
					}
				})
				.catch(err => {
					this.deleteIng = false
				})
		},
		toEdit(item) {
			console.log(item)
			this.jueseInfo = {
				name: item.name,
				rules: item.rules
			}
			this.role_id = item.role_id
			let rulesArr = item.rules.slice()
			let allWasChecked = true
			for (let i = 0; i < this.rulesRoutes.length; i++) {
				let item = this.rulesRoutes[i]
				item.checkedList = []
				for (let k = 0; k < item.routes.length; k++) {
					let a = rulesArr.filter(b => b == item.routes[k].value)
					if (a.length > 0) {
						item.checkedList = item.checkedList.concat(a)
					}
					if (k == item.routes.length - 1) {
						if (item.checkedList.length == item.routes.length) {
							console.log('最后')
							item.allChecked = true
						} else {
							item.allChecked = false
							allWasChecked = false
						}
					}
				}
			}
			this.checkAll = allWasChecked
			this.visibleInfo = true
		},
		initForm() {
			this.visibleInfo = false
			this.jueseInfo.name = ''
			this.jueseInfo.rules = []
			this.rulesRoutes = this.initRulesRoutes
		},
		handleCancel() {
			this.initForm()
		},
		submitInfo() {
			this.$refs.jueseForm.validate(valid => {
				console.log(1231231231)
				if (valid) {
					this.confirmLoading = true
					let arr = []
					for (let i = 0; i < this.rulesRoutes.length; i++) {
						let item = this.rulesRoutes[i]
						arr = arr.concat(item.checkedList)
					}
					let url = this.role_id ? 'roleEdit' : 'roleAdd'
					let obj = {
						name: this.jueseInfo.name,
						rules: arr
					}
					if (this.role_id) {
						obj.role_id = this.role_id
					}
					this.$post("staff/" + url, obj)
						.then(res => {
							let { code, msg, data } = res
							this.confirmLoading = false
							if (code == 0) {
								this.$message.success(msg, 1.5)
								this.initForm()
								this.getList()
							} else {
								this.$message.error(msg, 1.5)
							}
						})
						.catch(err => {
							this.confirmLoading = false
						})
				}
			})
		},
		onCheckAllChange(e) {
			this.checkAll = e.target.checked
			for (let i = 0; i < this.rulesRoutes.length; i++) {
				this.rulesRoutes[i].allChecked = e.target.checked
				this.rulesRoutes[i].checkedList = []
				if (e.target.checked) {
					for (let k = 0; k < this.rulesRoutes[i].routes.length; k++) {
						console.log(this.rulesRoutes[i].routes)
						this.rulesRoutes[i].checkedList.push(this.rulesRoutes[i].routes[k].value)
					}
				}
			}
		},
		itemAllChange(ind) {
			console.log(ind, event)
			this.rulesRoutes[ind].allChecked = event.target.checked
			console.log(this.rulesRoutes[ind])

			let a = []
			if (event.target.checked) {
				for (let i = 0; i < this.rulesRoutes[ind].routes.length; i++) {
					a.push(this.rulesRoutes[ind].routes[i].value)
				}
			}
			this.rulesRoutes[ind].checkedList = a
		},
		itemChange(ind) {
			console.log(ind)
			if (this.rulesRoutes[ind].checkedList.length == this.rulesRoutes[ind].routes.length) {
				this.rulesRoutes[ind].allChecked = true
				let a = true
				for (let i = 0; i < this.rulesRoutes.length; i++) {
					if (!this.rulesRoutes[i].allChecked) {
						a = false
					}
				}
				if (a) {
					this.checkAll = true
				}
			} else {
				this.rulesRoutes[ind].allChecked = false
				this.checkAll = false
			}
		}
	}
}
</script>

<style lang="less">
.add-btn-box {
	margin-bottom: 20px;
}

.jueseInfo {
	.ant-modal {
		width: 1000px !important;
	}

	.top-box {
		label {
			font-size: 16px;
			font-weight: bold;
		}
	}

	.li {
		line-height: 30px;
		padding: 0 10px;
		box-sizing: border-box;
		border-bottom: 1px solid #eee;

		.item-all {
			font-weight: bold;

			label {
				font-size: 16px;
			}
		}
	}
}
</style>
