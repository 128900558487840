<template>
	<div class="app-box">
		<div class="title-box">
			<div class="back" @click="back">
				<a-icon type="left" />
			</div>
			<div class="line"></div>
			<p class="title">设备编辑</p>
			<a-button @click="relationListOn" type="danger" size="big" style="margin-left: 78%;">查看最近上线设备</a-button>
		</div>
		<a-divider style="margin-left: -10px" />

		<a-form-model class="form-edit" :model="form" ref="form" :rules="rules" :labelCol="{ span: 2, offset: 2 }"
			:wrapperCol="{ span: 15 }" style="width: 1000px" :loding="loding">
			<a-form-model-item label="设备主ID" prop="main_sim">
				<div class="row-two-item">
					<a-input class="item" v-model="form.main_id" placeholder="id等待生成" disabled="true" />
					<div class="line-two"></div>
					<a-input class="item" v-model="form.main_sim" placeholder="请输入设备id" />
				</div>
			</a-form-model-item>
			<a-form-model-item label="从ID-1" prop="one_sim">
				<div class="row-two-item">
					<a-input class="item" v-model="form.one_id" placeholder="id等待生成" disabled="true" />
					<div class="line-two"></div>
					<a-input class="item" v-model="form.one_sim" placeholder="请输入设备id" />
				</div>
			</a-form-model-item>
			<a-form-model-item label="从ID-2" prop="two_sim">
				<div class="row-two-item">
					<a-input class="item" v-model="form.two_id" placeholder="id等待生成" disabled="true" />
					<div class="line-two"></div>
					<a-input class="item" v-model="form.two_sim" placeholder="请输入设备id" />
				</div>
			</a-form-model-item>
			<a-form-model-item label="生产日期">
				<a-date-picker v-model="form.birth_at_str" valueFormat="YYYY-MM-DD" placeholder="选择日期" />
			</a-form-model-item>
			<a-form-model-item label="出厂编号" prop="factory_no">
				<a-input v-model="form.factory_no" placeholder="字母加数字7位" />
			</a-form-model-item>
			<a-form-model-item label="检验员号">
				<a-input v-model="form.inspector_no" placeholder="请输入数字" />
			</a-form-model-item>
			<a-form-model-item label="对比值G" prop="contrast_g">
				<a-input-number v-model="form.contrast_g" placeholder="1-8位数字" />
			</a-form-model-item>
			<a-form-model-item label="对比值H" prop="contrast_h">
				<a-input-number v-model="form.contrast_h" placeholder="1-8位数字" />
			</a-form-model-item>
			<a-form-model-item label="对比值Z" prop="contrast_z">
				<a-input-number v-model="form.contrast_z" placeholder="1-8位数字" />
			</a-form-model-item>
			<a-form-model-item label="产品资质" prop="certificate" :wrapperCol="{ span: 19 }">
				<div class="row-two-item">
					<div class="upload-card" v-for="item in fileList" :key="item.key">
						<a-upload list-type="picture-card" :show-upload-list="false"
							:action="$domain + '/admin/v1/index/imgUpload'" :maxCount="1" @change="item.event">
							<img class="img-upload" v-if="form[item.key]" :src="form[item.key]" />
							<div v-else>
								<a-icon type="plus" />
								<div class="ant-upload-text">
									上传
								</div>
							</div>
						</a-upload>
						<div class="upload-text">{{ item.name }}</div>
					</div>
				</div>
			</a-form-model-item>
			<a-form-model-item label="备注">
				<a-textarea v-model="form.remark" :rows="5" />
			</a-form-model-item>
			<a-form-model-item label=" " :colon="false">
				<a-button @click="back">取消</a-button>
				<a-button type="primary" style="margin-left: 12px" @click="submitForm">
					提交
				</a-button>
			</a-form-model-item>
		</a-form-model>
		<!-- 关联抽屉 -->
		<a-drawer title="已联网设备" placement="right" width="600" :closable="false" :visible="relationvisible"
			@close="relationClose">
			<a-form-model class="form-right" :colon="false" layout="inline" :model="relationquery" labelAlign="left"
				@submit="relationHandleSearch" @submit.native.prevent>
				<a-row>
					<a-form-model-item style="float: right; margin-right: 0">
						<a-button type="primary" html-type="submit" size="small">
							查询
						</a-button>
						<a-button @click="relationReset" size="small" style="margin-left: 10px;">重置</a-button>
					</a-form-model-item>
					<a-form-model-item label="物联网号">
						<a-input v-model="relationquery.device_id" placeholder="物联网号" size="small" />
					</a-form-model-item>
					<a-form-model-item label="设备类型">
						<a-select v-model="relationquery.type" class="a-select" placeholder="请选择类型" @change="handleChange"
							style="margin-bottom: ;">
							<a-select-option :value="0">
								{{ '全部' }}
							</a-select-option>
							<a-select-option :value="1">
								{{ '主设备' }}
							</a-select-option>
							<a-select-option :value="2">
								{{ '从1设备' }}
							</a-select-option>
							<a-select-option :value="3">
								{{ '从2设备' }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="绑定类型">
						<a-select v-model="relationquery.is_bind" class="a-select" placeholder="请选择类型"
							@change="handleChange">
							<a-select-option :value="0">
								{{ '全部' }}
							</a-select-option>
							<a-select-option :value="1">
								{{ '已绑定' }}
							</a-select-option>
							<a-select-option :value="-1">
								{{ '未绑定' }}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-row>
			</a-form-model>
			<a-table class="main-table" :pagination="{
				current: relationquery.page,
				total: relationpagetotal
			}" @change="relationListChange" :columns="relationColumns" :data-source="relationData" rowKey="id"
				style="margin-top: 14px" v-viewer>
				<span slot="type" slot-scope="text">
					{{ text == 1 ? '主设备' : (text == 2 ? '从1设备' : '从2设备') }}
				</span>
				<span slot="is_bind" slot-scope="text">
					{{ text == 1 ? '已绑定' : '未绑定' }}
				</span>
			</a-table>
		</a-drawer>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'

export default {
	data() {
		return {
			relationvisible: false,
			relationquery: {
				page: 1,
				limit: 10
			},
			loding: false,
			relationpagetotal: 0,
			relationData: [],
			relationColumns: [{
				title: '设备类型',
				dataIndex: 'type',
				scopedSlots: {
					customRender: 'type'
				}
			},
			{
				title: '物联网号',
				dataIndex: 'device_id'
			},
			{
				title: '最近上线时间',
				dataIndex: 'updated_at_str'
			},
			{
				title: '是否绑定',
				dataIndex: 'is_bind',
				scopedSlots: {
					customRender: 'is_bind'
				}
			}
			],
			form: {},
			fileList: [{
				name: '产品合格证',
				key: 'certificate',
				event: this.changeCertificate
			}, {
				name: '产品检验报告',
				key: 'inspection_report',
				event: this.changeInspection
			}, {
				name: '产品标定证',
				key: 'calibration',
				event: this.changeCalibration
			}, {
				name: '产品产权归属',
				key: 'property_right',
				event: this.changeProperty
			}, {
				name: '产品历史记事',
				key: 'historical_note',
				event: this.changeHistorical
			}],
			rules: {
				main_sim: [{
					required: true,
					message: '请输入主设备id',
					trigger: 'change'
				},],
				one_sim: [{
					required: true,
					message: '请输入从1设备id',
					trigger: 'change'
				},],
				// two_sim: [{
				// 	required: true,
				// 	message: '请输入从2设备id',
				// 	trigger: 'change'
				// },],
				factory_no: [{
					required: true,
					message: '请输入出厂编号',
					trigger: 'change'
				},
				{
					min: 7,
					max: 7,
					message: '出产编号为7位',
					trigger: 'blur'
				}
				],
				contrast_h: [{
					required: true,
					message: '请输入对比值H',
					trigger: 'change'
				}],
				contrast_g: [{
					required: true,
					message: '请输入对比值G',
					trigger: 'change'
				}],
				contrast_z: [{
					required: true,
					message: '请输入对比值Z',
					trigger: 'change'
				}],
				certificate: [{
					required: true,
					message: '请上传产品合格证'
				},
				{
					validator: (rule, value, callback) => {
						if (!this.form.inspection_report) {
							callback('请上传产品检验报告')
						}
						if (!this.form.calibration) {
							callback('请上传产品标定证')
						}
						if (!this.form.property_right) {
							callback('请上传产品产权归属')
						}
						if (!this.form.historical_note) {
							callback('请上传产品历史记事')
						}
						callback()
					}
				}
				]
			}
		}
	},
	created() {
		if (this.$route.query.id > -1) {
			this.$post(domain + '/admin/v1/equipment/equipment/info', {
				equipment_id: this.$route.query.id
			}).then(res => {
				if (res.code === 0) {
					this.form = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		}
	},
	methods: {
		validator(key, callback) {
			if (this.form[key] && this.form[key].length > 0) {
				callback()
			} else {
				callback(new Error('请输入物联网号'))
			}
		},
		relationListOn() {
			let routeUrl = this.$router.resolve({
				path: '/connectList'// 这里的路径就可以正常的写，不需要添加_blank: true
			})
			window.open(routeUrl.href, '_blank')
		},
		relationClose() {
			this.relationvisible = false;
		},
		handleChange() {
			this.relationquery.page = 1
			this.relationList()
		},
		relationHandleSearch() {
			this.relationList()
		},
		relationList() {
			this.$post(domain + '/admin/v1/equipment/equipment/connectList', this.relationquery).then(res => {
				if (res.code === 0) {
					this.relationData = res.data.list
					console.log('我是不是空数组', this.relationData)
					this.relationpagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		relationReset() {
			this.relationquery = {
				page: 1,
				limit: this.relationquery.limit,
			}
			this.relationList()
		},
		relationListChange(e) {
			this.relationquery.page = e.current
			this.relationList();
		},
		submitForm() {
			this.loding = true
			console.log(this.form)
			let type
			this.$refs.form.validate(valid => {
				if (valid) {
					if (this.$route.query.id > -1) {
						type = 'edit'
					} else {
						type = 'add'
					}
					this.$post(domain + '/admin/v1/equipment/equipment/' + type, this.form).then(res => {
						if (res.code === 0) {
							this.loding = false
							this.$message.success('保存成功')
							this.$router.back()
						} else {
							this.loding = false
							this.$message.error(res.msg)
						}
					})
				}
				this.loding = false
			})
		},
		back() {
			this.$router.back()
		},
		changeCertificate(res) {
			this.changeFile(res, 'certificate')
		},
		changeInspection(res) {
			this.changeFile(res, 'inspection_report')
		},
		changeCalibration(res) {
			this.changeFile(res, 'calibration')
		},
		changeProperty(res) {
			this.changeFile(res, 'property_right')
		},
		changeHistorical(res) {
			this.changeFile(res, 'historical_note')
		},
		changeFile(res, key) {
			if (res.file.status == "done") {
				const response = res.file.response
				if (response && response.code === 0) {
					this.form[key] = response.data.really_path;
					this.form = {
						...this.form
					}
					this.$nextTick(() => {
						this.$refs.form.validateField('certificate')
					})
				} else {
					this.$message.error(response.msg)
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.row-two-item {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.item {
		width: 277px;
	}

	.line-two {
		height: 1px;
		background-color: #979797;
		width: 38px;
	}
}

.upload-card {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.img-upload {
	width: 86px;
	height: 86px;
}
</style>
<style lang="less">
.form-edit {
	.upload-card {
		.ant-upload.ant-upload-select-picture-card {
			margin-right: 0;
			margin-bottom: 0;
		}
	}
}
</style>
