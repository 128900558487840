var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-box"},[_vm._m(0),_c('a-divider',{staticStyle:{"margin-left":"-10px"}}),_c('a-form-model',{staticClass:"form-search",attrs:{"colon":false,"layout":"inline","model":_vm.query,"labelAlign":"left"},on:{"submit":_vm.handleSearch},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('a-row',[_c('a-form-model-item',{attrs:{"label":"地区"}},[_c('a-cascader',{staticStyle:{"min-width":"300px"},attrs:{"field-names":{
            label: 'name',
            value: 'name',
            children: 'districts',
          },"load-data":_vm.loadSsq,"change-on-select":"","options":_vm.ssqData,"placeholder":"请选择省市区"},on:{"change":_vm.ssqChange},model:{value:(_vm.addrValue),callback:function ($$v) {_vm.addrValue=$$v},expression:"addrValue"}})],1),_c('a-form-model-item',{attrs:{"label":"企业名称"}},[_c('a-input',{attrs:{"placeholder":"输入企业名称"},model:{value:(_vm.query.company_name),callback:function ($$v) {_vm.$set(_vm.query, "company_name", $$v)},expression:"query.company_name"}})],1),_c('a-form-model-item',{attrs:{"label":"经办人"}},[_c('a-input',{attrs:{"placeholder":"输入经办人"},model:{value:(_vm.query.manager),callback:function ($$v) {_vm.$set(_vm.query, "manager", $$v)},expression:"query.manager"}})],1),_c('a-form-model-item',{attrs:{"label":"设备ID"}},[_c('a-input',{attrs:{"placeholder":"输入设备ID"},model:{value:(_vm.query.main_id),callback:function ($$v) {_vm.$set(_vm.query, "main_id", $$v)},expression:"query.main_id"}})],1),_c('a-form-model-item',{staticStyle:{"float":"right","margin-right":"0"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 查询 ")]),_c('a-button',{on:{"click":_vm.Reset}},[_vm._v("重置")])],1)],1),_c('a-row',{staticStyle:{"margin-top":"20px"}},[_c('a-form-model-item',{attrs:{"label":"出厂编号"}},[_c('a-input',{attrs:{"placeholder":"输入出场编号"},model:{value:(_vm.query.factory_no),callback:function ($$v) {_vm.$set(_vm.query, "factory_no", $$v)},expression:"query.factory_no"}})],1),_c('a-form-model-item',{attrs:{"label":"物联网号"}},[_c('a-input',{attrs:{"placeholder":"输入物联网号"},model:{value:(_vm.query.main_sim),callback:function ($$v) {_vm.$set(_vm.query, "main_sim", $$v)},expression:"query.main_sim"}})],1)],1)],1),_c('a-table',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"main-table",staticStyle:{"margin-top":"14px"},attrs:{"pagination":{
      current: _vm.query.page,
      total: _vm.pagetotal,
    },"columns":_vm.columns,"data-source":_vm.data,"rowKey":"id"},on:{"change":_vm.listChange},scopedSlots:_vm._u([{key:"main_id",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(text)+" "),_c('br'),(record.main_status === 1 || record.main_status === 2)?_c('a-tag',{attrs:{"color":record.main_status === 1 || record.main_status === 2
            ? '#74CE78'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.main_status === 1 || record.main_status === 2 ? '在线' : '离线')+" ")]):_vm._e(),_c('a-tag',{attrs:{"color":record.main_status === 1
            ? '#74CE78'
            : record.main_status === 2
            ? '#757776'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.main_status === 1 ? '开机' : record.main_status === 2 ? '关机' : '离线')+" ")])],1)}},{key:"one_id",fn:function(text, record){return _c('span',{},[_vm._v(" "+_vm._s(text)+" "),_c('br'),(record.one_status === 1 || record.one_status === 2)?_c('a-tag',{attrs:{"color":record.one_status === 1 || record.one_status === 2
            ? '#74CE78'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.one_status === 1 || record.one_status === 2 ? '在线' : '离线')+" ")]):_vm._e(),_c('a-tag',{attrs:{"color":record.one_status === 1
            ? '#74CE78'
            : record.one_status === 2
            ? '#757776'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.one_status === 1 ? '开机' : record.one_status === 2 ? '关机' : '离线')+" ")])],1)}},{key:"two_id",fn:function(text, record){return (record.two_sim)?_c('span',{},[_vm._v(" "+_vm._s(text)+" "),_c('br'),(record.two_status === 1 || record.two_status === 2)?_c('a-tag',{attrs:{"color":record.two_status === 1 || record.two_status === 2
            ? '#74CE78'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.two_status === 1 || record.two_status === 2 ? '在线' : '离线')+" ")]):_vm._e(),_c('a-tag',{attrs:{"color":record.two_status === 1
            ? '#74CE78'
            : record.two_status === 2
            ? '#757776'
            : '#FF0000'}},[_vm._v(" "+_vm._s(record.two_status === 1 ? '开机' : record.two_status === 2 ? '关机' : '离线')+" ")])],1):_vm._e()}},{key:"is_on",fn:function(text, record){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.equipmentOn(record.equipment_id, 1)}}},[_vm._v("开启")]),_vm._v("  "),_c('a',{staticStyle:{"color":"#e0352b"},on:{"click":function($event){return _vm.equipmentOn(record.equipment_id, -1)}}},[_vm._v("关机")])])}},{key:"qr_code",fn:function(text){return _c('span',{},[_c('img',{staticClass:"img-qr",attrs:{"src":text}})])}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a-space',[_c('a',{on:{"click":function($event){return _vm.toEdit(record.equipment_id, record.customer_id)}}},[_vm._v("编辑")]),_c('a',{on:{"click":function($event){return _vm.toChangeList(record.equipment_id)}}},[_vm._v("变更")]),_c('a',{on:{"click":function($event){return _vm.toContrastList(record.equipment_id)}}},[_vm._v("对比")]),_c('a',{staticStyle:{"color":"#e0352b"},on:{"click":function($event){return _vm.reback(record.equipment_id)}}},[_vm._v("归还")])])],1)}}],null,true)})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-box"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("租赁用户列表")])])}]

export { render, staticRenderFns }