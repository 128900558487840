<template>
	<div class="app-box">
		<div class="title-box">
			<div class="back" @click="back">
				<a-icon type="left" />
			</div>
			<div class="line"></div>
			<p class="title">资质编辑</p>
		</div>
		<a-divider style="margin-left: -10px" />

		<a-form-model class="form-edit" :model="form" ref="form" :rules="rules" :labelCol="{ span: 2, offset: 2 }"
			:wrapperCol="{ span: 15 }" style="width: 1000px">

			<a-form-model-item label="名称" prop="name">
				<a-input v-model="form.name" placeholder="名称" />
			</a-form-model-item>
			<a-form-model-item label="资质类型" ref prop="type">
				<a-tag :color="form.type === 1 ? '#74CE78' : '#757776'" @click="showChange(1)">
					{{ '视频'  }}
				</a-tag>
				<a-tag type="link" @click="showChange(2)" :color="form.type === 2 ? '#74CE78' : '#757776'">
					{{ '图片' }}
				</a-tag>
			</a-form-model-item>
			<a-form-model-item v-if="form.type==1" label="资质" prop="material" :wrapperCol="{ span: 19 }">
				<a-upload :action="$domain + '/admin/v1/index/videoUpload'" list-type="picture-card"
					@change="handleHead" :show-upload-list="false">
					<img class="place-img"
						:src="form.material+'?x-oss-process=video/snapshot,t_5000,f_png,w_0,h_0,m_fast'"
						v-if="form.material" />
					<div v-else>
      			<a-icon :type="uploading ? 'loading' : 'plus'" />
						<div class="ant-upload-text">上传视频不超过200M</div>
					</div>
				</a-upload>
			</a-form-model-item>
			<a-form-model-item v-else label="资质" prop="material" :wrapperCol="{ span: 19 }">
				<a-upload :action="$domain + '/admin/v1/index/imgUpload'" list-type="picture-card" @change="handleHead"
					:show-upload-list="false">
					<img class="place-img" :src="form.material" v-if="form.material" />
					<div v-else>
						<a-icon type="plus" />
						<div class="ant-upload-text">请上传图片</div>
					</div>
				</a-upload>
			</a-form-model-item>
			<a-form-model-item label=" " :colon="false">
				<a-button @click="cancel">取消</a-button>
				<a-button type="primary" style="margin-left: 12px" @click="submitForm">
					提交
				</a-button>
			</a-form-model-item>
		</a-form-model>
	</div>
</template>

<script>
	import {
		domain
	} from '@/siteInfo.js'
	import {
		message
	} from 'ant-design-vue'
	export default {
		data() {
			return {
      	uploading: false,
				material_id: '',
				form: {
					name: '',
					material: '',
					type: 1,
				},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					material: [{
						required: true,
						message: '请上传视频',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '请选择资质类型',
						trigger: 'change'
					}],
				}
			}
		},
		async created() {
			this.material_id = this.$route.query.id;
			if (this.$route.query.id != -1) {
				this.$post(domain + '/admin/v1/company/qualification/info', {
					material_id: this.material_id
				}).then(res => {
					if (res.code === 0) {
						this.form.material = res.data.material
						this.form.name = res.data.name
						this.form.type = res.data.type
					} else {
						this.$message.error(res.msg)
					}
				})
			} else {
				console.log('id', this.$route.query.id)
			}
		},
		methods: {
			cancel() {
				console.log('点击取消')
				this.back()
			},
			validator(key, callback) {
				if (this.form[key] && this.form[key].length > 0) {
					callback()
				} else {
					callback(new Error('请输入物联网号'))
				}
			},
			showChange(type) {
				if (type !== this.form.type) {
					console.log('点击', type)
					this.form.material = ''
					this.form.type = type
				}
			},
			submitForm() {
				let action = '';
				console.log('业务id', this.material_id)
				if (this.material_id != -1) {
					action = 'edit'
					this.form.material_id = this.material_id
				} else {
					action = 'add'
				}
				console.log(this.form)
				this.$refs.form.validate(valid => {
					if (valid) {
						this.$post(domain + '/admin/v1/company/qualification/' + action, this.form).then(res => {
							if (res.code === 0) {
								this.$message.success('操作成功', 1.5)
								this.$router.back()
							} else {
								this.$message.error(res.msg, 1.5)
							}
						})
					}
					console.log('substatus', valid)
				})
			},
			back() {
				this.$router.back()
			},
			handleHead(res) {
				console.log('视频改变', res);
				this.uploading = true
				if (res.file.status == "done") {
					console.log('code', res.file.response.code)
					this.uploading = false
					if (res.file.response.code != 0) {
						this.$message.error(res.file.response.msg)
					} else {
						this.form.material = res.file.response.data.really_path;
					}
					console.log('132', this.form.material);
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.row-two-item {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.item {
			width: 277px;
		}

		.line-two {
			height: 1px;
			background-color: #979797;
			width: 38px;
		}
	}

	.upload-card {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.img-upload {
		width: 86px;
		height: 86px;
	}
</style>
<style lang="less">
	.form-edit {
		.upload-card {
			.ant-upload.ant-upload-select-picture-card {
				margin-right: 0;
				margin-bottom: 0;
			}
		}
	}
</style>
