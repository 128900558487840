<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">设备列表</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
      @submit="handleSearch" @submit.native.prevent>
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item label="设备ID">
          <a-input v-model="query.main_id" placeholder="输入设备ID" />
        </a-form-model-item>
        <a-form-model-item label="出厂编号">
          <a-input v-model="query.factory_no" placeholder="出厂编号" />
        </a-form-model-item>
        <a-form-model-item label="物联网号">
          <a-input v-model="query.user" placeholder="物联网号" />
        </a-form-model-item>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-form-model-item label="设备状态">
          <a-checkbox-group v-model="checkValue" name="checkboxgroup" :options="checkOptions" @change="changeCheck" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="toDivide()">分配客户</a-button>
      <a-button type="primary" style="margin-left: 10px" @click="toEdit(-1)">新增设备</a-button>
    </a-row>
    <a-table class="main-table" :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }" :pagination="{
  current: query.page,
  total: pagetotal,
}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
      v-viewer>
      <span slot="main_id" slot-scope="text, record">
        {{ text }}
        <br />
        <a-tag :color="record.main_status === 1 || record.main_status === 2
            ? '#74CE78'
            : '#FF0000'
          " v-if="record.main_status === 1 || record.main_status === 2">
          {{
            record.main_status === 1 || record.main_status === 2
            ? '在线'
            : '离线'
          }}
        </a-tag>
        <a-tag :color="record.main_status === 1
              ? '#74CE78'
              : record.main_status === 2
                ? '#757776'
                : '#FF0000'
            ">
          {{
            record.main_status === 1
            ? '开机'
            : record.main_status === 2
              ? '关机'
              : '离线'
          }}
        </a-tag>
      </span>
      <span slot="one_id" slot-scope="text, record">
        {{ text }}
        <br />
        <a-tag :color="record.one_status === 1 || record.one_status === 2
            ? '#74CE78'
            : '#FF0000'
          " v-if="record.one_status === 1 || record.one_status === 2">
          {{
            record.one_status === 1 || record.one_status === 2 ? '在线' : '离线'
          }}
        </a-tag>
        <a-tag :color="record.one_status === 1
            ? '#74CE78'
            : record.one_status === 2
              ? '#757776'
              : '#FF0000'
          ">
          {{
            record.one_status === 1
            ? '开机'
            : record.one_status === 2
              ? '关机'
              : '离线'
          }}
        </a-tag>
      </span>
      <span slot="two_id" slot-scope="text, record" v-if="record.two_sim">
        {{ text }}
        <br />
        <a-tag :color="record.two_status === 1 || record.two_status === 2
            ? '#74CE78'
            : '#FF0000'
          " v-if="record.two_status === 1 || record.two_status === 2">
          {{
            record.two_status === 1 || record.two_status === 2 ? '在线' : '离线'
          }}
        </a-tag>
        <a-tag :color="record.two_status === 1
            ? '#74CE78'
            : record.two_status === 2
              ? '#757776'
              : '#FF0000'
          ">
          {{
            record.two_status === 1
            ? '开机'
            : record.two_status === 2
              ? '关机'
              : '离线'
          }}
        </a-tag>
      </span>
      <span slot="warn_type" slot-scope="text, record">
        <a-tag :color="text === 1 ? '#74CE78' : '#757776'">
          {{ text === 1 ? '正常' : '屏蔽' }}
        </a-tag>
        <a-button type="link" @click="equipmentWarn(record.equipment_id)">
          {{ text === 1 ? '屏蔽' : '正常' }}
        </a-button>
      </span>

      <span slot="is_on" slot-scope="text, record">
        <a @click="equipmentOn(record.equipment_id, 1)">开启</a>&nbsp;
        <a style="color: #e0352b" @click="equipmentOn(record.equipment_id, -1)">关机</a>
      </span>
      <span slot="qr_code" slot-scope="text">
        <img :src="text" class="img-qr" v-if="text" />
        <div v-else alt="" class="img-qr_code" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="toEdit(record.equipment_id)">编辑</a>
          <a @click="toChangeList(record.equipment_id)">变更</a>
          <a @click="toContrastList(record.equipment_id)">对比</a>
          <a style="color: #e0352b" @click="del(record.equipment_id)">删除</a>
        </a-space>
      </span>
    </a-table>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
        order: 'asc',
      },
      customer_id: null,
      pagetotal: 0,
      checkValue: [],
      checkOptions: [
        {
          label: '开启',
          value: 1,
        },
        {
          label: '关闭',
          value: -1,
        },
      ],
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '主ID',
          dataIndex: 'main_id',
          scopedSlots: {
            customRender: 'main_id',
          },
        },
        {
          title: '出厂编号',
          dataIndex: 'factory_no',
        },
        {
          title: '从ID-1',
          dataIndex: 'one_id',
          scopedSlots: {
            customRender: 'one_id',
          },
        },
        {
          title: '从ID-2',
          dataIndex: 'two_id',
          scopedSlots: {
            customRender: 'two_id',
          },
        },
        {
          title: '对比值G',
          dataIndex: 'contrast_g',
        },
        {
          title: '对比值H',
          dataIndex: 'contrast_h',
        },
        {
          title: '对比值Z',
          dataIndex: 'contrast_z',
        },
        {
          title: '屏蔽状态',
          dataIndex: 'warn_type',
          scopedSlots: {
            customRender: 'warn_type',
          },
        },
        {
          title: '状态',
          dataIndex: 'is_on',
          scopedSlots: {
            customRender: 'is_on',
          },
        },
        {
          title: '二维码',
          dataIndex: 'qr_code',
          scopedSlots: {
            customRender: 'qr_code',
          },
        },
        {
          title: '更新时间',
          dataIndex: 'create_at_str',
          sorter: true,
        },
        {
          title: '创建人',
          dataIndex: 'do_admin_name',
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      data: [],
      selectedRowKeys: [],
    }
  },
  created() {
    this.customer_id = this.$route.query.cid
    this.getList()
  },
  methods: {
    del(id) {
      console.log(id)
      this.$confirm({
        title: '确认',
        content: '确认删除该设备吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/equipment/equipment/delete', {
            equipment_id: id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => { },
      })
    },
    Reset() {
      this.customer_id = null
      this.query = {
        page: 1,
        limit: this.query.limit,
      }
      this.getList()
    },
    equipmentOn(id, type) {
      this.$post(domain + '/admin/v1/equipment/equipment/on', {
        equipment_id: id,
        type: type,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    equipmentWarn(id) {
      this.$post(domain + '/admin/v1/equipment/equipment/warn', {
        equipment_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toEdit(id) {
      this.$router.push({
        path: '/deviceEdit',
        query: {
          id: id,
        },
      })
    },
    toDivide() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.error('请选中设备进行分配')
      } else {
        this.$router.push({
          path: '/customerListDivide',
          query: {
            ids: this.selectedRowKeys.join(','),
          },
        })
      }
    },
    toChangeList(id) {
      this.$router.push({
        path: '/changeList',
        query: {
          id: id,
        },
      })
    },
    toContrastList(id) {
      this.$router.push({
        path: '/contrastList',
        query: {
          id: id,
        },
      })
    },
    getList() {
      console.log('这是啥玩意看一下', this.selectedRowKeys)
      let cusSerch = {}
      if (this.customer_id == null) {
        cusSerch = this.query
      } else {
        cusSerch = {
          customer_id: this.customer_id,
          page: this.query.page,
          limit: this.query.limit,
          order: this.query.order || 'asc',
        }
      }
      this.$post(domain + '/admin/v1/equipment/equipment/list', cusSerch).then(
        (res) => {
          if (res.code === 0) {
            this.data = res.data.list
            this.pagetotal = res.data.total
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    listChange(e, filters, sorter) {
      this.query.page = e.current
      if (sorter.order == 'descend') {
        this.query.order = 'desc'
      } else {
        this.query.order = 'asc'
      }
      this.getList()
    },
    handleSearch(query) {
      this.customer_id = null
      this.getList()
      console.log(query)
    },
    changeCheck(value) {
      if (value.length === 1) {
        this.query.is_on = value[0]
      } else {
        this.query.is_on = null
      }
      this.getList()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}

.img-qr_code {
  width: 30px;
  height: 30px;
  background-image: url('../../assets/qr_code.png');
  background-size: 100%;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
