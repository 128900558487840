<template>
  <div class="app-box">
    <div class="title-box">
      <div class="back" @click="back">
        <a-icon type="left" />
      </div>
      <div class="line"></div>
      <p class="title">{{ map_id == -1 ? '新建' : '编辑' }}地图</p>
    </div>
    <a-divider style="margin-left: -10px" />

    <a-form-model
      class="form-edit"
      :model="form"
      ref="form"
      :rules="rules"
      :labelCol="{ span: 2, offset: 2 }"
      :wrapperCol="{ span: 15 }"
      style="width: 1000px"
    >
      <a-form-model-item label="地图ID" prop="map_id">
        {{ form.map_id }}</a-form-model-item
      >
      <a-form-model-item label="地图名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入地图名称" />
      </a-form-model-item>
      <a-form-model-item
        label="备注说明"
        prop="content
"
      >
        <a-textarea
          v-model="form.content"
          placeholder="请输入备注说明"
          :autosize="{ minRows: 6, maxRows: 20 }"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label=" " :colon="false" style="margin-top: 100px">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" style="margin-left: 12px" @click="submitForm">
          保存
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import axios from 'axios'
import { domain } from '@/siteInfo.js'
import { message } from 'ant-design-vue'

export default {
  data() {
    return {
      map_id: '',
      form: {
        name: '',
        item_id: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入登录手机号',
            trigger: 'change',
          },
        ],
        contact: [
          {
            required: true,
            message: '请输入联系人',
            trigger: 'change',
          },
        ],
      },
    }
  },
  async created() {
    console.log('创建被执行了')
    // this.godList()
    this.map_id = this.$route.query.id
    this.form.item_id = this.$route.query.itemId
    if (this.$route.query.id != -1) {
      this.$post(domain + '/admin/v1/map/info', {
        map_id: this.map_id,
      }).then((res) => {
        if (res.code === 0) {
          this.form = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    } else {
      console.log('id', this.$route.query.id)
    }
  },
  methods: {
    godList() {
      // 得到政府列表
      this.$post(domain + '/admin/v1/gov/list', { page: 1, limit: 9999 }).then(
        (res) => {
          if (res.code === 0) {
            this.dataSource = res.data.list.map((item) => item.name)
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    submitForm() {
      let action = ''
      console.log('业务id', this.material_id)
      if (this.map_id != -1) {
        action = 'update'
        this.form.map_id = this.map_id
      } else {
        action = 'add'
      }
      console.log(this.form)
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$post(domain + '/admin/v1/map/' + action, this.form).then(
            (res) => {
              if (res.code === 0) {
                this.$message.success('操作成功', 1.5)
                this.$router.back()
              } else {
                this.$message.error(res.msg, 1.5)
              }
            }
          )
        }
        console.log('substatus', valid)
      })
    },
    cancel() {
      console.log('点击取消')
      this.back()
    },
    back() {
      this.$router.back()
    },
  },
}
</script>

<style lang="less" scoped>
.row-two-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item {
    width: 277px;
  }

  .line-two {
    height: 1px;
    background-color: #979797;
    width: 38px;
  }
}

.upload-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-upload {
  width: 86px;
  height: 86px;
}
</style>
<style lang="less">
.form-edit {
  .upload-card {
    .ant-upload.ant-upload-select-picture-card {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}
</style>