<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">租赁用户列表</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model
      class="form-search"
      :colon="false"
      layout="inline"
      :model="query"
      labelAlign="left"
      @submit="handleSearch"
      @submit.native.prevent
    >
      <a-row>
        <a-form-model-item label="地区">
          <a-cascader
            :field-names="{
              label: 'name',
              value: 'name',
              children: 'districts',
            }"
            v-model="addrValue"
            :load-data="loadSsq"
            change-on-select
            :options="ssqData"
            placeholder="请选择省市区"
            style="min-width: 300px"
            @change="ssqChange"
          />
        </a-form-model-item>
        <a-form-model-item label="企业名称">
          <a-input v-model="query.company_name" placeholder="输入企业名称" />
        </a-form-model-item>
        <a-form-model-item label="经办人">
          <a-input v-model="query.manager" placeholder="输入经办人" />
        </a-form-model-item>
        <a-form-model-item label="设备ID">
          <a-input v-model="query.main_id" placeholder="输入设备ID" />
        </a-form-model-item>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-form-model-item label="出厂编号">
          <a-input v-model="query.factory_no" placeholder="输入出场编号" />
        </a-form-model-item>
        <a-form-model-item label="物联网号">
          <a-input v-model="query.main_sim" placeholder="输入物联网号" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-table
      class="main-table"
      :pagination="{
        current: query.page,
        total: pagetotal,
      }"
      @change="listChange"
      :columns="columns"
      :data-source="data"
      rowKey="id"
      style="margin-top: 14px"
      v-viewer
    >
      <span slot="main_id" slot-scope="text, record">
        {{ text }}
        <br />
        <a-tag
          :color="
            record.main_status === 1 || record.main_status === 2
              ? '#74CE78'
              : '#FF0000'
          "
          v-if="record.main_status === 1 || record.main_status === 2"
        >
          {{
            record.main_status === 1 || record.main_status === 2
              ? '在线'
              : '离线'
          }}
        </a-tag>
        <a-tag
          :color="
            record.main_status === 1
              ? '#74CE78'
              : record.main_status === 2
              ? '#757776'
              : '#FF0000'
          "
        >
          {{
            record.main_status === 1
              ? '开机'
              : record.main_status === 2
              ? '关机'
              : '离线'
          }}
        </a-tag>
      </span>
      <span slot="one_id" slot-scope="text, record">
        {{ text }}
        <br />
        <a-tag
          :color="
            record.one_status === 1 || record.one_status === 2
              ? '#74CE78'
              : '#FF0000'
          "
          v-if="record.one_status === 1 || record.one_status === 2"
        >
          {{
            record.one_status === 1 || record.one_status === 2 ? '在线' : '离线'
          }}
        </a-tag>
        <a-tag
          :color="
            record.one_status === 1
              ? '#74CE78'
              : record.one_status === 2
              ? '#757776'
              : '#FF0000'
          "
        >
          {{
            record.one_status === 1
              ? '开机'
              : record.one_status === 2
              ? '关机'
              : '离线'
          }}
        </a-tag>
      </span>
      <span slot="two_id" slot-scope="text, record" v-if="record.two_sim">
        {{ text }}
        <br />
        <a-tag
          :color="
            record.two_status === 1 || record.two_status === 2
              ? '#74CE78'
              : '#FF0000'
          "
          v-if="record.two_status === 1 || record.two_status === 2"
        >
          {{
            record.two_status === 1 || record.two_status === 2 ? '在线' : '离线'
          }}
        </a-tag>
        <a-tag
          :color="
            record.two_status === 1
              ? '#74CE78'
              : record.two_status === 2
              ? '#757776'
              : '#FF0000'
          "
        >
          {{
            record.two_status === 1
              ? '开机'
              : record.two_status === 2
              ? '关机'
              : '离线'
          }}
        </a-tag>
      </span>
      <span slot="is_on" slot-scope="text, record">
        <a @click="equipmentOn(record.equipment_id, 1)">开启</a>&nbsp;
        <a style="color: #e0352b" @click="equipmentOn(record.equipment_id, -1)"
          >关机</a
        >
      </span>
      <span slot="qr_code" slot-scope="text">
        <img :src="text" class="img-qr" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="toEdit(record.equipment_id, record.customer_id)">编辑</a>
          <a @click="toChangeList(record.equipment_id)">变更</a>
          <a @click="toContrastList(record.equipment_id)">对比</a>
          <a style="color: #e0352b" @click="reback(record.equipment_id)"
            >归还</a
          >
        </a-space>
      </span>
    </a-table>
  </div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import axios from 'axios'
import { ssqdata } from '@/utils/ssqdata'

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10,
      },
      pagetotal: 0,
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '企业名称',
          dataIndex: 'company_name',
        },
        {
          title: '经办人',
          dataIndex: 'manager',
        },
        {
          title: '地区',
          customRender: (text, record, index) =>
            `${record.pro}-${record.city}-${record.area}`,
        },
        {
          title: '主ID',
          dataIndex: 'main_id',
          scopedSlots: {
            customRender: 'main_id',
          },
        },
        {
          title: '出厂编号',
          dataIndex: 'factory_no',
        },
        {
          title: '从ID-1',
          dataIndex: 'one_id',
          scopedSlots: {
            customRender: 'one_id',
          },
        },
        {
          title: '从ID-2',
          dataIndex: 'two_id',
          scopedSlots: {
            customRender: 'two_id',
          },
        },
        {
          title: '状态',
          dataIndex: 'is_on',
          scopedSlots: {
            customRender: 'is_on',
          },
        },
        {
          title: '二维码',
          dataIndex: 'qr_code',
          scopedSlots: {
            customRender: 'qr_code',
          },
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      data: [],
      ssqData: ssqdata,
      addrValue: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    reback(id) {
      console.log(id)
      this.$confirm({
        title: '确认',
        content: '确认归还该设备吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/equipment/lease/return', {
            equipment_id: id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {},
      })
    },
    equipmentOn(id, type) {
      this.$post(domain + '/admin/v1/equipment/equipment/on', {
        equipment_id: id,
        type: type,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toEdit(eid, cid) {
      this.$router.push({
        path: '/eqCusEdit',
        query: {
          eid: eid,
          cid: cid,
        },
      })
    },
    toChangeList(id) {
      this.$router.push({
        path: '/changeList',
        query: {
          id: id,
        },
      })
    },
    toContrastList(id) {
      this.$router.push({
        path: '/contrastList',
        query: {
          id: id,
        },
      })
    },
    getList() {
      this.$post(domain + '/admin/v1/equipment/lease/list', this.query).then(
        (res) => {
          if (res.code === 0) {
            this.data = res.data.list
            this.pagetotal = res.data.total
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    listChange(e) {
      this.query.page = e.current
      this.getList()
    },
    handleSearch(query) {
      this.query.page = 1
      this.getList()
    },
    changeCheck(value) {
      console.log('value', value)
    },
    Reset() {
      this.addrValue = []
      this.query = {
        page: this.query.page,
        limit: this.query.limit,
      }
      this.getList()
    },
    ssqChange(e, opt) {
      // this.query.pro = e[0] || "";
      // this.query.city = e[1] || "";
      // this.query.area = e[2] || "";
      this.query.pro_code = opt && opt[0] ? opt[0].adcode : ''
      this.query.city_code = opt && opt[1] ? opt[1].adcode : ''
      this.query.area_code = opt && opt[2] ? opt[2].adcode : ''
    },
    loadSsq(selectedOptions) {
      console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // load options lazily
      let subdistrict =
        targetOption.level == 'province'
          ? 1
          : targetOption.level == 'city'
          ? 2
          : targetOption.level == 'district'
          ? 3
          : 4
      axios
        .get('https://restapi.amap.com/v3/config/district', {
          params: {
            keywords: targetOption.adcode,
            subdistrict: subdistrict,
            key: 'ce7404ac71c28a7893cae3ccc5517da0',
          },
        })
        .then((res) => {
          let districts = res.data.districts[0].districts
          for (let i = 0; i < districts.length; i++) {
            let item = districts[i]
            delete item.districts
            item.isLeaf =
              item.level == 'district' ||
              res.data.districts[0].adcode == 810000 ||
              res.data.districts[0].adcode == 820000
          }
          targetOption.districts = districts
          targetOption.loading = false
          this.ssqData = [...this.ssqData]
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
