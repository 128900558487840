<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">账号列表</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model
      class="form-search"
      :colon="false"
      layout="inline"
      :model="query"
      labelAlign="left"
      @submit="handleSearch"
      @submit.native.prevent
    >
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item label="地区">
          <a-cascader
            :field-names="{
              label: 'name',
              value: 'name',
              children: 'districts',
            }"
            v-model="addrValue"
            style="min-width: 300px"
            :load-data="loadSsq"
            change-on-select
            :options="ssqData"
            placeholder="请选择省市区"
            @change="ssqChange"
          />
        </a-form-model-item>
        <a-form-model-item label="区域名称">
          <a-input v-model="query.name" placeholder="请输入区域名称" />
        </a-form-model-item>
        <a-form-model-item label="账号">
          <a-input v-model="query.phone" placeholder="请输入账号" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="toEdit(-1)">新增</a-button>
    </a-row>
    <a-table
      class="main-table"
      :pagination="{
        current: query.page,
        total: query.total,
      }"
      @change="listChange"
      :columns="columns"
      :data-source="data"
      rowKey="id"
      style="margin-top: 14px"
      v-viewer
    >
      <span slot="type" slot-scope="text, record">
        <a @click="showVisible(record.gov_id)">工地配置</a>
      </span>
      <span slot="equipment_count" slot-scope="text, record">
        <a @click="toEquipmentList(record.gov_id)">{{ text }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="toEdit(record.gov_id)">编辑</a>
          <a style="color: #e0352b" @click="del(record.gov_id)">删除</a>
          <a style="color: #127416" @click="showChangePassword(record.gov_id)"
            >修改密码</a
          >
        </a-space>
      </span>
    </a-table>
    <a-drawer
      title="工地配置"
      placement="right"
      :closable="true"
      :width="600"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
    >
      <a-button
        type="primary"
        @click="showChildrenDrawer"
        style="margin-bottom: 24px"
      >
        添加工地
      </a-button>

      <a-table :data-source="dataInfo" :columns="columns1">
        <span
          slot="action1"
          slot-scope="text, record"
          style="color: #e0352b; cursor: pointer"
          @click="delAction(record)"
          >移除</span
        >
      </a-table>
      <a-drawer
        title="添加工地"
        width="500"
        :closable="true"
        :visible="childrenDrawer"
        @close="onChildrenDrawerClose"
      >
        <a-form-model layout="inline">
          <a-form-model-item label="工地名称">
            <a-input v-model="query1.company_name" placeholder="请输入操作人" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="siteSearch"> 查询 </a-button>
          </a-form-model-item>
        </a-form-model>
        <a-table
          :data-source="listData"
          :columns="columns2"
          :pagination="{
            current: query1.page,
            total: query1.total,
          }"
          @change="listChange1"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
        </a-table>
        <div
          :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
        >
          <a-button style="margin-right: 20px" @click="onChildrenDrawerClose">
            取消
          </a-button>
          <a-button type="primary" @click="onChildrenDrawerSave">
            保存
          </a-button>
        </div>
      </a-drawer>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="margin-right: 20px" @click="onClose"> 取消 </a-button>
        <a-button type="primary" @click="onDraSave"> 保存 </a-button>
      </div>
    </a-drawer>
    <a-modal
      title="修改密码"
      :visible="showPwdFlag"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        class="form-search"
        :colon="false"
        layout="inline"
        :model="modleQuery"
        labelAlign="left"
        :rules="modleRules"
      >
        <a-row>
          <a-form-model-item label="密码" prop="password">
            <a-input-password
              v-model="modleQuery.password"
              placeholder="请输入新密码"
            />
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { domain } from '@/siteInfo'
import axios from 'axios'
import { ssqdata } from '@/utils/ssqdata'

export default {
  data() {
    return {
      visible: false,
      childrenDrawer: false,
      query: {
        page: 1,
        limit: 10,
        total: 0,
      },
      modleQuery: {
        password: '',
      },
      modleRules: {
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'change',
          },
        ],
      },
      selectedRowKeys: [],

      query1: {
        page: 1,
        limit: 10,
        total: 0,
        company_name: '',
      },
      columns1: [
        {
          title: '工地名称',
          dataIndex: 'company_name',
        },
        // {
        //   title: '手机号',
        //   dataIndex: 'phone',
        // },
        {
          title: '操作',
          dataIndex: 'action1',
          scopedSlots: {
            customRender: 'action1',
          },
        },
      ],
      columns2: [
        {
          title: '工地名称',
          dataIndex: 'company_name',
        },
      ],
      columns: [
        // {
        //   title: '序号',
        //   customRender: (text, record, index) => index + 1,
        // },
        {
          title: '区域名称',
          dataIndex: 'name',
        },
        {
          title: '地区',
          customRender: (text, record, index) =>
            `${record.pro}-${record.city}-${record.area}`,
        },
        {
          title: '联系人',
          dataIndex: 'contact',
        },
        {
          title: '登陆账号',
          dataIndex: 'phone',
        },
        {
          title: '工地配置',
          dataIndex: 'type',
          scopedSlots: {
            customRender: 'type',
          },
        },
        {
          title: '创建日期',
          dataIndex: 'create_at_str',
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      data: [],
      listData: [],
      dataInfo: [],
      ssqData: ssqdata,
      addrValue: [],
      gov_id: null,
      showPwdFlag: false,
      confirmLoading: false,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 打开二层抽屉
    showChildrenDrawer() {
      this.getInfoList()
      this.childrenDrawer = true
    },
    // 关闭二层抽屉
    onChildrenDrawerClose() {
      this.childrenDrawer = false
      // this.getBindInfo(this.gov_id)
    },
    // 关闭一层抽屉
    onClose() {
      this.visible = false
    },
    // 全选
    onSelectChange(selectedRowKeys) {
      console.log(selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    onDraSave() {
      this.onDraSaveData()
    },
    // 打开一层抽屉
    showVisible(id) {
      this.getBindInfo(id)
      this.gov_id = id
      this.visible = true
    },
    afterVisibleChange() {
      this.data1 = []
    },
    // 获取绑定的工地列表
    getBindInfo(id) {
      this.$post(domain + '/admin/v1/gov/bindInfo', {
        gov_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.dataInfo = res.data.customer_list
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取工地列表
    getInfoList() {
      this.$post(
        domain + '/admin/v1/equipment/customer/list',
        this.query1
      ).then((res) => {
        if (res.code === 0) {
          this.listData = res.data.list
          this.query1.total = res.data.total
          //   找到已经添加的工地
          let indexs = []
          this.dataInfo.forEach((item, index) => {
            this.listData.forEach((it, ind) => {
              if (item.customer_id == it.customer_id) {
                indexs.push(ind)
              }
            })
          })
          this.selectedRowKeys = indexs
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 添加操作员保存事件
    onChildrenDrawerSave() {
      if (!this.selectedRowKeys.length > 0) {
        this.$message.error('请选择操作人')
        return
      }
      const customer_ids = this.selectedRowKeys.map((item) => {
        return this.listData[item].customer_id
      })
      this.dataInfo = []
      this.selectedRowKeys.forEach((item) => {
        this.dataInfo.push(this.listData[item])
      })
      this.onChildrenDrawerClose()

      //   return op_ids
      // this.$post(domain + '/admin/v1/gov/bind', {
      //   gov_id: this.gov_id,
      //   customer_ids,
      // }).then((res) => {
      //   if (res.code === 0) {
      //     this.$message.success('新增成功')
      //     this.onChildrenDrawerClose()
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })
    },
    onDraSaveData() {
      const customer_ids = this.dataInfo.map((item) => item.customer_id) || []
      this.$post(domain + '/admin/v1/gov/bind', {
        gov_id: this.gov_id,
        customer_ids,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('操作成功')
          this.onClose()
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //移除操作人
    delAction(record) {
      if (this.dataInfo.length == 1) {
        // this.$message.error('不可移除，至少应有一个操作人！')
        this.dataInfo.splice(0, 1)
      } else {
        let ind = null
        this.dataInfo.forEach((item, index) => {
          if (item.op_id === record.op_id) {
            ind = index
          }
        })
        this.dataInfo.splice(ind, 1)
      }
    },
    showChangePassword(gov_id) {
      this.gov_id = gov_id
      this.showPwdFlag = true
    },
    handleOk(e) {
      this.confirmLoading = true
      this.$post(domain + '/admin/v1/gov/updatePassword', {
        gov_id: this.gov_id,
        password: this.modleQuery.password,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('修改成功')
          this.confirmLoading = false
          this.showPwdFlag = false
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
      setTimeout(() => {
        this.visible = false
      }, 2000)
    },
    handleCancel(e) {
      this.showPwdFlag = false
    },
    async del(id) {
      console.log(id)
      const { data, code } = await this.$post(
        domain + '/admin/v1/gov/bindInfo',
        {
          gov_id: id,
        }
      )
      if (code == 0) {
        if (data.customer_list.length > 0) {
          this.$message.error('政府下存在工地，无法删除')
          return
        }
      }
      this.$confirm({
        title: '确认',
        content: '确认删除该账号吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/gov/delete', {
            gov_id: id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {},
      })
    },
    listChange(e) {
      this.query.page = e.current
      this.getList()
    },
    listChange1(e) {
      this.query1.page = e.current
      this.getInfoList()
    },
    ssqChange(e, opt) {
      // this.query.pro = e[0] || "";
      // this.query.city = e[1] || "";
      // this.query.area = e[2] || "";
      this.query.pro_code = opt && opt[0] ? opt[0].adcode : ''
      this.query.city_code = opt && opt[1] ? opt[1].adcode : ''
      this.query.area_code = opt && opt[2] ? opt[2].adcode : ''
    },
    loadSsq(selectedOptions) {
      console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // load options lazily
      let subdistrict =
        targetOption.level == 'province'
          ? 1
          : targetOption.level == 'city'
          ? 2
          : targetOption.level == 'district'
          ? 3
          : 4
      axios
        .get('https://restapi.amap.com/v3/config/district', {
          params: {
            keywords: targetOption.adcode,
            subdistrict: subdistrict,
            key: 'ce7404ac71c28a7893cae3ccc5517da0',
          },
        })
        .then((res) => {
          let districts = res.data.districts[0].districts
          for (let i = 0; i < districts.length; i++) {
            let item = districts[i]
            delete item.districts
            item.isLeaf =
              item.level == 'district' ||
              res.data.districts[0].adcode == 810000 ||
              res.data.districts[0].adcode == 820000
          }
          targetOption.districts = districts
          targetOption.loading = false
          this.ssqData = [...this.ssqData]
        })
        .catch((err) => {
          console.log(err)
        })
    },
    searchList() {
      this.getList()
    },
    // equipmentOn(id) {
    // 	this.$post(domain + '/admin/v1/equipment/qualification/on', {
    // 		material_id: id
    // 	}).then(res => {
    // 		if (res.code === 0) {
    // 			this.$message.success(res.msg)
    // 			this.getList()
    // 			console.log(res)
    // 		} else {
    // 			this.$message.error(res.msg)
    // 		}
    // 	})
    // },
    toEdit(id) {
      this.$router.push({
        path: '/governmentEdit',
        query: {
          id: id,
        },
      })
    },
    toEquipmentList(cid) {
      this.$router.push({
        path: '/deviceList',
        query: {
          cid: cid,
        },
      })
    },
    getList() {
      console.log('搜索对象', this.query)
      this.$post(domain + '/admin/v1/gov/list', this.query).then((res) => {
        console.log(res)
        if (res.code === 0) {
          this.data = res.data.list
          this.query.total = res.data.total
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    Reset() {
      this.addrValue = []
      this.query = {
        page: this.query.page,
        limit: this.query.limit,
      }
      this.getList()
    },
    handleSearch(query) {
      this.query.page = 1
      this.getList()
      console.log(query)
    },
    siteSearch() {
      this.query1.page = 1
      this.getInfoList()
    },
    changeCheck(value) {
      console.log(value)
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
