<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">客户列表</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-form-model
      class="form-search"
      :colon="false"
      layout="inline"
      :model="query"
      labelAlign="left"
      @submit="handleSearch"
      @submit.native.prevent
    >
      <a-row>
        <a-form-model-item style="float: right; margin-right: 0">
          <a-button type="primary" html-type="submit"> 查询 </a-button>
          <a-button @click="Reset">重置</a-button>
        </a-form-model-item>
        <a-form-model-item label="地区">
          <a-cascader
            :field-names="{
              label: 'name',
              value: 'name',
              children: 'districts',
            }"
            style="min-width: 300px"
            v-model="addrValue"
            :load-data="loadSsq"
            change-on-select
            :options="ssqData"
            placeholder="请选择省市区"
            @change="ssqChange"
          />
        </a-form-model-item>
        <a-form-model-item label="类型">
          <a-select
            v-model="query.type"
            class="a-select"
            placeholder="请选择类型"
            @change="searchList"
          >
            <a-select-option :value="1">
              {{ '租赁客户' }}
            </a-select-option>
            <a-select-option :value="2">
              {{ '产权客户' }}
            </a-select-option>
            <a-select-option :value="3">
              {{ '工地客户' }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="(企业/工地) 名称" class="siteLabel">
          <a-input
            v-model="query.company_name"
            placeholder="请输入(企业/工地) 名称"
          />
        </a-form-model-item>
        <a-form-model-item label="经办人">
          <a-input v-model="query.manager" placeholder="请输入经办人" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
    <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="toEdit(-1)">新增客户</a-button>
    </a-row>
    <a-table
      class="main-table"
      :pagination="{
        current: query.page,
        total: query.total,
      }"
      @change="listChange"
      :columns="columns"
      :data-source="data"
      rowKey="id"
      style="margin-top: 14px"
      v-viewer
    >
      <span slot="type" slot-scope="text">
        {{ text === 1 ? '租赁客户' : text === 2 ? '产权客户' : '工地客户' }}
      </span>
      <span slot="equipment_count" slot-scope="text, record">
        <a @click="toEquipmentList(record.customer_id)">{{ text }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="showVisible(record.customer_id)" v-show="record.type == 3"
            >操作员</a
          >

          <a @click="toEdit(record.customer_id)">编辑</a>
          <a style="color: #e0352b" @click="del(record.customer_id)">删除</a>
        </a-space>
      </span>
    </a-table>
    <a-drawer
      title="操作员"
      placement="right"
      :closable="true"
      :width="600"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
    >
      <a-button
        type="primary"
        @click="showChildrenDrawer"
        style="margin-bottom: 24px"
      >
        添加操作员
      </a-button>

      <a-table :data-source="dataInfo" :columns="columns1">
        <span
          slot="action1"
          slot-scope="text, record"
          style="color: #e0352b; cursor: pointer"
          @click="delAction(record)"
          >移除</span
        >
      </a-table>
      <a-drawer
        title="添加操作员"
        width="500"
        :closable="true"
        :visible="childrenDrawer"
        @close="onChildrenDrawerClose"
      >
        <a-form-model layout="inline">
          <a-form-model-item label="操作人">
            <a-input v-model="query1.name" placeholder="请输入操作人" />
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="personList"> 查询 </a-button>
          </a-form-model-item>
        </a-form-model>
        <a-table
          :data-source="listData"
          :columns="columns2"
          :pagination="{
            current: query1.page,
            total: query1.total,
          }"
          @change="listChange1"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
        </a-table>
        <div
          :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }"
        >
          <a-button style="margin-right: 20px" @click="onChildrenDrawerClose">
            取消
          </a-button>
          <a-button type="primary" @click="onChildrenDrawerSave">
            保存
          </a-button>
        </div>
      </a-drawer>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="margin-right: 20px" @click="onClose"> 取消 </a-button>
        <a-button type="primary" @click="onDraSave"> 保存 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { domain } from '@/siteInfo'
import axios from 'axios'
import { ssqdata } from '@/utils/ssqdata'

export default {
  data() {
    return {
      visible: false,
      childrenDrawer: false,
      query: {
        page: 1,
        limit: 10,
        total: 0,
      },
      selectedRowKeys: [],

      query1: {
        page: 1,
        limit: 10,
        total: 0,
      },
      columns2: [
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '手机号',
          dataIndex: 'phone',
        },
      ],
      columns1: [
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '手机号',
          dataIndex: 'phone',
        },
        {
          title: '操作',
          dataIndex: 'action1',
          scopedSlots: {
            customRender: 'action1',
          },
        },
      ],
      columns: [
        {
          title: '序号',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: {
            customRender: 'type',
          },
        },
        {
          title: '(企业/工地) 名称',
          dataIndex: 'company_name',
        },
        // {
        //   title: '企业名称',
        //   dataIndex: 'company_name',
        // },
        {
          title: '经办人',
          dataIndex: 'manager',
        },
        {
          title: '地区',
          customRender: (text, record, index) =>
            `${record.pro}-${record.city}-${record.area}`,
        },
        {
          title: '设备数量',
          dataIndex: 'equipment_count',
          scopedSlots: {
            customRender: 'equipment_count',
          },
        },
        {
          title: '创建人',
          dataIndex: 'admin_name',
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      data: [],
      listData: [],
      dataInfo: [],
      ssqData: ssqdata,
      addrValue: [],
      customer_id: null,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 打开二层抽屉
    showChildrenDrawer() {
      this.getInfoList()
      this.childrenDrawer = true
    },
    // 关闭二层抽屉
    onChildrenDrawerClose() {
      this.childrenDrawer = false
      // this.getBindInfo(this.customer_id)
    },
    // 关闭一层抽屉
    onClose() {
      this.visible = false
    },
    // 全选
    onSelectChange(selectedRowKeys) {
      console.log(selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    onDraSave() {
      this.onDraSaveData()
    },
    // 打开一层抽屉
    showVisible(id) {
      this.getBindInfo(id)
      this.customer_id = id
      this.visible = true
    },
    afterVisibleChange() {
      this.data1 = []
    },
    // 获取绑定的操作人列表
    getBindInfo(id) {
      this.$post(domain + '/admin/v1/equipment/customer/bindInfo', {
        customer_id: id,
      }).then((res) => {
        if (res.code === 0) {
          this.dataInfo = res.data.op_list
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取操作人列表
    getInfoList() {
      this.$post(domain + '/admin/v1/operator/list', this.query1).then(
        (res) => {
          if (res.code === 0) {
            this.listData = res.data.list
            this.query1.total = res.data.total
            //   找到已经添加的工地
            let indexs = []
            this.dataInfo.forEach((item, index) => {
              this.listData.forEach((it, ind) => {
                if (item.op_id == it.op_id) {
                  indexs.push(ind)
                }
              })
            })
            this.selectedRowKeys = indexs
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    // 添加操作员保存事件
    onChildrenDrawerSave() {
      if (!this.selectedRowKeys.length > 0) {
        this.$message.error('请选择操作人')
        return
      }
      const op_ids = this.selectedRowKeys.map((item) => {
        return this.listData[item].op_id
      })
      this.dataInfo = []
      this.selectedRowKeys.forEach((item) => {
        this.dataInfo.push(this.listData[item])
      })
      this.onChildrenDrawerClose()

      //   return op_ids
      // this.$post(domain + '/admin/v1/equipment/customer/bind', {
      //   customer_id: this.customer_id,
      //   op_ids,
      // }).then((res) => {
      //   if (res.code === 0) {
      //     this.$message.success('新增成功')
      //     this.onChildrenDrawerClose()
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })
    },
    onDraSaveData() {
      const op_ids = this.dataInfo.map((item) => item.op_id)
      this.$post(domain + '/admin/v1/equipment/customer/bind', {
        customer_id: this.customer_id,
        op_ids,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success('操作成功')
          this.onClose()
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //移除操作人
    delAction(record) {
      if (this.dataInfo.length == 1) {
        // this.$message.error('不可移除，至少应有一个操作人！')
        this.dataInfo.splice(0, 1)
      } else {
        let ind = null
        this.dataInfo.forEach((item, index) => {
          if (item.op_id === record.op_id) {
            ind = index
          }
        })
        this.dataInfo.splice(ind, 1)
      }
    },
    del(id) {
      console.log(id)
      this.$confirm({
        title: '确认',
        content: '确认删除该客户吗',
        onOk: () => {
          this.$post(domain + '/admin/v1/equipment/customer/delete', {
            customer_id: id,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        },
        onCancel: () => {},
      })
    },
    listChange(e) {
      this.query.page = e.current
      this.getList()
    },
    listChange1(e) {
      this.query1.page = e.current
      this.getInfoList()
    },
    ssqChange(e, opt) {
      // this.query.pro = e[0] || "";
      // this.query.city = e[1] || "";
      // this.query.area = e[2] || "";
      this.query.pro_code = opt && opt[0] ? opt[0].adcode : ''
      this.query.city_code = opt && opt[1] ? opt[1].adcode : ''
      this.query.area_code = opt && opt[2] ? opt[2].adcode : ''
    },
    loadSsq(selectedOptions) {
      console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // load options lazily
      let subdistrict =
        targetOption.level == 'province'
          ? 1
          : targetOption.level == 'city'
          ? 2
          : targetOption.level == 'district'
          ? 3
          : 4
      axios
        .get('https://restapi.amap.com/v3/config/district', {
          params: {
            keywords: targetOption.adcode,
            subdistrict: subdistrict,
            key: 'ce7404ac71c28a7893cae3ccc5517da0',
          },
        })
        .then((res) => {
          let districts = res.data.districts[0].districts
          for (let i = 0; i < districts.length; i++) {
            let item = districts[i]
            delete item.districts
            item.isLeaf =
              item.level == 'district' ||
              res.data.districts[0].adcode == 810000 ||
              res.data.districts[0].adcode == 820000
          }
          targetOption.districts = districts
          targetOption.loading = false
          this.ssqData = [...this.ssqData]
        })
        .catch((err) => {
          console.log(err)
        })
    },
    searchList() {
      this.getList()
    },
    // equipmentOn(id) {
    // 	this.$post(domain + '/admin/v1/equipment/qualification/on', {
    // 		material_id: id
    // 	}).then(res => {
    // 		if (res.code === 0) {
    // 			this.$message.success(res.msg)
    // 			this.getList()
    // 			console.log(res)
    // 		} else {
    // 			this.$message.error(res.msg)
    // 		}
    // 	})
    // },
    toEdit(id) {
      this.$router.push({
        path: '/customerEdit',
        query: {
          id: id,
        },
      })
    },
    toEquipmentList(cid) {
      this.$router.push({
        path: '/deviceList',
        query: {
          cid: cid,
        },
      })
    },
    getList() {
      console.log('搜索对象', this.query)
      this.$post(domain + '/admin/v1/equipment/customer/list', this.query).then(
        (res) => {
          console.log(res)
          if (res.code === 0) {
            this.data = res.data.list
            this.query.total = res.data.total
          } else {
            this.$message.error(res.msg)
          }
        }
      )
    },
    Reset() {
      this.addrValue = []
      this.query = {
        page: this.query.page,
        limit: this.query.limit,
      }
      this.getList()
    },
    handleSearch(query) {
      this.query.page = 1
      this.getList()
      console.log(query)
    },
    changeCheck(value) {
      console.log(value)
    },
    personList() {
      this.query1.page = 1
      this.getInfoList()
    },
  },
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4e80f8;
  }

  .title {
    color: #383f50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    min-width: 70px;
  }
  .siteLabel {
    .ant-form-item-label {
      width: 115px;
    }
  }
}
</style>
